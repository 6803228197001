import { UserProfile } from "@lobby/core/src/components";
import { createFileRoute } from "@tanstack/react-router";
import { Outlet } from "@tanstack/react-router";
import { isLobbyDemoMode, redirectIfAuthenticatedUserRequired } from "app/lib";
import { loadLobbyConfig } from "app/lib/loaders";
import { CongratsModal } from "features/congrats-modal";
import { LogInModal } from "widget/log-in-modal";
import { PasswordForgotModal } from "widget/password-forgot-modal";
import { RegisterModal } from "widget/register-modal";

export const Route = createFileRoute("/_auth")({
  beforeLoad: () => redirectIfAuthenticatedUserRequired(),
  loader: () => Promise.all([loadLobbyConfig()]),
  component: () => (
    <>
      <UserProfile />
      <CongratsModal />

      {isLobbyDemoMode() && (
        <>
          <LogInModal />
          <RegisterModal />
          <PasswordForgotModal />
        </>
      )}

      <Outlet />
    </>
  ),
});
