import { useTranslate } from "@lobby/ocb-intl";
import { type ReactNode, useMemo } from "react";
import { Lobby, Player } from "../../../../entities";
import { type ProfileSection, profileSection } from "../../const";
import { Bonuses } from "./bonuses";
import { GameHistory } from "./game-history";
import { Info } from "./info";
import { MemberCard } from "./member-card";
import { Settings } from "./settings";
import { SportBetsHistory } from "./sport-bets-history";
import { TransactionHistory } from "./transaction-history";
import { Wallet } from "./wallet";

export type TProfileSectionItem = {
  id: ProfileSection;
  title: string;
  icon: string;
  component: ReactNode;
};

export const useProfileSections = (): TProfileSectionItem[] => {
  const { $t } = useTranslate();
  const { data } = Player.usePlayer();
  const { data: lobby } = Lobby.useLobby();

  const isWalletEnabled =
    Boolean(data?.isDepositActive) || Boolean(data?.isWithdrawActive);

  return useMemo(() => {
    const isSportBetsHistoryEnabled = Boolean(lobby?.showSportHistory);

    const sections = [
      {
        id: profileSection.Bonuses,
        title: $t({ defaultMessage: "Bonuses" }),
        icon: "order",
        component: <Bonuses />,
      },
      {
        id: profileSection.GameHistory,
        title: $t({ defaultMessage: "Game history" }),
        icon: "gamepadSolid",
        component: <GameHistory />,
      },
      isSportBetsHistoryEnabled && {
        id: profileSection.SportBetsHistory,
        title: $t({ defaultMessage: "Sport Bets history" }),
        icon: "trophySolid",
        component: <SportBetsHistory />,
      },
      isWalletEnabled && {
        id: profileSection.Wallet,
        title: $t({ defaultMessage: "Wallet" }),
        icon: "wallet",
        component: <Wallet />,
      },
      {
        id: profileSection.TransactionHistory,
        title: $t({ defaultMessage: "Transaction history" }),
        icon: "clock",
        component: <TransactionHistory />,
      },
      {
        id: profileSection.MemberCard,
        title: $t({ defaultMessage: "Member card" }),
        icon: "memberCard",
        component: <MemberCard />,
      },
      {
        id: profileSection.Info,
        title: $t({ defaultMessage: "Info" }),
        icon: "info",
        component: <Info />,
      },
      {
        id: profileSection.Settings,
        title: $t({ defaultMessage: "Settings" }),
        icon: "settings",
        component: <Settings />,
      },
    ];

    return sections.filter(Boolean) as TProfileSectionItem[];
  }, [lobby, isWalletEnabled, $t]);
};
