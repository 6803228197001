import { debounce } from "@lobby/core/src/shared";
import { useNavigate } from "@tanstack/react-router";
import { useCallback } from "react";
import { InputSearch } from "shared/ui/input-search";

export function GameSearch({ className }: { className?: string }) {
  const navigate = useNavigate();

  const handleSearch = useCallback(
    debounce((value: string) => {
      navigate({
        // @ts-ignore
        search: (prev) => ({
          ...prev,
          game: value || undefined,
          pid: undefined,
          cid: undefined,
        }),
      });
    }),
    [navigate],
  );

  return <InputSearch className={className} onChange={handleSearch} />;
}
