import { clsx } from "clsx";
import type { ButtonHTMLAttributes } from "react";
import { Icon } from "../icons";

interface IButtonProps {
  className?: string;
  variant?: "primary" | "secondary" | "danger" | "warning" | "success";
  loading?: boolean;
  fit?: boolean;
}

export function Button({
  className,
  variant = "primary",
  type = "button",
  loading,
  fit,
  onClick,
  children,
  ...restProps
}: IButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={clsx(
        "btn",
        `btn-${variant}`,
        fit && "btn-fit",
        loading && "pointer-events-none cursor-default",
        className,
      )}
      type={type}
      onClick={onClick}
      {...restProps}
    >
      <div className={clsx("truncate", loading && "invisible")}>{children}</div>
      {loading && (
        <span className="absolute animate-spin">
          <Icon name="loading" />
        </span>
      )}
    </button>
  );
}
