import { Player } from "../../../entities";

export function useAuth() {
  const { data: player, isFetching } = Player.usePlayer();

  return {
    isAuth: Boolean(player),
    isAuthInProgress: isFetching,
  };
}
