import { Lobby } from "@lobby/core/src/entities";
import { formatTime, useFortuneWheelCountdown } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import type { SVGProps } from "react";
import { type SVGAttributes, memo } from "react";

const Timer = memo(function Timer(props: SVGAttributes<SVGTextElement>) {
  const { data } = Lobby.useLobby();

  const countdown = useFortuneWheelCountdown(data?.fortuneWheel?.nextSpinTime as number);

  return (
    <text
      fontWeight="bold"
      alignmentBaseline="middle"
      className="fill-white text-[3.125rem] tracking-tight [text-shadow:0_0.125rem_0.25rem_#000]"
      {...props}
    >
      {formatTime(countdown)}
    </text>
  );
});

interface IWheelSectorProps {
  fill: string;
  path: string;
  isWinSector: boolean;
}

export function WheelSector({
  fill,
  path,
  isWinSector,
  ...restProps
}: IWheelSectorProps & SVGProps<SVGGElement>) {
  return (
    <g {...restProps}>
      <path fill={fill} d={path} />
      {isWinSector && (
        <path fill="#fff" fillOpacity="0" d={path}>
          <animate
            attributeName="fill-opacity"
            calcMode="discrete"
            values="0;0.25"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      )}
    </g>
  );
}

export function OuterCircle() {
  return (
    <g>
      <path
        fill="#FFEA94"
        d="M384.326 1.326c211.533 0 383 171.467 383 383s-171.467 383-383 383-383-171.467-383-383 171.467-383 383-383Z"
      />
      <path
        fill="#C79C11"
        d="M384.826 4.326c210.161 0 380.5 170.339 380.5 380.5s-170.339 380.5-380.5 380.5-380.5-170.339-380.5-380.5 170.339-380.5 380.5-380.5Z"
      />
      <path
        fill="url(#paint0_radial_572_1481)"
        d="M384.826 4.326c210.161 0 380.5 170.339 380.5 380.5s-170.339 380.5-380.5 380.5-380.5-170.339-380.5-380.5 170.339-380.5 380.5-380.5Z"
      />
      <path
        fill="url(#paint1_radial_572_1481)"
        d="M384.826 4.326c210.161 0 380.5 170.339 380.5 380.5s-170.339 380.5-380.5 380.5-380.5-170.339-380.5-380.5 170.339-380.5 380.5-380.5Z"
      />
      <path
        fill="#FFEA94"
        d="M384.826 763.326c-209.056 0-378.5-169.444-378.5-378.5 0-209.057 169.444-378.5 378.5-378.5 209.057 0 378.5 169.443 378.5 378.5 0 209.056-169.443 378.5-378.5 378.5Zm0-751.349c-205.893 0-372.848 166.955-372.848 372.849 0 205.894 166.903 372.848 372.848 372.848 205.946 0 372.849-166.903 372.849-372.848 0-205.946-166.955-372.849-372.849-372.849Zm0 707.433c-184.791 0-334.583-149.793-334.583-334.584S200.035 50.242 384.826 50.242c184.791 0 334.584 149.793 334.584 334.584S569.617 719.41 384.826 719.41Zm0-662.583c-181.161 0-327.999 146.837-327.999 327.999 0 181.161 146.838 327.999 327.999 327.999 181.162 0 327.999-146.838 327.999-327.999 0-181.162-146.837-327.999-327.999-327.999Z"
      />
      <path
        fill="url(#paint2_linear_572_1481)"
        d="M384.826 763.326c-209.056 0-378.5-169.444-378.5-378.5 0-209.057 169.444-378.5 378.5-378.5 209.057 0 378.5 169.443 378.5 378.5 0 209.056-169.443 378.5-378.5 378.5Zm0-751.349c-205.893 0-372.848 166.955-372.848 372.849 0 205.894 166.903 372.848 372.848 372.848 205.946 0 372.849-166.903 372.849-372.848 0-205.946-166.955-372.849-372.849-372.849Zm0 707.433c-184.791 0-334.583-149.793-334.583-334.584S200.035 50.242 384.826 50.242c184.791 0 334.584 149.793 334.584 334.584S569.617 719.41 384.826 719.41Zm0-662.583c-181.161 0-327.999 146.837-327.999 327.999 0 181.161 146.838 327.999 327.999 327.999 181.162 0 327.999-146.838 327.999-327.999 0-181.162-146.837-327.999-327.999-327.999Z"
      />
    </g>
  );
}

export function OuterCircleDiamond() {
  return (
    <svg id="outer-circle-diamond" viewBox="0 0 47 47">
      <path
        fill="#D8A61F"
        d="m17.689 38.886-5.649-3.864-3.865-5.65-1.262-6.726 1.262-6.726 3.865-5.649 5.649-3.86 6.725-1.263L31.14 6.41l5.649 3.865 3.862 5.647 1.262 6.726-1.262 6.727-3.864 5.648-5.647 3.863-6.726 1.262-6.726-1.262Z"
      />
      <path fill="#fff" d="m33.436 27.005-9.43-3.906h9.43v3.906Z" opacity=".7" />
      <path fill="#fff" d="M38.363 23.111h3.608l-1.296 6.905-2.312-6.905Z" opacity=".6" />
      <path fill="#fff" d="m40.675 30.016-2.312-6.905-4.927 3.907 7.24 2.998Z" opacity=".9" />
      <path fill="#fff" d="M38.363 23.1h-4.927v3.907l4.927-3.906Z" opacity=".7" />
      <path
        fill="#fff"
        d="m33.436 19.196-9.43 3.907h9.43v-3.907ZM38.363 23.11h3.608l-1.296-6.904-2.312 6.905Z"
        opacity=".9"
      />
      <path fill="#fff" d="m40.675 16.206-2.312 6.905-4.927-3.907 7.24-2.998Z" opacity=".8" />
      <path fill="#fff" d="M38.363 23.103h-4.927v-3.907l4.927 3.907Z" opacity=".51" />
      <path fill="#fff" d="m14.465 19.217 9.43 3.906h-9.43v-3.906Z" opacity=".7" />
      <path fill="#fff" d="M9.538 23.11H5.931l1.295-6.904 2.312 6.905Z" opacity=".5" />
      <path fill="#fff" d="m7.226 16.206 2.312 6.905 4.927-3.907-7.239-2.998Z" opacity=".9" />
      <path fill="#fff" d="M9.538 23.121h4.927v-3.906L9.538 23.12Z" opacity=".6" />
      <path fill="#fff" d="m14.465 27.026 9.43-3.907h-9.43v3.907Z" opacity=".9" />
      <path fill="#fff" d="M9.538 23.111H5.931l1.295 6.905 2.312-6.905Z" opacity=".2" />
      <path fill="#fff" d="M9.538 23.12h4.927v3.906l-4.927-3.907Z" opacity=".3" />
      <path fill="#fff" d="m20.056 32.596 3.907-9.43v9.43h-3.907Z" opacity=".2" />
      <path fill="#fff" d="M23.95 37.523v3.608l-6.904-1.296 6.905-2.312Z" />
      <path fill="#fff" d="m17.046 39.835 6.905-2.312-3.907-4.927-2.998 7.24Z" opacity=".7" />
      <path fill="#fff" d="M23.96 37.523v-4.927h-3.906l3.907 4.928Z" opacity=".8" />
      <path fill="#fff" d="m27.866 32.596-3.907-9.43v9.43h3.907Z" />
      <path fill="#fff" d="M23.95 37.523v3.608l6.905-1.296-6.904-2.312Z" opacity=".9" />
      <path fill="#fff" d="m30.855 39.835-6.904-2.312 3.906-4.927 2.998 7.24Z" opacity=".7" />
      <path fill="#fff" d="M23.959 37.523v-4.927h3.907l-3.907 4.928Z" opacity=".2" />
      <path fill="#fff" d="m27.845 13.626-3.906 9.43v-9.43h3.906Z" />
      <path fill="#fff" d="M23.95 8.699V5.09l6.905 1.296-6.904 2.312Z" opacity=".5" />
      <path fill="#fff" d="m30.855 6.387-6.904 2.312 3.906 4.927 2.998-7.24Z" opacity=".9" />
      <path fill="#fff" d="M23.94 8.698v4.928h3.907L23.94 8.698Z" opacity=".4" />
      <path fill="#fff" d="m20.036 13.626 3.907 9.43v-9.43h-3.907Z" opacity=".8" />
      <path fill="#fff" d="M23.95 8.699V5.09l-6.904 1.296 6.905 2.312Z" opacity=".2" />
      <path fill="#fff" d="m17.046 6.387 6.905 2.312-3.907 4.927-2.998-7.24Z" opacity=".7" />
      <path fill="#fff" d="M23.943 8.698v4.928h-3.907l3.907-4.928Z" opacity=".1" />
      <path fill="#fff" d="m27.904 32.572-3.906-9.43 6.668 6.668-2.762 2.762Z" opacity=".8" />
      <path fill="#fff" d="m34.143 33.303 2.55 2.55-5.799 3.966 3.249-6.516Z" />
      <path
        fill="#fff"
        d="m30.894 39.819 3.249-6.518-6.247-.72 2.998 7.238ZM33.426 27.05l-9.43-3.906 6.668 6.668 2.762-2.762Z"
        opacity=".9"
      />
      <path fill="#fff" d="m34.143 33.303 2.55 2.55 3.966-5.799-6.516 3.25Z" opacity=".7" />
      <path fill="#fff" d="m40.659 30.055-6.518 3.248-.723-6.247 7.24 2.999Z" opacity=".7" />
      <path
        fill="#fff"
        d="m34.147 33.297-3.485-3.485 2.762-2.762.723 6.247ZM19.997 13.65l3.907 9.43-6.669-6.668 2.762-2.762Z"
      />
      <path fill="#fff" d="m13.758 12.919-2.55-2.55 5.8-3.966-3.25 6.516Z" opacity=".7" />
      <path fill="#fff" d="m17.007 6.403-3.249 6.518 6.247.723-2.998-7.241Z" />
      <path fill="#fff" d="m13.752 12.927 3.485 3.485L20 13.65l-6.247-.723Z" opacity=".6" />
      <path fill="#fff" d="m14.475 19.172 9.43 3.906-6.668-6.668-2.762 2.762Z" opacity=".7" />
      <path fill="#fff" d="m13.758 12.919-2.55-2.55-3.965 5.799 6.515-3.25Z" opacity=".2" />
      <path fill="#fff" d="m7.243 16.167 6.518-3.248.723 6.247-7.241-2.999Z" opacity=".6" />
      <path fill="#fff" d="m13.754 12.925 3.485 3.485-2.762 2.762-.723-6.247Z" opacity=".3" />
      <path fill="#fff" d="m14.49 27.064 9.43-3.906-6.668 6.668-2.762-2.762Z" opacity=".5" />
      <path fill="#fff" d="m13.758 33.303-2.55 2.55-3.965-5.799 6.515 3.25Z" opacity=".9" />
      <path fill="#fff" d="m7.243 30.055 6.518 3.248.723-6.247-7.241 2.999Z" />
      <path fill="#fff" d="m13.767 33.31 3.485-3.486-2.762-2.762-.723 6.247Z" opacity=".5" />
      <path fill="#fff" d="m20.012 32.586 3.906-9.43-6.668 6.668 2.762 2.762Z" opacity=".9" />
      <path fill="#fff" d="m13.758 33.303-2.55 2.55 5.8 3.968-3.25-6.518Z" opacity=".7" />
      <path fill="#fff" d="m17.007 39.819-3.249-6.518 6.247-.723-2.998 7.241Z" opacity=".5" />
      <path
        fill="#fff"
        d="m33.412 19.157-9.43 3.907 6.668-6.669 2.762 2.762ZM34.143 12.919l2.55-2.55 3.966 5.799-6.516-3.25Z"
        opacity=".9"
      />
      <path fill="#fff" d="M40.659 16.167 34.14 12.92l-.721 6.247 7.239-2.999Z" opacity=".4" />
      <path fill="#fff" d="m34.135 12.913-3.485 3.483 2.762 2.761.723-6.244Z" opacity=".6" />
      <path fill="#fff" d="m27.89 13.636-3.907 9.43 6.669-6.668-2.762-2.762Z" opacity=".4" />
      <path fill="#fff" d="m34.143 12.919 2.55-2.55-5.799-3.966 3.249 6.516Z" opacity=".2" />
      <path fill="#fff" d="M34.137 12.915 30.652 16.4l-2.762-2.764 6.247-.721Z" opacity=".3" />
    </svg>
  );
}

export function OuterCircleHandle() {
  return (
    <svg id="outer-circle-handle" viewBox="0 0 15 15">
      <circle cx="56%" cy="50%" r="50%" fill="url(#paint0_radial_669_250)" />
      <circle cx="50%" cy="50%" r="37%" fill="#FFEA94" />
      <circle cx="50%" cy="50%" r="37%" fill="url(#paint0_radial_669_251)" />
      <defs>
        <radialGradient id="paint0_radial_669_251">
          <stop offset=".39" stopOpacity="0" />
          <stop offset=".58" stopOpacity=".08" />
          <stop offset=".9" stopOpacity=".34" />
          <stop offset="1" stopOpacity=".75" />
        </radialGradient>
        <radialGradient id="paint0_radial_669_250">
          <stop offset=".73" stopOpacity=".6" />
          <stop offset=".9" stopOpacity=".1" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export function InnerCircleDiamond() {
  return (
    <svg id="inner-circle-diamond" viewBox="0 0 27 27">
      <circle cx="13.892" cy="12.876" r="10.5" fill="#CA9A00" />
      <circle cx="13.892" cy="12.876" r="10.5" fill="url(#paint2_linear_660_1226)" />
      <path
        fill="#D8A61F"
        d="m10.241 21.69-3.066-2.097-2.098-3.067-.685-3.651.685-3.651 2.098-3.067 3.066-2.096 3.651-.685 3.651.685 3.067 2.098 2.097 3.066.685 3.651-.685 3.651-2.098 3.067-3.066 2.097-3.65.685-3.652-.685Z"
      />
      <path fill="#fff" d="m18.831 15.442-5.29-2.191h5.29v2.191Z" opacity=".7" />
      <path fill="#fff" d="M21.595 13.257h2.024l-.727 3.874-1.297-3.874Z" opacity=".6" />
      <path fill="#fff" d="m22.892 17.13-1.297-3.873-2.764 2.192 4.061 1.682Z" opacity=".9" />
      <path fill="#fff" d="M21.595 13.252h-2.764v2.191l2.764-2.191Z" opacity=".7" />
      <path
        fill="#fff"
        d="m18.831 11.061-5.29 2.192h5.29V11.06ZM21.595 13.258h2.024l-.727-3.874-1.297 3.873Z"
        opacity=".9"
      />
      <path fill="#fff" d="m22.892 9.384-1.297 3.873-2.764-2.191 4.061-1.682Z" opacity=".8" />
      <path fill="#fff" d="M21.595 13.253h-2.764V11.06l2.764 2.192Z" opacity=".51" />
      <path fill="#fff" d="m8.19 11.073 5.29 2.191H8.19v-2.191Z" opacity=".7" />
      <path fill="#fff" d="M5.425 13.258H3.402l.726-3.874 1.297 3.873Z" opacity=".5" />
      <path fill="#fff" d="m4.128 9.384 1.297 3.873 2.764-2.191-4.06-1.682Z" opacity=".9" />
      <path fill="#fff" d="M5.425 13.263H8.19v-2.191l-2.764 2.191Z" opacity=".6" />
      <path fill="#fff" d="m8.19 15.454 5.29-2.192H8.19v2.191Z" opacity=".9" />
      <path fill="#fff" d="M5.425 13.257H3.402l.726 3.874 1.297-3.874Z" opacity=".2" />
      <path fill="#fff" d="M5.425 13.262H8.19v2.191l-2.764-2.191Z" opacity=".3" />
      <path fill="#fff" d="m11.326 18.579 2.191-5.29v5.29h-2.191Z" opacity=".2" />
      <path fill="#fff" d="M13.51 21.343v2.023l-3.873-.727 3.873-1.296Z" />
      <path fill="#fff" d="m9.637 22.64 3.873-1.297-2.191-2.764-1.682 4.06Z" opacity=".7" />
      <path fill="#fff" d="M13.516 21.343v-2.764h-2.191l2.191 2.764Z" opacity=".8" />
      <path fill="#fff" d="m15.706 18.579-2.191-5.29v5.29h2.191Z" />
      <path fill="#fff" d="M13.51 21.343v2.023l3.874-.727-3.874-1.296Z" opacity=".9" />
      <path fill="#fff" d="m17.384 22.64-3.874-1.297 2.192-2.764 1.682 4.06Z" opacity=".7" />
      <path fill="#fff" d="M13.515 21.343v-2.764h2.191l-2.191 2.764Z" opacity=".2" />
      <path fill="#fff" d="m15.695 7.936-2.191 5.29v-5.29h2.191Z" />
      <path fill="#fff" d="M13.51 5.172V3.15l3.874.726-3.874 1.297Z" opacity=".5" />
      <path fill="#fff" d="M17.384 3.875 13.51 5.172l2.192 2.764 1.682-4.06Z" opacity=".9" />
      <path fill="#fff" d="M13.505 5.172v2.764h2.191l-2.191-2.764Z" opacity=".4" />
      <path fill="#fff" d="m11.314 7.936 2.192 5.29v-5.29h-2.192Z" opacity=".8" />
      <path fill="#fff" d="M13.51 5.172V3.15l-3.873.726 3.873 1.297Z" opacity=".2" />
      <path fill="#fff" d="m9.637 3.875 3.873 1.297-2.191 2.764-1.682-4.06Z" opacity=".7" />
      <path fill="#fff" d="M13.506 5.172v2.764h-2.192l2.192-2.764Z" opacity=".1" />
      <path fill="#fff" d="m15.728 18.565-2.191-5.29 3.74 3.74-1.549 1.55Z" opacity=".8" />
      <path fill="#fff" d="m19.228 18.975 1.43 1.43-3.253 2.225 1.823-3.655Z" />
      <path
        fill="#fff"
        d="m17.405 22.63 1.823-3.656-3.505-.405 1.682 4.061ZM18.826 15.467l-5.29-2.191 3.74 3.74 1.55-1.549Z"
        opacity=".9"
      />
      <path
        fill="#fff"
        d="m19.228 18.975 1.43 1.43 2.225-3.252-3.655 1.822ZM22.883 17.153l-3.656 1.822-.406-3.504 4.062 1.682Z"
        opacity=".7"
      />
      <path
        fill="#fff"
        d="m19.23 18.972-1.955-1.955 1.55-1.55.405 3.505ZM11.293 7.95l2.191 5.29-3.74-3.74 1.549-1.55Z"
      />
      <path fill="#fff" d="m7.793 7.54-1.43-1.43 3.252-2.225L7.793 7.54Z" opacity=".7" />
      <path fill="#fff" d="M9.615 3.885 7.793 7.54l3.504.405-1.682-4.061Z" />
      <path fill="#fff" d="M7.79 7.544 9.743 9.5l1.55-1.549-3.505-.406Z" opacity=".6" />
      <path fill="#fff" d="m8.195 11.048 5.29 2.191-3.74-3.74-1.55 1.549Z" opacity=".7" />
      <path fill="#fff" d="m7.793 7.54-1.43-1.43-2.225 3.252L7.793 7.54Z" opacity=".2" />
      <path fill="#fff" d="M4.138 9.362 7.794 7.54l.405 3.504-4.061-1.682Z" opacity=".6" />
      <path fill="#fff" d="m7.79 7.543 1.955 1.955-1.549 1.55-.406-3.505Z" opacity=".3" />
      <path fill="#fff" d="m8.203 15.475 5.29-2.191-3.74 3.74-1.55-1.549Z" opacity=".5" />
      <path fill="#fff" d="m7.793 18.975-1.43 1.43-2.225-3.252 3.655 1.822Z" opacity=".9" />
      <path fill="#fff" d="m4.138 17.153 3.656 1.822.405-3.504-4.061 1.682Z" />
      <path fill="#fff" d="m7.797 18.979 1.955-1.956-1.55-1.549-.405 3.505Z" opacity=".5" />
      <path fill="#fff" d="m11.3 18.573 2.192-5.29-3.74 3.74 1.548 1.55Z" opacity=".9" />
      <path fill="#fff" d="m7.793 18.975-1.43 1.43 3.252 2.226-1.822-3.656Z" opacity=".7" />
      <path fill="#fff" d="m9.615 22.63-1.822-3.656 3.504-.406-1.682 4.062Z" opacity=".5" />
      <path
        fill="#fff"
        d="m18.818 11.04-5.29 2.191 3.74-3.74 1.55 1.549ZM19.228 7.54l1.43-1.43 2.225 3.252-3.655-1.822Z"
        opacity=".9"
      />
      <path fill="#fff" d="M22.883 9.362 19.227 7.54l-.405 3.504 4.061-1.682Z" opacity=".4" />
      <path fill="#fff" d="M19.223 7.536 17.268 9.49l1.55 1.55.405-3.504Z" opacity=".6" />
      <path fill="#fff" d="m15.72 7.942-2.191 5.29 3.74-3.74-1.549-1.55Z" opacity=".4" />
      <path fill="#fff" d="m19.228 7.54 1.43-1.43-3.253-2.225 1.823 3.655Z" opacity=".2" />
      <path fill="#fff" d="M19.224 7.538 17.27 9.493l-1.55-1.55 3.504-.405Z" opacity=".3" />
      <defs>
        <linearGradient id="paint2_linear_660_1226" gradientTransform="rotate(45) translate(0.2)">
          <stop offset=".36" stopColor="#fff" stopOpacity="0" />
          <stop offset=".5" stopColor="#fff" stopOpacity=".8" />
          <stop offset=".69" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function InnerCircleLamp() {
  return (
    <svg id="inner-circle-lamp" viewBox="0 0 40 40">
      <circle cx="50%" cy="50%" r="50%" fill="url(#paint0_radial_660_1898)" />
      <defs>
        <radialGradient id="paint0_radial_660_1898">
          <stop stopColor="#FFFDEE" />
          <stop offset=".28" stopColor="#fff" />
          <stop offset=".37" stopColor="#fff" stopOpacity=".62" />
          <stop offset=".51" stopColor="#fff" stopOpacity=".4" />
          <stop offset=".865" stopColor="#fff" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export function UpperArrow({ x = 0, y = 0, ...restProps }: SVGProps<SVGSVGElement>) {
  return (
    <svg x={+x - 110 / 2} y={y} width="110" height="103" viewBox="0 0 110 103" {...restProps}>
      <path
        fill="url(#paint0_linear_0_1)"
        d="M54.994 94.618 12.064 6.601S31.071 0 54.994 0s42.93 6.601 42.93 6.601l-42.93 88.017Z"
      />
      <path
        fill="url(#paint1_linear_0_1)"
        fillOpacity=".4"
        d="M54.994 94.618 12.064 6.601S31.071 0 54.994 0s42.93 6.601 42.93 6.601l-42.93 88.017Z"
      />
      <path
        fill="url(#paint2_linear_0_1)"
        fillOpacity=".4"
        d="M54.994 94.618 12.064 6.601S31.071 0 54.994 0s42.93 6.601 42.93 6.601l-42.93 88.017Z"
      />
      <path
        fill="url(#paint3_linear_0_1)"
        d="M100.126 15.406C97.45 20.9 57.172 101.32 56.811 102.004c-.36.685-1.095.996-1.805.996s-1.456-.311-1.805-.996c-.348-.685-40.651-81.105-43.34-86.598C5.804 7.174 4.546 5.38.227 5.38 5.83 4.41 12.75 3.3 17.566 3.3c1.32 0 2.203 1.097 2.203 1.097l35.225 73.706L90.219 4.397S91.102 3.3 92.422 3.3c4.817 0 11.737 1.108 17.338 2.08-4.319 0-5.576 1.793-9.634 10.025Z"
      />
      <path
        fill="url(#paint4_linear_0_1)"
        d="M92.422 3.3c-1.32 0-2.203 1.097-2.203 1.097L54.994 78.103 19.769 4.397S18.885 3.3 17.566 3.3c-3.635 0-9.248.697-14.202 1.457 2.95 0 4.941 2.055 8.7 9.54 3.075 6.277 42.93 83.608 42.93 83.608s39.867-77.33 42.93-83.608c3.746-7.485 5.75-9.54 8.7-9.54-4.942-.76-10.555-1.457-14.202-1.457Z"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="54.994"
          x2="54.994"
          y1="0"
          y2="94.618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset=".41" stopColor="#fff" />
          <stop offset="1" stopColor="#5E5E5E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="12.064"
          x2="97.923"
          y1="26.625"
          y2="67.993"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".61" stopOpacity="0" />
          <stop offset=".65" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_1"
          x1="97.923"
          x2="12.064"
          y1="27.28"
          y2="67.338"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".61" stopColor="#fff" stopOpacity="0" />
          <stop offset=".65" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_1"
          x1="54.994"
          x2="54.994"
          y1="103"
          y2="3.301"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".04" stopColor="#5C3700" />
          <stop offset=".575" stopColor="#FBE9C1" />
          <stop offset="1" stopColor="#F9DF7B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_1"
          x1="54.994"
          x2="54.994"
          y1="97.906"
          y2="3.301"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".08" stopColor="#E8CDA0" />
          <stop offset=".425" stopColor="#693712" />
          <stop offset=".585" stopColor="#875A17" />
          <stop offset=".78" stopColor="#F7EBC6" />
          <stop offset="1" stopColor="#723F17" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function UpperArrowBack({ x = 0, y = 0, ...restProps }: SVGProps<SVGSVGElement>) {
  return (
    <svg x={+x - 126 / 2} y={y} width="126" height="69" viewBox="0 0 126 69" {...restProps}>
      <path
        fill="url(#paint0_linear_298_1179)"
        d="M0 68.365c.473-9.79 2.39-48.971 2.651-55.597.311-7.573 2.95-9.615 5.303-10.263C10.306 1.857 17.4.811 20.86.675c3.46-.125 6.597 2.415 6.597 2.415h71.06s3.136-2.54 6.596-2.416c3.46.125 10.555 1.17 12.908 1.831 2.352.648 5.003 2.69 5.302 10.275.261 6.626 2.178 45.808 2.651 55.598H0v-.013Z"
      />
      <defs>
        <linearGradient
          id="paint0_linear_298_1179"
          x1="62.987"
          x2="62.987"
          y1="68.378"
          y2=".67"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".56" stopColor="#734F1F" />
          <stop offset="1" stopColor="#241104" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function BigArrow({
  x = 0,
  y = 0,
  isTimerActive,
  ...restProps
}: { isTimerActive: boolean } & SVGProps<SVGSVGElement>) {
  const { $t } = useTranslate();

  const spinText = $t({ defaultMessage: "SPIN!" }).toUpperCase();

  return (
    <svg
      x={+x - 324 / 2}
      y={+y - 224 / 2}
      width="324"
      height="224"
      viewBox="0 0 324 224"
      {...restProps}
    >
      <path
        d="M322.763 182.904C322.763 182.904 316.479 168.646 290.273 175.188C291.71 195.249 290.841 216.016 290.841 216.016L290.373 223.966C290.373 223.966 323.967 207.998 323.967 189.68C324 186.493 322.763 182.904 322.763 182.904Z"
        fill="#4E0093"
      />
      <path
        d="M322.763 182.904C322.763 182.904 316.479 168.646 290.273 175.188C291.71 195.249 290.841 216.016 290.841 216.016L290.373 223.966C290.373 223.966 323.967 207.998 323.967 189.68C324 186.493 322.763 182.904 322.763 182.904Z"
        fill="url(#paint0_linear_641_38250)"
      />
      <path
        d="M322.763 182.904C322.763 182.904 316.479 168.646 290.273 175.188C324.033 184.179 297.426 204.878 291.042 208.87C290.975 213.298 290.841 216.016 290.841 216.016L290.373 223.966C290.373 223.966 323.967 207.998 323.967 189.68C324 186.493 322.763 182.904 322.763 182.904Z"
        fill="#BA7C00"
      />
      <path
        d="M322.763 182.904C322.763 182.904 316.479 168.646 290.273 175.188C324.033 184.179 297.426 204.878 291.042 208.87C290.975 213.298 290.841 216.016 290.841 216.016L290.373 223.966C290.373 223.966 323.967 207.998 323.967 189.68C324 186.493 322.763 182.904 322.763 182.904Z"
        fill="url(#paint1_linear_641_38250)"
      />
      <path
        d="M322.763 182.904C322.763 182.904 275.532 18.3508 273.226 10.165C271.755 5.13284 266.407 2.68384 259.621 2.68384C215.532 2.68384 91.6546 63.238 87.9777 65.1167C80.3231 69.0082 78.0167 64.0431 74.9081 59.6819C71.8329 55.3206 69.6602 46.9336 55.688 64.5463C41.7493 82.159 14.039 124.262 8.18941 133.991C-1.50418 150.094 -5.81616 153.314 13.4707 162.607C32.7911 171.9 76.4457 193.035 99.61 201.288C110.407 205.146 114.986 205.213 116.29 203.737C119.699 200.181 119.097 197.027 118.964 195.417C118.596 185.554 116.858 186.057 128.357 185.017C135.71 184.346 214.93 175.557 257.883 175.557C289.671 175.557 308.724 180.455 308.724 192.935C308.724 205.448 290.875 216.016 290.875 216.016L290.407 223.966C290.407 223.966 324 207.998 324 189.68C324 186.493 322.763 182.904 322.763 182.904Z"
        fill="#BA7C00"
      />
      <path
        d="M322.763 182.904C322.763 182.904 275.532 18.3508 273.226 10.165C271.755 5.13284 266.407 2.68384 259.621 2.68384C215.532 2.68384 91.6546 63.238 87.9777 65.1167C80.3231 69.0082 78.0167 64.0431 74.9081 59.6819C71.8329 55.3206 69.6602 46.9336 55.688 64.5463C41.7493 82.159 14.039 124.262 8.18941 133.991C-1.50418 150.094 -5.81616 153.314 13.4707 162.607C32.7911 171.9 76.4457 193.035 99.61 201.288C110.407 205.146 114.986 205.213 116.29 203.737C119.699 200.181 119.097 197.027 118.964 195.417C118.596 185.554 116.858 186.057 128.357 185.017C135.71 184.346 214.93 175.557 257.883 175.557C289.671 175.557 308.724 180.455 308.724 192.935C308.724 205.448 290.875 216.016 290.875 216.016L290.407 223.966C290.407 223.966 324 207.998 324 189.68C324 186.493 322.763 182.904 322.763 182.904Z"
        fill="url(#paint2_linear_641_38250)"
      />
      <path
        d="M260.29 0C216.201 0 92.3231 60.5541 88.6463 62.4328C80.9917 66.3244 78.6852 61.3593 75.5766 56.9981C72.5014 52.6368 70.3287 44.2498 56.3566 61.8625C42.4178 79.4752 13.7047 124.262 7.85516 133.991C-1.83843 150.094 -4.14484 151.637 15.1421 160.93C34.4624 170.223 77.1142 190.351 100.279 198.604C123.443 206.857 118.93 198.47 119.632 190.72C120.334 182.937 119.198 182.367 130.696 181.327C138.05 180.656 225.627 170.524 268.579 170.524C315.443 170.524 321.827 180.186 322.663 182.434C320.156 173.309 281.181 32.0719 273.928 7.44766C272.423 2.449 267.075 0 260.29 0Z"
        fill="#F0BF36"
      />
      <path
        d="M260.29 0C216.201 0 92.3231 60.5541 88.6463 62.4328C80.9917 66.3244 78.6852 61.3593 75.5766 56.9981C72.5014 52.6368 70.3287 44.2498 56.3566 61.8625C42.4178 79.4752 13.7047 124.262 7.85516 133.991C-1.83843 150.094 -4.14484 151.637 15.1421 160.93C34.4624 170.223 77.1142 190.351 100.279 198.604C123.443 206.857 118.93 198.47 119.632 190.72C120.334 182.937 119.198 182.367 130.696 181.327C138.05 180.656 225.627 170.524 268.579 170.524C315.443 170.524 321.827 180.186 322.663 182.434C320.156 173.309 281.181 32.0719 273.928 7.44766C272.423 2.449 267.075 0 260.29 0Z"
        fill="url(#paint3_linear_641_38250)"
      />
      <path
        d="M260.29 0C216.201 0 92.3231 60.5541 88.6463 62.4328C80.9917 66.3244 78.6852 61.3593 75.5766 56.9981C72.5014 52.6368 70.3287 44.2498 56.3566 61.8625C42.4178 79.4752 13.7047 124.262 7.85516 133.991C-1.83843 150.094 -4.14484 151.637 15.1421 160.93C34.4624 170.223 77.1142 190.351 100.279 198.604C123.443 206.857 118.93 198.47 119.632 190.72C120.334 182.937 119.198 182.367 130.696 181.327C138.05 180.656 225.627 170.524 268.579 170.524C315.443 170.524 321.827 180.186 322.663 182.434C320.156 173.309 281.181 32.0719 273.928 7.44766C272.423 2.449 267.075 0 260.29 0Z"
        fill="url(#paint4_linear_641_38250)"
      />
      <path
        d="M260.624 6.03223C216.535 6.03223 90.5849 66.4522 86.3398 68.096C82.0947 69.7399 78.0836 68.0625 75.1086 63.869C72.1337 59.6755 70.3955 51.624 56.9582 68.5657C43.5877 85.4739 18.3176 124.524 12.702 133.884C3.37605 149.383 1.16992 150.859 19.7214 159.783C38.273 168.706 74.6072 186.386 96.8691 194.304C119.131 202.221 115.487 194.169 116.156 186.722C116.825 179.274 121.905 179.744 126.451 179.039C130.997 178.335 224.557 166.492 276.936 166.492C312.368 166.492 319.889 175.483 321.46 179.173L275.198 12.9096C275.198 12.9096 274.663 6.03223 260.624 6.03223ZM321.46 179.173L321.894 180.717C321.894 180.683 321.861 180.113 321.46 179.173Z"
        fill="url(#paint5_linear_641_38250)"
      />
      <path
        d="M260.624 5.03223C216.535 5.03223 90.5849 65.4522 86.3398 67.096C82.0947 68.7399 78.0836 67.0625 75.1086 62.869C72.1337 58.6755 70.3955 50.624 56.9582 67.5657C43.5877 84.4739 18.3176 123.524 12.702 132.884C3.37605 148.383 1.16992 149.859 19.7214 158.783C38.273 167.706 74.6072 185.386 96.8691 193.304C119.131 201.221 115.487 193.169 116.156 185.722C116.825 178.274 121.905 178.744 126.451 178.039C130.997 177.335 224.557 165.492 276.936 165.492C312.368 165.492 319.889 174.483 321.46 178.173L275.198 11.9096C275.198 11.9096 274.663 5.03223 260.624 5.03223ZM321.46 178.173L321.894 179.717C321.894 179.683 321.861 179.113 321.46 178.173Z"
        fill="#5600A2"
      />
      <path
        d="M260.624 5.03223C216.535 5.03223 90.5849 65.4522 86.3398 67.096C82.0947 68.7399 78.0836 67.0625 75.1086 62.869C72.1337 58.6755 70.3955 50.624 56.9582 67.5657C43.5877 84.4739 18.3176 123.524 12.702 132.884C3.37605 148.383 1.16992 149.859 19.7214 158.783C38.273 167.706 74.6072 185.386 96.8691 193.304C119.131 201.221 115.487 193.169 116.156 185.722C116.825 178.274 121.905 178.744 126.451 178.039C130.997 177.335 224.557 165.492 276.936 165.492C312.368 165.492 319.889 174.483 321.46 178.173L275.198 11.9096C275.198 11.9096 274.663 5.03223 260.624 5.03223ZM321.46 178.173L321.894 179.717C321.894 179.683 321.861 179.113 321.46 178.173Z"
        fill="url(#paint6_linear_641_38250)"
      />
      <path
        d="M275.317 13.2609L322 179.807C322 179.807 316.386 163.755 272.778 163.755C221.484 163.755 129.789 175.311 125.345 175.977C120.901 176.643 115.888 176.177 115.253 183.437C114.585 190.697 118.194 198.523 96.3729 190.83C74.5187 183.137 38.9303 165.953 20.7518 157.261C2.57329 148.569 4.74536 147.104 13.9014 132.051C19.4151 122.96 44.1432 84.9613 57.3092 68.4765C70.4419 51.9918 72.1795 59.8512 75.0867 63.9141C77.9939 67.977 81.9371 69.6088 86.0807 68.0103C90.2243 66.4451 217.273 7 260.481 7C274.716 7 275.317 13.2609 275.317 13.2609Z"
        fill="url(#paint7_linear_641_38250)"
        fillOpacity="0.3"
      />

      <g transform="rotate(-14.41)">
        {isTimerActive ? (
          <Timer x={50} y={150} />
        ) : (
          <g>
            <path id="fw-spin-text" d="M50 165H300" />
            <text
              alignmentBaseline="middle"
              fontWeight="bold"
              fontSize="60px"
              className="fill-white [text-shadow:0_0.25rem_0.5rem_#000]"
            >
              <textPath
                href="#fw-spin-text"
                method="stretch"
                lengthAdjust="spacingAndGlyphs"
                textLength={spinText.length > 5 ? "200" : undefined}
              >
                {spinText}
              </textPath>
            </text>
          </g>
        )}
      </g>

      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 4 161.308)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 7 158.308)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 13 151.308)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 29.3846 176.308)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 32.3846 173.308)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 38.3846 166.308)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 58.2308 189)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 61.2308 186)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 67.2308 179)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 85.9231 200.538)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 88.9231 197.538)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 94.9231 190.538)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 108.308 181.769)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 111.308 178.769)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 117.308 171.769)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 139.308 174.692)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 142.308 171.692)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 148.308 164.692)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 172.154 169.769)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 175.154 166.769)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 181.154 159.769)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 206.154 166.693)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 209.154 163.693)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 215.154 156.693)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 242.692 164.923)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 245.692 161.923)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 251.692 154.923)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 275.692 165.923)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 278.692 162.923)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 284.692 155.923)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 16.6923 135.923)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 19.6923 132.923)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 25.6923 125.923)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 31.6923 111.692)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 34.6923 108.692)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 40.6923 101.692)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 47.8462 89.7695)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 50.8462 86.7695)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 56.8462 79.7695)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 75.5385 96.6924)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 78.5385 93.6924)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 84.5385 86.6924)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 105.538 82.6155)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 108.538 79.6155)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 114.538 72.6155)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 136.385 70.5386)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 139.385 67.5386)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 145.385 60.5386)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 170.231 57.4617)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 173.231 54.4617)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 179.231 47.4617)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 204.385 46.5386)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 207.385 43.5386)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 213.385 36.5386)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <ellipse
          cx="17.5"
          cy="18"
          rx="17.5"
          ry="18"
          transform="matrix(1 0 0 -1 237.385 39.5386)"
          fill="url(#paint0_radial_643_38418)"
        />
        <ellipse
          cx="14.5"
          cy="15"
          rx="14.5"
          ry="15"
          transform="matrix(1 0 0 -1 240.385 36.5386)"
          fill="url(#paint1_radial_643_38418)"
        />
        <ellipse
          cx="8.5"
          cy="8"
          rx="8.5"
          ry="8"
          transform="matrix(1 0 0 -1 246.385 29.5386)"
          fill="url(#paint2_radial_643_38418)"
        />
      </g>
      <g>
        <path
          d="M275.056 161.477C275.056 161.477 292.651 165.227 307.181 169.12C321.71 173.013 334.944 177.523 334.944 177.523C334.944 177.523 321.228 174.813 306.698 170.92C292.169 167.026 275.056 161.477 275.056 161.477Z"
          fill="url(#paint8_linear_641_38250)"
        />
        <path
          d="M313.259 138.678C313.259 138.678 309.833 156.905 305.826 171.861C301.819 186.817 296.741 200.322 296.741 200.322C296.741 200.322 299.097 186.087 303.104 171.132C307.111 156.176 313.259 138.678 313.259 138.678Z"
          fill="url(#paint9_linear_641_38250)"
        />
        <path
          d="M320.225 159.28C320.225 159.28 312.46 165.969 305.072 170.928C297.684 175.887 289.775 179.72 289.775 179.72C289.775 179.72 295.568 174.355 302.956 169.396C310.343 164.437 320.225 159.28 320.225 159.28Z"
          fill="url(#paint10_linear_641_38250)"
        />
        <path
          d="M292.417 143.847C292.417 143.847 301.009 158.58 307.114 171.028C313.22 183.475 317.583 195.154 317.583 195.154C317.583 195.154 310.621 183.744 304.516 171.296C298.41 158.848 292.417 143.847 292.417 143.847Z"
          fill="url(#paint11_linear_641_38250)"
        />
      </g>
      <g>
        <path
          d="M29.4411 26.002C29.4411 26.002 51.455 40.8397 69.1944 54.1826C86.9338 67.5254 102.559 80.9983 102.559 80.9983C102.559 80.9983 85.281 69.7228 67.5416 56.38C49.8022 43.0372 29.4411 26.002 29.4411 26.002Z"
          fill="url(#paint12_linear_641_38250)"
        />
        <path
          d="M94.3046 15.869C94.3046 15.869 79.5626 38.9279 65.8284 57.1876C52.0942 75.4473 37.6953 91.1314 37.6953 91.1314C37.6953 91.1314 48.7706 72.9474 62.5048 54.6877C76.239 36.4281 94.3046 15.869 94.3046 15.869Z"
          fill="url(#paint13_linear_641_38250)"
        />
        <path
          d="M92.4759 47.9097C92.4759 47.9097 78.1578 52.7814 65.311 55.4941C52.4641 58.2067 39.524 59.0906 39.524 59.0906C39.524 59.0906 50.4123 54.9431 63.2591 52.2304C76.106 49.5178 92.4759 47.9097 92.4759 47.9097Z"
          fill="url(#paint14_linear_641_38250)"
        />
        <path
          d="M62.9272 11.4471C62.9272 11.4471 66.5604 36.3519 68.0513 56.7572C69.5423 77.1625 69.0727 95.5533 69.0727 95.5533C69.0727 95.5533 65.8376 76.0963 64.3466 55.691C62.8557 35.2857 62.9272 11.4471 62.9272 11.4471Z"
          fill="url(#paint15_linear_641_38250)"
        />
      </g>
      <defs>
        <radialGradient id="paint0_radial_643_38418">
          <stop offset=".31" stopColor="#FFAF04" stopOpacity="0" />
          <stop offset=".825" stopColor="#FFAF04" stopOpacity="0" />
          <stop offset="1" stopColor="#FFAF04" stopOpacity=".06" />
        </radialGradient>
        <radialGradient id="paint1_radial_643_38418">
          <stop stopColor="#FFAF04" />
          <stop offset=".23" stopColor="#FFAF04" stopOpacity=".9" />
          <stop offset=".675" stopColor="#FFAF04" stopOpacity=".28" />
          <stop offset="1" stopColor="#FFAF04" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint2_radial_643_38418">
          <stop offset=".6" stopColor="#fff" />
          <stop offset=".7" stopColor="#fff" stopOpacity=".9" />
          <stop offset=".82" stopColor="#FFF2BE" stopOpacity=".28" />
          <stop offset="1" stopColor="#FFAF04" stopOpacity="0" />
        </radialGradient>
        <filter
          id="filter0_d_641_38250"
          x="70.8402"
          y="59.7803"
          width="202.665"
          height="90.7092"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_641_38250" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_641_38250"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_641_38250"
          x1="290.273"
          y1="203.6"
          x2="323.986"
          y2="193.902"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.115" stopOpacity="0.2" />
          <stop offset="0.35" stopOpacity="0.7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_641_38250"
          x1="290.273"
          y1="203.548"
          x2="323.986"
          y2="193.954"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.240919" stopOpacity="0" />
          <stop offset="0.66" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_641_38250"
          x1="-0.0406548"
          y1="91.9029"
          x2="323.917"
          y2="135.366"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="0.11" stopColor="white" stopOpacity="0" />
          <stop offset="0.21" stopOpacity="0" />
          <stop offset="0.615" stopOpacity="0.5" />
          <stop offset="1" stopOpacity="0.7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_641_38250"
          x1="0.549524"
          y1="148.2"
          x2="322.98"
          y2="54.4381"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.84" stopOpacity="0" />
          <stop offset="0.91" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_641_38250"
          x1="0.549525"
          y1="146.025"
          x2="322.965"
          y2="56.5882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="0.02" stopColor="white" stopOpacity="0" />
          <stop offset="0.24" stopColor="white" stopOpacity="0" />
          <stop offset="0.271296" stopColor="white" stopOpacity="0.9" />
          <stop offset="0.29" stopColor="white" stopOpacity="0" />
          <stop offset="0.81" stopColor="white" stopOpacity="0" />
          <stop offset="0.85" stopColor="white" />
          <stop offset="0.87" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_641_38250"
          x1="5.67862"
          y1="147.106"
          x2="321.894"
          y2="56.4931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.07" stopColor="#FFD964" />
          <stop offset="0.27" stopColor="white" />
          <stop offset="0.35" stopColor="#B78114" />
          <stop offset="0.8" stopColor="#FFD964" />
          <stop offset="0.85" stopColor="white" />
          <stop offset="0.88" stopColor="#B78114" />
          <stop offset="0.9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_641_38250"
          x1="5.67862"
          y1="141.821"
          x2="322.054"
          y2="60.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopOpacity="0" />
          <stop offset="0.16" stopOpacity="0.2" />
          <stop offset="0.36" stopOpacity="0.25" />
          <stop offset="0.48" stopOpacity="0" />
          <stop offset="0.74" stopOpacity="0.5" />
          <stop offset="0.85" stopOpacity="0" />
          <stop offset="0.92" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_641_38250"
          x1="147.436"
          y1="7"
          x2="181.564"
          y2="194"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.495" stopColor="#FF0000" stopOpacity="0" />
          <stop offset="0.905" stopColor="#FF0000" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_641_38250"
          x1="275.056"
          y1="161.477"
          x2="334.944"
          y2="177.523"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.35" stopColor="white" stopOpacity="0.3" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="0.65" stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_641_38250"
          x1="313.259"
          y1="138.678"
          x2="296.741"
          y2="200.322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.35" stopColor="white" stopOpacity="0.3" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="0.65" stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_641_38250"
          x1="320.225"
          y1="159.28"
          x2="300.051"
          y2="187.157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.35" stopColor="white" stopOpacity="0.3" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="0.65" stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_641_38250"
          x1="292.417"
          y1="143.847"
          x2="297.922"
          y2="197.183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.35" stopColor="white" stopOpacity="0.3" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="0.65" stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_641_38250"
          x1="29.4411"
          y1="26.002"
          x2="102.559"
          y2="80.9983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.35" stopColor="white" stopOpacity="0.3" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="0.65" stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_641_38250"
          x1="94.3046"
          y1="15.869"
          x2="37.6953"
          y2="91.1314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.35" stopColor="white" stopOpacity="0.3" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="0.65" stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_641_38250"
          x1="92.4759"
          y1="47.9097"
          x2="49.4867"
          y2="74.9371"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.35" stopColor="white" stopOpacity="0.3" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="0.65" stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_641_38250"
          x1="62.9272"
          y1="11.4471"
          x2="41.0434"
          y2="87.4866"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.35" stopColor="white" stopOpacity="0.3" />
          <stop offset="0.5" stopColor="white" />
          <stop offset="0.65" stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function MiddleCircle({ x = 0, y = 0, ...restProps }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      x={+x - 263 / 2}
      y={+y - 263 / 2}
      width="263"
      height="263"
      viewBox="0 0 263 263"
      {...restProps}
    >
      <path
        fill="#B78114"
        d="M176.56 8.442c67.963 24.878 102.897 100.155 78.019 168.118S154.424 279.457 86.461 254.579-16.436 154.424 8.442 86.461C33.321 18.498 108.597-16.436 176.56 8.442Z"
      />
      <path
        fill="url(#paint0_linear_751_289)"
        fillOpacity=".6"
        d="M176.56 8.442c67.963 24.878 102.897 100.155 78.019 168.118S154.424 279.457 86.461 254.579-16.436 154.424 8.442 86.461C33.321 18.498 108.597-16.436 176.56 8.442Z"
      />
      <path
        fill="url(#paint1_linear_751_289)"
        fillOpacity=".6"
        d="M176.56 8.442c67.963 24.878 102.897 100.155 78.019 168.118S154.424 279.457 86.461 254.579-16.436 154.424 8.442 86.461C33.321 18.498 108.597-16.436 176.56 8.442Z"
      />
      <path
        fill="url(#paint2_linear_751_289)"
        fillOpacity=".6"
        d="M176.56 8.442c67.963 24.878 102.897 100.155 78.019 168.118S154.424 279.457 86.461 254.579-16.436 154.424 8.442 86.461C33.321 18.498 108.597-16.436 176.56 8.442Z"
      />
      <path
        fill="url(#paint3_linear_751_289)"
        fillOpacity=".6"
        d="M176.56 8.442c67.963 24.878 102.897 100.155 78.019 168.118S154.424 279.457 86.461 254.579-16.436 154.424 8.442 86.461C33.321 18.498 108.597-16.436 176.56 8.442Z"
      />
      <path
        fill="url(#paint4_linear_751_289)"
        fillOpacity=".6"
        d="M176.56 8.442c67.963 24.878 102.897 100.155 78.019 168.118S154.424 279.457 86.461 254.579-16.436 154.424 8.442 86.461C33.321 18.498 108.597-16.436 176.56 8.442Z"
      />
      <path
        fill="url(#paint5_linear_751_289)"
        fillOpacity=".6"
        d="M176.56 8.442c67.963 24.878 102.897 100.155 78.019 168.118S154.424 279.457 86.461 254.579-16.436 154.424 8.442 86.461C33.321 18.498 108.597-16.436 176.56 8.442Z"
      />
      <path
        fill="url(#paint6_radial_751_289)"
        d="M176.56 8.442c67.963 24.878 102.897 100.155 78.019 168.118S154.424 279.457 86.461 254.579-16.436 154.424 8.442 86.461C33.321 18.498 108.597-16.436 176.56 8.442Z"
      />
      <defs>
        <linearGradient
          id="paint0_linear_751_289"
          x1="246.678"
          x2="16.343"
          y1="198.145"
          y2="64.876"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".4" stopColor="#fff" stopOpacity="0" />
          <stop offset=".5" stopColor="#fff" />
          <stop offset=".6" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_751_289"
          x1="-8.803"
          x2="271.824"
          y1="133.572"
          y2="129.449"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".4" stopColor="#fff" stopOpacity="0" />
          <stop offset=".5" stopColor="#fff" />
          <stop offset=".6" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_751_289"
          x1="-14.432"
          x2="277.454"
          y1="217.646"
          y2="45.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".4" stopColor="#fff" stopOpacity="0" />
          <stop offset=".5" stopColor="#fff" />
          <stop offset=".6" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_751_289"
          x1="62.761"
          x2="200.26"
          y1="245.903"
          y2="17.118"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".4" stopColor="#fff" stopOpacity="0" />
          <stop offset=".5" stopColor="#fff" />
          <stop offset=".6" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_751_289"
          x1="129.518"
          x2="133.503"
          y1="270.34"
          y2="-7.319"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".4" stopColor="#fff" stopOpacity="0" />
          <stop offset=".5" stopColor="#fff" />
          <stop offset=".6" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_751_289"
          x1="45.141"
          x2="217.88"
          y1="-13.794"
          y2="276.815"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".4" stopColor="#fff" stopOpacity="0" />
          <stop offset=".5" stopColor="#fff" />
          <stop offset=".6" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <radialGradient id="paint6_radial_751_289">
          <stop offset=".73" />
          <stop offset=".74" stopOpacity="0" />
          <stop offset=".76" stopOpacity="0" />
          <stop offset=".77" stopOpacity=".4" />
          <stop offset=".8" stopOpacity=".1" />
          <stop offset=".83" stopOpacity="0" />
          <stop offset=".9" stopOpacity="0" />
          <stop offset=".94" stopOpacity=".1" />
          <stop offset=".97" stopOpacity=".5" />
          <stop offset=".98" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export function SpinButton({
  x = 0,
  y = 0,
  isReadyToSpin,
  ...restProps
}: { isReadyToSpin: boolean } & SVGProps<SVGSVGElement>) {
  const midPoint = 189 / 2;

  return (
    <svg
      x={+x - midPoint}
      y={+y - midPoint}
      width="189"
      height="189"
      viewBox="0 0 189 189"
      className={`group ${isReadyToSpin ? "pointer-events-auto" : "pointer-events-none"}`}
      {...restProps}
    >
      <circle
        cx="50%"
        cy="50%"
        r="50%"
        className="fill-[#E80000] group-hover:fill-[#FB2929] group-active:fill-[#D40101]"
      />
      <circle
        cx="50%"
        cy="50%"
        r="50%"
        fill="url(#paint0_radial_748_290)"
        className="opacity-70 group-active:opacity-90"
      />
      <path
        fill="url(#paint1_radial_748_290)"
        fillOpacity=".7"
        d="M187.788 93.653c0 31.129-16.515 56.369-36.883 56.369-20.368 0-36.883-25.24-36.883-56.37 0-31.128 16.515-56.368 36.883-56.368 20.368 0 36.883 25.24 36.883 56.369Z"
      />
      <path
        fill="url(#paint2_radial_748_290)"
        fillOpacity=".7"
        d="M74.187 93.653c0 31.129-16.515 56.369-36.884 56.369-20.368 0-36.883-25.24-36.883-56.37 0-31.128 16.515-56.368 36.883-56.368 20.369 0 36.884 25.24 36.884 56.369Z"
      />
      <path
        fill="url(#paint3_radial_748_290)"
        d="M94.64 11.621c25.871 0 46.848 11.106 46.848 24.802 0 13.697-20.977 24.803-46.849 24.803-25.871 0-46.849-11.106-46.849-24.803 0-13.696 20.977-24.802 46.85-24.802Z"
      />
      {isReadyToSpin ? (
        <ArrowIcon x={midPoint} y={midPoint} />
      ) : (
        <LockIcon x={midPoint} y={midPoint} />
      )}
      <defs>
        <radialGradient id="paint0_radial_748_290">
          <stop offset=".1" stopOpacity="0" />
          <stop offset=".76" stopOpacity=".1" />
          <stop offset=".84" stopOpacity=".2" />
          <stop offset=".9" stopOpacity=".3" />
          <stop offset=".97" stopOpacity=".6" />
          <stop offset="1" stopOpacity=".8" />
        </radialGradient>
        <radialGradient id="paint1_radial_748_290">
          <stop offset=".395" stopColor="#fff" stopOpacity=".8" />
          <stop offset=".84" stopColor="#fff" stopOpacity=".1" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint2_radial_748_290">
          <stop offset=".395" stopColor="#fff" stopOpacity=".8" />
          <stop offset=".84" stopColor="#fff" stopOpacity=".1" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint3_radial_748_290">
          <stop offset=".365" stopColor="#fff" />
          <stop offset=".75" stopColor="#fff" stopOpacity=".2" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

function LockIcon({ x = 0, y = 0, ...restProps }: SVGProps<SVGSVGElement>) {
  return (
    <svg x={+x - 58 / 2} y={+y - 69 / 2} width="58" height="69" viewBox="0 0 58 69" {...restProps}>
      <path
        fill="#fff"
        d="M29.25 69C45.141 68.954 58 61.285 58 52.544c0-8.768-1.598-20.292-1.598-20.292-.65-3.266-4.29-7.326-7.709-7.326v-4.813C48.693 9.048 40 .073 29.25 0 18.5.072 9.807 9.048 9.807 20.113v4.813c-3.419 0-7.06 4.06-7.71 7.326 0 0-1.597 11.524-1.597 20.292C.5 61.285 13.36 68.954 29.25 69Zm3.53-24.049 1.093 9.007c0 1.334-1.244 2.416-2.777 2.416H27.45c-1.533 0-2.776-1.082-2.776-2.416l1.278-9.007a6.703 6.703 0 0 1-3.078-5.667c0-3.685 2.907-6.672 6.492-6.672s6.491 2.987 6.491 6.672a6.703 6.703 0 0 1-3.078 5.667Zm-13.22-25.43c0-5.524 4.329-10.007 9.69-10.066 5.362.06 9.69 4.542 9.69 10.067v4.565H19.56v-4.565Z"
      />
    </svg>
  );
}

function ArrowIcon({ x = 0, y = 0, ...restProps }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      x={+x - 108 / 2}
      y={+y - 107 / 2}
      width="108"
      height="107"
      viewBox="0 0 108 107"
      {...restProps}
    >
      <g>
        <path
          fill="url(#arrow-icon_paint0_linear_0_1)"
          d="M.184 53.598C.184 24.34 23.96.539 53.17.539a53.028 53.028 0 0 1 38.446 16.54l9.967-7.174 5.61 48.762-44.444-20.665 9.364-6.788c-5.308-4.51-11.952-7.002-18.943-7.002-16.18 0-29.34 13.189-29.34 29.386 0 16.196 13.16 29.386 29.34 29.386 8.026 0 15.49-3.18 21.1-8.98l.82-.859L92.393 89.3l-.863.902C81.433 100.813 67.798 106.7 53.127 106.7 23.96 106.656.184 82.855.184 53.598Z"
        />
        <path
          fill="url(#arrow-icon_paint1_linear_0_1)"
          d="M.184 53.598C.184 24.34 23.96.539 53.17.539a53.028 53.028 0 0 1 38.446 16.54l9.967-7.174 5.61 48.762-44.444-20.665 9.364-6.788c-5.308-4.51-11.952-7.002-18.943-7.002-16.18 0-29.34 13.189-29.34 29.386 0 16.196 13.16 29.386 29.34 29.386 8.026 0 15.49-3.18 21.1-8.98l.82-.859L92.393 89.3l-.863.902C81.433 100.813 67.798 106.7 53.127 106.7 23.96 106.656.184 82.855.184 53.598Z"
        />
        <path
          fill="url(#arrow-icon_paint2_linear_0_1)"
          d="M.184 53.598C.184 24.34 23.96.539 53.17.539a53.028 53.028 0 0 1 38.446 16.54l9.967-7.174 5.61 48.762-44.444-20.665 9.364-6.788c-5.308-4.51-11.952-7.002-18.943-7.002-16.18 0-29.34 13.189-29.34 29.386 0 16.196 13.16 29.386 29.34 29.386 8.026 0 15.49-3.18 21.1-8.98l.82-.859L92.393 89.3l-.863.902C81.433 100.813 67.798 106.7 53.127 106.7 23.96 106.656.184 82.855.184 53.598Z"
        />
        <path
          fill="#fff"
          d="M1.56 53.57c0-28.68 23.108-51.96 51.574-51.96 15.133 0 28.723 6.565 38.154 17.018l9.089-6.652 5.102 44.617-40.427-18.918 8.917-6.479c-5.445-5.14-12.733-8.292-20.792-8.292-16.806 0-30.439 13.734-30.439 30.665 0 16.931 13.633 30.666 30.438 30.666 8.575 0 16.334-3.585 21.864-9.372l15.434 14.512c-9.389 9.934-22.636 16.153-37.34 16.153-28.466 0-51.574-23.28-51.574-51.959Z"
        />
        {/* <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 54 53.5"
          to="360 54 53.5"
          dur="2s"
          repeatCount="indefinite"
        /> */}
      </g>
      <defs>
        <linearGradient
          id="arrow-icon_paint0_linear_0_1"
          x1="49.443"
          x2="57.511"
          y1="106.699"
          y2=".507"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".27" stopColor="#fff" stopOpacity="0" />
          <stop offset=".5" stopColor="#fff" />
          <stop offset=".715" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="arrow-icon_paint1_linear_0_1"
          x1="33.232"
          x2="74.144"
          y1=".539"
          y2="106.699"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".315" stopColor="#fff" stopOpacity="0" />
          <stop offset=".525" stopColor="#fff" />
          <stop offset=".745" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="arrow-icon_paint2_linear_0_1"
          x1=".184"
          x2="107.436"
          y1="22.822"
          y2="83.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".27" stopColor="#fff" stopOpacity="0" />
          <stop offset=".5" stopColor="#fff" />
          <stop offset=".715" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
