import { useModal } from "@lobby/core/src/shared";
import { useEffect } from "react";
import { LogInModal } from "widget/log-in-modal";
import { PasswordForgotModal } from "widget/password-forgot-modal";
import { RegisterModal } from "widget/register-modal";

export function SignInPage() {
  const { open: openLogInModal } = useModal("log-in");

  useEffect(() => openLogInModal(), [openLogInModal]);

  return (
    <>
      <LogInModal isClosable={false} />
      <RegisterModal isClosable={false} />
      <PasswordForgotModal isClosable={false} />
    </>
  );
}
