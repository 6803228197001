import { Button, Icon } from "@lobby/core/src/shared";
import { clsx } from "clsx";
import type { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { Spin } from "../spin";

function Portal({ children }: PropsWithChildren) {
  // @ts-ignore
  return <>{createPortal(children, document.getElementById("root"))}</>;
}

function Overlay({ className = "", onClick }: { className?: string; onClick?: VoidFunction }) {
  return <div className={clsx(className, "fixed inset-0 bg-black/80")} onClick={onClick} />;
}

function PendingOverlay({ isActive }: { isActive: boolean }) {
  return (
    <div
      className={clsx(
        "absolute inset-0 overflow-hidden rounded-inherit",
        isActive ? "visible" : "invisible",
      )}
    >
      <div className="absolute inset-0 bg-bright-gray/60" />
      <div className="absolute inset-0 flex-center">
        <Spin />
      </div>
    </div>
  );
}

function Title({ className, children }: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx("modal-dialog__title", className)}>{children}</div>;
}

function CloseButton({ className, onClick }: { className?: string; onClick: VoidFunction }) {
  return (
    <Button variant="secondary" fit onClick={onClick} className={className}>
      <Icon className="text-14 lg:text-16" name="close" />
    </Button>
  );
}

function Panel({
  className,
  centered,
  children,
}: PropsWithChildren<{ className?: string; centered?: boolean }>) {
  return (
    <div className={clsx("modal-dialog__panel", className, centered && "-translate-y-1/2 top-1/2")}>
      {children}
    </div>
  );
}

function ModalBase({
  children,
  open,
}: PropsWithChildren<{
  open: boolean;
}>) {
  return (
    open && (
      <Portal>
        <div className="modal-dialog">{children}</div>
      </Portal>
    )
  );
}

export const Modal = Object.assign(ModalBase, {
  Title,
  Panel,
  Overlay,
  PendingOverlay,
  Portal,
  CloseButton,
});
