import { useEffect, useRef } from "react";

export function useInfiniteScroll(
  onScrollEnd: VoidFunction,
  scrollElement?: HTMLDivElement | null,
) {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollElement) {
      scrollRef.current = scrollElement;
    }

    const controller = new AbortController();

    scrollRef.current?.addEventListener(
      "scroll",
      (e: Event) => {
        e.stopPropagation();

        const { scrollHeight, scrollTop, clientHeight } = e.currentTarget as HTMLDivElement;

        if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
          onScrollEnd();
        }
      },
      { signal: controller.signal },
    );

    return () => controller.abort();
  }, [onScrollEnd, scrollElement]);

  return scrollRef;
}
