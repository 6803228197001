import { Field, Input, Label, RadioGroup } from "@headlessui/react";
import { useIntlT, useTranslate } from "@lobby/ocb-intl";
import { useEffect, useRef, useState } from "react";
import type { ChangeEvent, FormEvent } from "react";
import { Payment } from "../../../../../entities/payment";
import {
  APIError,
  Alert,
  Button,
  Icon,
  Spinner,
  type TPaymentMethodsList,
  emitter,
  first,
} from "../../../../../shared";
import { NoDataPlaceholder, ProfileSection } from "../../components";
import { PaymentMethodCard } from "./components";
import { penniesToDollars, selectPaymentAccountComponent } from "./helpers";

export function WithdrawTab() {
  const { $t } = useTranslate();

  const { data, isLoading } = Payment.useMethodList("withdraw");
  const activeWithdrawRequestResponse = Payment.useActiveWithdrawRequest();

  return (
    <ProfileSection
      isLoading={isLoading || activeWithdrawRequestResponse.isLoading}
      data={data}
      placeholder={
        <NoDataPlaceholder
          topText={$t({
            defaultMessage: "Currently, there are no available methods for withdrawing funds.",
          })}
          bottomText={$t({ defaultMessage: "Please contact the support chat to withdraw funds." })}
          icon={<Icon name="creditCard" className="text-[6.25rem]" />}
        />
      }
    >
      {(validatedData) => <WithdrawTabView data={validatedData} />}
    </ProfileSection>
  );
}

function WithdrawTabView({ data }: { data: TPaymentMethodsList["data"] }) {
  const [paymentMethod, setPaymentMethod] = useState(first(data)?.id);
  const [paymentAmount, setPaymentAmount] = useState<string>("");
  const [paymentAccount, setPaymentAccount] = useState<string>("");
  const [isWithdrawWarningVisible, setWithdrawWarningVisible] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const { $t } = useTranslate();
  const { intl } = useIntlT();

  const { mutate: withdraw, isPending } = Payment.useWithdraw();
  const activeWithdrawRequestResponse = Payment.useActiveWithdrawRequest();

  const activeWithDrawRequest = activeWithdrawRequestResponse.data?.result?.data[0];
  const isWithdrawRequestActive = Boolean(activeWithDrawRequest);

  function requestWithdraw(data: FormData) {
    withdraw(
      {
        methodId: Number(data.get("payment-method")),
        amount: Number(data.get("payment-amount")),
        account: data.get("payment-account") as string,
      },
      {
        onSuccess: (data) => {
          if (data.error) {
            const { message, code } = data.error;
            emitter.emit("ERROR", { error: new APIError(message, { code }) });
          } else {
            activeWithdrawRequestResponse.refetch();
            emitter.emit("SUCCESS_MODAL_OPEN", {
              message: $t({ defaultMessage: "Withdrawal request sent" }),
            });
          }
        },
      },
    );
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const form = formRef.current;

    if (!form) return;

    const data = new FormData(form);
    data.set("payment-amount", String(Number(data.get("payment-amount")) * 100));

    if (validateForm(data)) {
      requestWithdraw(data);
    } else {
      console.error("Form is invalid");
    }
  }

  function validatePaymentMethod(method: number) {
    return data.some((item) => item.id === method);
  }

  function validatePaymentAmount(amount: number, method: number) {
    const selectedMethod = data.find((item) => item.id === method);

    if (!selectedMethod) return false;

    const isDepositMethodWithLimits = Boolean(selectedMethod.limits);

    if (isDepositMethodWithLimits) {
      const min = selectedMethod.limits?.min ?? 0;
      const max = selectedMethod.limits?.max ?? 0;

      return amount && amount >= min && amount <= max;
    }
    return amount && amount > 0;
  }

  function validatePaymentAccount(account: string) {
    return account.length > 0;
  }

  function validateForm(data: FormData) {
    const paymentMethod = Number(data.get("payment-method"));
    const paymentAmount = Number(data.get("payment-amount"));
    const paymentAccount = data.get("payment-account") as string;

    return (
      validatePaymentMethod(paymentMethod) &&
      validatePaymentAmount(paymentAmount, paymentMethod) &&
      validatePaymentAccount(paymentAccount)
    );
  }

  function handlePaymentAccountChange(e: ChangeEvent<HTMLInputElement>) {
    setPaymentAccount(e.target.value);
  }

  const selectedMethod = data.find((item) => item.id === Number(paymentMethod));
  const isDepositMethodWithLimits = Boolean(selectedMethod?.limits);

  let paymentAmountMin;
  let paymentAmountMax;
  if (isDepositMethodWithLimits) {
    paymentAmountMin = penniesToDollars(selectedMethod?.limits?.min);
    paymentAmountMax = penniesToDollars(selectedMethod?.limits?.max);
  }

  const PaymentAccountFieldComponent = selectPaymentAccountComponent(selectedMethod?.type);

  useEffect(() => {
    const defaultAmount = selectedMethod?.limits?.default;
    if (defaultAmount !== undefined) {
      setPaymentAmount(String(penniesToDollars(defaultAmount)));
    }
  }, [paymentMethod]);

  useEffect(() => {
    setWithdrawWarningVisible(isWithdrawRequestActive);
  }, [isWithdrawRequestActive]);

  return (
    <div className="gutter-stable relative min-h-0 py-1 mobile-only:pb-5 lg:h-full lg:max-h-full lg:overflow-y-auto">
      <form
        className={`text-14 ${isPending ? "blur-sm" : ""}`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <p>
          {$t({
            defaultMessage:
              "You can make a withdrawal request. To do this, specify the amount requested for withdrawal.",
          })}
        </p>
        <hr
          style={{
            borderColor: "var(--text-secondary-color)",
          }}
          className="my-3"
        />
        {isWithdrawWarningVisible && (
          <>
            <Alert
              type="warning"
              message={$t(
                {
                  defaultMessage: `At the moment, you already have an active withdrawal request from {dateTime}.
                    If you make a new request, then the previous request will be canceled.`,
                },
                {
                  dateTime: new Date(activeWithDrawRequest?.createdAt ?? "").toLocaleString(
                    intl.locale,
                    {
                      timeStyle: "short",
                      dateStyle: "short",
                    },
                  ),
                },
              )}
              afterClose={() => setWithdrawWarningVisible(false)}
            />
            <div className="mb-3" />
          </>
        )}
        <ol className="list-outside list-decimal space-y-10 ps-5">
          <li>
            <span>
              {$t({
                defaultMessage: "Choose one of the ways where you want to receive payment.",
              })}
            </span>
            <RadioGroup
              value={paymentMethod}
              onChange={setPaymentMethod}
              name="payment-method"
              className="mt-2.5"
            >
              <ul className="flex flex-wrap gap-2.5">
                {data.map(({ id, name, logoUrl, commission, limits }) => (
                  <li key={id}>
                    <PaymentMethodCard
                      id={id}
                      name={name}
                      logoUrl={logoUrl}
                      commission={commission}
                      limits={limits}
                    />
                  </li>
                ))}
              </ul>
            </RadioGroup>
          </li>
          <li>
            <PaymentAccountFieldComponent
              value={paymentAccount}
              onChange={handlePaymentAccountChange}
              disabled={!selectedMethod}
            />
          </li>
          <li>
            <span>{$t({ defaultMessage: "Enter the amount you want to withdraw" })}</span>
            <Field disabled={!selectedMethod}>
              <Label className="block font-bold data-[disabled]:opacity-50">
                {$t({ defaultMessage: "Amount" })}:
              </Label>
              <div className="flex mobile-only:flex-col gap-3 lg:gap-5">
                <Input
                  className="input w-56 appearance-none data-[disabled]:opacity-50"
                  placeholder={$t({ defaultMessage: "Enter the amount" })}
                  name="payment-amount"
                  type="number"
                  value={paymentAmount}
                  pattern="[0-9]*"
                  onChange={(e) => setPaymentAmount(e.target.value)}
                  min={paymentAmountMin}
                  max={paymentAmountMax}
                  step="1"
                  required
                />
                <Button
                  variant="success"
                  type="submit"
                  disabled={!paymentAmount}
                  className="mobile-only:mx-auto mobile-only:w-fit"
                >
                  {$t({ defaultMessage: "Request withdraw" })}
                </Button>
              </div>
            </Field>
          </li>
        </ol>
      </form>

      {isPending && (
        <div className="absolute inset-0 flex-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}
