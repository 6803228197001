import { clsx } from "clsx";
import type { CSSProperties, PropsWithChildren } from "react";

export function Skeleton({
  className,
  style,
  children,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>) {
  return (
    <div style={style} className={clsx("skeleton", className)}>
      {children}
    </div>
  );
}
