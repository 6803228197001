import { useTranslate } from "@lobby/ocb-intl";
import { Tabs } from "../../../../../shared";
import { HistoryList } from "./history-list";

export function SportBetsHistory() {
  const { $t } = useTranslate();

  return (
    <div className="flex h-full min-h-0 flex-col">
      <Tabs
        items={[
          {
            label: $t({ defaultMessage: "In game" }),
            children: <HistoryList status="created" />,
          },
          {
            label: $t({ defaultMessage: "Completed" }),
            children: <HistoryList status="non-created" />,
          },
        ]}
      />
    </div>
  );
}
