import { useTranslate } from "@lobby/ocb-intl";
import { Player } from "../../../../../entities/player";
import { Tabs } from "../../../../../shared";
import { DepositTab } from "./wallet.deposit";
import { WithdrawTab } from "./wallet.withdraw";

export function Wallet() {
  const { $t } = useTranslate();
  const { data } = Player.usePlayer();

  const isDepositActive = Boolean(data?.isDepositActive);
  const isWithdrawActive = Boolean(data?.isWithdrawActive);

  return (
    <div className="mobile-only:relative flex h-full min-h-0 flex-col">
      <Tabs
        items={[
          {
            label: $t({ defaultMessage: "Deposit" }),
            children: <DepositTab />,
            disabled: !isDepositActive,
          },
          {
            label: $t({ defaultMessage: "Withdraw" }),
            children: <WithdrawTab />,
            disabled: !isWithdrawActive,
          },
        ]}
      />
    </div>
  );
}
