import { ExternalIcon, Icon, type TGameCategory } from "@lobby/core/src/shared";
import { Link, useSearch } from "@tanstack/react-router";

interface INavigationListProps {
  items: {
    id: number;
    parentId: unknown;
    name: string;
    tag: string | null;
    actionType: TGameCategory["actionType"];
    actionData?: unknown;
  }[];
}

export function NavigationList({ items }: INavigationListProps) {
  const categoryId = useSearch({ strict: false, select: ({ cid }) => cid });

  return (
    <ul className="nav-list">
      {items.map(({ id, name, actionType, actionData }) => {
        switch (actionType) {
          case "main":
            return (
              <li key={id}>
                <Link
                  className="no-underline hover:text-inherit"
                  activeOptions={{
                    exact: items.some(({ id }) => id === categoryId),
                  }}
                  activeProps={{ className: "active" }}
                  to="/casino"
                >
                  <ExternalIcon className="text-24" id={id} />
                  {name}
                </Link>
              </li>
            );
          case "game":
            return (
              <li key={id}>
                <Link
                  className="no-underline hover:text-inherit"
                  activeProps={{ className: "active" }}
                  to="/game/$gameId"
                  params={{ gameId: actionData as string }}
                >
                  <ExternalIcon className="text-24" id={id} />
                  {name}
                </Link>
              </li>
            );
          case "sport_history":
            return (
              <li key={id}>
                <Link
                  className="no-underline hover:text-inherit"
                  to="."
                  search={(prev) => ({
                    ...prev,
                    modal: "profile",
                    profileSection: "sportBetsHistory",
                  })}
                >
                  <Icon className="text-24" name="trophySolid" />
                  {name}
                </Link>
              </li>
            );
          case "wallet":
            return (
              <li key={id}>
                <Link
                  className="no-underline hover:text-inherit"
                  to="."
                  search={(prev) => ({
                    ...prev,
                    modal: "profile",
                    profileSection: "wallet",
                  })}
                >
                  <Icon className="text-24" name="wallet" />
                  {name}
                </Link>
              </li>
            );
          default:
            return (
              <li key={id}>
                <Link
                  className="no-underline hover:text-inherit"
                  activeProps={{ className: "active" }}
                  to="/casino"
                  search={{ cid: id }}
                >
                  <ExternalIcon className="text-24" id={id} />
                  {name}
                </Link>
              </li>
            );
        }
      })}
    </ul>
  );
}
