import { useNavigate } from "@tanstack/react-router";
import { isAllGamesCategory } from "../helpers";

export function useNavigateToCategory() {
  const navigate = useNavigate();

  return (id: string | number) => {
    // Scroll to the game list container when navigating to a category,
    // and position the container at the center of the viewport.
    const anchor = document.getElementById("navigate-category-anchor");
    if (anchor && document.documentElement.scrollTop > anchor.offsetTop) {
      window.scrollTo({ top: anchor.offsetTop - window.innerHeight / 2 });
    }

    navigate({
      replace: true,
      // @ts-ignore
      search: (prev) => ({
        ...prev,
        game: undefined,
        pid: undefined,
        cid: isAllGamesCategory(id) ? undefined : id,
      }),
    });
  };
}
