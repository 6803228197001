import { apiClient } from "@lobby/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { APIError, emitter } from "../../../shared/lib";

export const MemberCard = {
  usePinGenerator() {
    return useMutation({
      mutationKey: ["MemberCard.generatePin"],
      mutationFn: () =>
        apiClient.send({
          endpoint: "MemberCard.generatePin",
        }),
    });
  },
  useCurrent() {
    return useQuery({
      queryKey: ["MemberCard.getCurrent"],
      queryFn: async () => {
        const result = await apiClient.send({
          endpoint: "MemberCard.getCurrent",
        });

        if (result.error) {
          emitter.emit("ERROR", {
            error: new APIError(result.error.message, { code: result.error.code }),
          });
        }

        return result;
      },
      select: (data) => data.result,
    });
  },
};
