import { Lobby } from "@lobby/core/src/entities";
import { Button, LanguageSelector } from "@lobby/core/src/shared";
import { useAuth, useModal } from "@lobby/core/src/shared/lib";
import { useTranslate } from "@lobby/ocb-intl";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { Modal } from "shared/ui/modal";
import { PasswordForgotForm } from "./components";

interface IModalClosable {
  isClosable?: boolean;
}

export function PasswordForgotModal({ isClosable = true }: IModalClosable) {
  const { isOpen, close } = useModal("password-forgot");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={isClosable ? close : undefined} />
      <PasswordForgotModalComponent isClosable={isClosable} />
    </Modal>
  );
}

function PasswordForgotModalComponent({ isClosable }: IModalClosable) {
  const { open: openLogInModal, close } = useModal("log-in");
  const { $t } = useTranslate();
  const navigate = useNavigate();

  const { isAuth } = useAuth();
  const { data: lobbyData } = Lobby.useLobby();

  const isRegistrationEnabled = lobbyData?.registration.enabled ?? false;

  useEffect(() => {
    if (isAuth || !isRegistrationEnabled) {
      navigate({
        to: "/",
        replace: true,
      });
    }
  }, [isRegistrationEnabled, isAuth, navigate]);

  return (
    <Modal.Panel className="mobile-only:min-h-full w-full lg:top-17 lg:w-[25rem]">
      <div className="flex items-center gap-2">
        <div className="font-bold text-14 uppercase lg:text-16 grow">
          {$t({ defaultMessage: "Forgot your password?" })}
        </div>
        <Button variant="secondary" onClick={openLogInModal}>
          {$t({ defaultMessage: "Log in" })}
        </Button>
        {isClosable && <Modal.CloseButton onClick={close} className="lg:hidden" />}
      </div>

      <div className="mt-7 mobile-only:mb-10">
        <PasswordForgotForm />
      </div>

      <div className="mt-auto flex items-center justify-between">
        <LanguageSelector orientation="top" />
      </div>
    </Modal.Panel>
  );
}
