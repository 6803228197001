import { useTranslate } from "@lobby/ocb-intl";
import type { PropsWithChildren, ReactNode } from "react";
import { Skeleton, range } from "../../shared";
import { GameCard } from "../GameCard";

export function GameListHeader({
  label,
  CategoryIcon,
  children,
}: PropsWithChildren<{
  label: string;
  CategoryIcon: ReactNode;
}>) {
  return (
    <div
      style={{
        color: "var(--game-list-header-color)",
      }}
      className="flex justify-between min-h-10"
    >
      <div className="flex items-center self-start gap-1.5 text-20 mobile-only:pt-2.5">
        {CategoryIcon}
        <span className="font-medium uppercase text-15 lg:text-22">{label}</span>
      </div>
      {children}
    </div>
  );
}

export function GameListEmpty() {
  const { $t } = useTranslate();

  return (
    <div className="text-14 lg:text-22">{$t({ defaultMessage: "No games in this category" })}</div>
  );
}

export function GameListSkeleton() {
  return (
    <section>
      <div className="flex justify-between">
        <Skeleton className="w-60 h-7.5" />

        <div className="flex">
          <Skeleton className="size-10 me-[0.3125rem]" />
          <Skeleton className="size-10" />
          <Skeleton className="h-10 w-[9.6875rem] ms-2.5" />
        </div>
      </div>

      <div className="mt-5">
        <div className="flex gap-2.5 overflow-x-auto *:basis-36 *:shrink-0">
          {range(11)((idx) => (
            <GameCard.Skeleton key={idx} />
          ))}
        </div>
      </div>
    </section>
  );
}
