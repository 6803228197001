import { useIntlT, useTranslate } from "@lobby/ocb-intl";
import { useMemo } from "react";
import { GameHistory as GameHistoryModel, usePlayerMoneyFormatter } from "../../../../../entities";
import { Icon, type TGameHistory, useInfiniteScroll, useMobile } from "../../../../../shared";
import { getMobileSectionScrollContainer } from "../../../helpers";
import { NextPageLoader, NoDataPlaceholder, ProfileSection } from "../../components";

export function GameHistory() {
  const { $t } = useTranslate();

  const { data, isLoading } = GameHistoryModel.useBetHistory();

  const normalizedData = useMemo(
    () => data?.pages.flatMap((page) => page.result?.data).filter(Boolean) ?? [],
    [data],
  );

  return (
    <ProfileSection
      isLoading={isLoading}
      data={normalizedData}
      placeholder={
        <NoDataPlaceholder
          topText={$t({ defaultMessage: "The list of games will appear here soon" })}
          bottomText={$t({ defaultMessage: "Play your first game" })}
          icon={<Icon name="gamepad" className="text-[4.5rem]" />}
        />
      }
    >
      {(validatedData) => <GameHistoryView data={validatedData} />}
    </ProfileSection>
  );
}

function GameHistoryView({ data }: { data: TGameHistory }) {
  const { $t } = useTranslate();
  const { intl } = useIntlT();
  const isMobile = useMobile();

  const formatMoney = usePlayerMoneyFormatter();

  const { fetchNextPage, isFetchingNextPage } = GameHistoryModel.useBetHistory();

  const scrollContainer = isMobile ? getMobileSectionScrollContainer() : null;

  const scrollRef = useInfiniteScroll(fetchNextPage, scrollContainer);

  return (
    <div className="mobile-only:relative flex h-full min-h-0 flex-col lg:max-h-full">
      <div className="gutter-stable lg:overflow-y-auto" ref={scrollRef}>
        <table>
          <thead>
            <tr>
              <th>{$t({ defaultMessage: "Date" })}</th>
              <th>{$t({ defaultMessage: "Game" })}</th>
              <th>{$t({ defaultMessage: "Bet" })}</th>
              <th>{$t({ defaultMessage: "Win" })}</th>
              <th>{$t({ defaultMessage: "Revenue" })}</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ createdAt, gameName, betSum, winSum, profitSum }, idx) => (
              <tr key={idx}>
                <td>
                  {new Date(createdAt * 1e3).toLocaleString(intl.locale, {
                    timeStyle: "short",
                    dateStyle: "short",
                  })}
                </td>
                <td>{gameName}</td>
                <td>{formatMoney(betSum, { style: "decimal" })}</td>
                <td>{formatMoney(winSum, { style: "decimal" })}</td>
                <td>{formatMoney(profitSum, { style: "decimal" })}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {isFetchingNextPage && <NextPageLoader />}
      </div>
    </div>
  );
}
