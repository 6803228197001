import { FavouriteBtn } from "@lobby/core/src/components";
import { Game } from "@lobby/core/src/entities/game";
import { Icon, useAuth } from "@lobby/core/src/shared";
import { useNavigate } from "@tanstack/react-router";
import { MobileMenuButton } from "features/mobile-menu-button";
import { useState } from "react";

interface IGamePageControlsMobileProps {
  gameStringId: string;
  onGameClose?: () => void;
}

export function GamePageControlsMobile({
  gameStringId,
  onGameClose,
}: IGamePageControlsMobileProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();
  const { isAuth } = useAuth();

  const favouriteMutation = Game.useFavouriteSetter();
  const game = Game.getGameByStringId(gameStringId);

  const isFavourite = Boolean(game?.isFavourite);
  const gameId = Number(game?.id);

  return (
    <div
      className={`absolute ltr:right-0 rtl:left-0 bottom-0 translate-y-1 bg-raisin-black/50 transition-transform ${isExpanded ? "translate-x-0" : "ltr:translate-x-1 rtl:-translate-x-1"}`}
    >
      <div className="flex items-center">
        <div
          className="ltr:-translate-x-1 rtl:translate-x-1 fixed top-0 ltr:left-0 rtl:right-0 h-full w-8 flex-center border-dark-charcoal border-e-1 bg-raisin-black/50 backdrop-blur-sm"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Icon
            className={`size-4 text-accent ${isExpanded ? "ltr:rotate-180 rtl:rotate-0" : "ltr:rotate-0 rtl:-rotate-180"}`}
            name="arrowLeft"
          />
        </div>
        <ul className="flex h-full items-center px-1.5 *:flex-center *:p-3">
          <li>
            <button
              type="button"
              onClick={() => {
                onGameClose?.();
                navigate({ to: "/casino" });
              }}
            >
              <Icon name="close" />
            </button>
          </li>

          {isAuth && (
            <>
              <li>
                <FavouriteBtn
                  isFavourite={isFavourite}
                  onClick={() => favouriteMutation.mutate({ gameId, isFavourite: !isFavourite })}
                />
              </li>

              <li>
                <MobileMenuButton />
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}
