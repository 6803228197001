import { useTranslate } from "@lobby/ocb-intl";
import type { WarningEventPayload } from "../../lib";
import { Dialog, type IDialogProps } from "../dialog";

interface IWarningModalProps extends IDialogProps {
  payload: WarningEventPayload;
}

export function WarningModal({ payload }: IWarningModalProps) {
  const { $t } = useTranslate();

  const title = $t({ defaultMessage: "please note" });

  return (
    <Dialog type="warning" title={title} icon="warningSign">
      <Dialog.Message>{payload.message}</Dialog.Message>
    </Dialog>
  );
}
