import { useTranslate } from "@lobby/ocb-intl";
import { useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import type { CSSProperties } from "react";
import { Skeleton, emitter } from "../../shared";
import { onImageLoadError } from "./helpers";

export function GameCardSkeleton() {
  return (
    <div className="h-full rounded-4">
      <Skeleton className="aspect-square rounded-b-none" />
      <Skeleton
        style={
          {
            background: "color-mix(in srgb, var(--skeleton-bg) 30%, transparent)",
          } as CSSProperties
        }
        className="flex h-12.5 flex-col justify-center gap-1 rounded-none px-2.5 *:w-full"
      >
        <Skeleton className="h-4" />
        <Skeleton className="h-4" />
      </Skeleton>
    </div>
  );
}

interface IFavouriteBtnProps {
  className?: string;
  isFavourite: boolean;
  onClick: VoidFunction;
}

export function FavouriteBtn({ className, isFavourite, onClick }: IFavouriteBtnProps) {
  return (
    <button
      className={clsx(
        "add-to-favourites-btn",
        isFavourite && "add-to-favourites-btn--checked",
        className,
      )}
      type="button"
      onClick={onClick}
    >
      <svg width="1em" height="1em" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.42 2.58a6.25 6.25 0 0 0 0 8.84l.883.883 8.84 8.84v-.001l8.838-8.839.884-.884a6.25 6.25 0 1 0-8.839-8.838l-.884.883-.884-.884a6.25 6.25 0 0 0-8.838 0Z"
          fill="currentColor"
          strokeWidth="2"
          strokeLinejoin="round"
          className="add-to-favourites-btn__icon"
        />
      </svg>
    </button>
  );
}

export function GameCardLinkLayer({
  gameId,
  showWalletIntegrationWarning,
}: { gameId: string; showWalletIntegrationWarning: boolean }) {
  const { $t } = useTranslate();
  const navigate = useNavigate();

  function navigateToGame() {
    navigate({ to: "/game/$gameId", params: { gameId } });
  }

  function showWarningModal() {
    emitter.emit("WALLET_INTEGRATION_WARNING_MODAL_OPENED", {
      onOk: navigateToGame,
    });
  }

  function handleClick() {
    if (showWalletIntegrationWarning) {
      showWarningModal();
    } else {
      navigateToGame();
    }
  }

  return (
    <button onClick={handleClick} type="button" className="game-card__link-layer">
      <div className="game-card__play-btn">{$t({ defaultMessage: "play" })}</div>
    </button>
  );
}

export function GameCardImage({ src, title }: { src: string; title: string }) {
  return (
    <div className="game-card__img-container">
      <picture>
        <source srcSet={src.replace(".jpg", ".webp")} type="image/webp" />
        <img
          className="object-contain"
          src={src}
          alt={title}
          loading="lazy"
          width="100%"
          height="100%"
          onError={onImageLoadError}
        />
      </picture>
    </div>
  );
}

export function GameCardDescription({
  provider,
  gameName,
}: { provider: string; gameName: string }) {
  return (
    <div dir="ltr" className="game-card__description">
      <div className="text-11 text-dove-gray">{provider}</div>
      <div className="truncate text-14" title={gameName}>
        {gameName}
      </div>
    </div>
  );
}
