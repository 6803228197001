import { memo } from "react";

import { useToggle } from "../../../../../shared";
import {
  AddCardHint,
  BackToLobbyLink,
  CardBack,
  CardFront,
  LogInWithBarcodeButton,
} from "./components";

export const MemberCard = memo(function MemberCard() {
  const [isCardExpanded, toggleCard] = useToggle(false);

  return (
    <div className="gutter-stable lg:overflow-y-scroll py-1 grow">
      <div
        className={`relative mx-auto flex w-full max-w-[25rem] select-none flex-col items-center transition-[aspect-ratio] ${isCardExpanded ? "[aspect-ratio:1/1.2]" : "[aspect-ratio:1.3/1]"}`}
      >
        <CardBack />
        <CardFront onClick={toggleCard} />
      </div>

      <div className="relative mt-10 flex flex-col items-center text-center">
        <LogInWithBarcodeButton onClick={toggleCard} />
        <BackToLobbyLink />
        <AddCardHint className="lg:hidden" />
      </div>
    </div>
  );
});
