import { useGSAP } from "@gsap/react";
import { type IWin, JackpotMap, type TJackpotsType } from "@lobby/core/src/entities";
import { APIError, Button, emitter as coreEmitter } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { emitter } from "app/config";
import GameBallImg from "assets/ball.png";
import minigameBackgroundImg from "assets/minigame-bg.avif";
import gsap from "gsap";
import { forwardRef, useEffect, useRef } from "react";
import { JackpotsTicker } from "widget/jackpots";
import { useMiniGameContext } from "../model/use-mini-game-context";
import { calcRectMidPoint } from "./helpers";

gsap.registerPlugin(useGSAP);

export function MiniGameGuard() {
  const { win } = useMiniGameContext();

  return win && <MiniGame />;
}

const MiniGame = () => {
  const ballRef = useRef<HTMLImageElement>(null);

  const ctx = useMiniGameContext();
  const { contextSafe } = useGSAP({ scope: ballRef });

  const win = ctx.win as IWin;

  // @ts-ignore
  const slotName = JackpotMap[win.slot] as TJackpotsType;

  const openCongratsModal = () => {
    if (!win) return;

    emitter.emit("OPEN_CONGRATS_MODAL", {
      type: "jackpot",
      winAmount: win.value,
      subtitle: slotName,
      onOk: () => {
        resetBallPosition();
        ctx.fetchWinAccept();
        ctx.updateReelStatus("spin-stop");
      },
    });
  };

  function resetBallPosition() {
    gsap.set(ballRef.current, { x: 0, y: 0, scale: 1, rotation: 0 });
  }

  const onEnter = contextSafe(() => {
    const hook = document.getElementById(`-js-hook-minigame-jp-ticker-${slotName}`);

    if (!hook || !ballRef.current) return;

    const startRect = calcRectMidPoint(ballRef.current.getBoundingClientRect());
    const finishRect = calcRectMidPoint(hook.getBoundingClientRect());

    ctx.updateReelStatus("spin-start");

    gsap.to(ballRef.current, {
      x: finishRect.x - startRect.x,
      y: finishRect.y - startRect.y,
      scale: "-= 0.3",
      duration: 0.8,
      ease: "linear",
      rotation: 270,
    });

    gsap.to(ballRef.current, {
      scale: 0.6,
      duration: 1,
      ease: "bounce.out",
      onComplete: openCongratsModal,
    });
  });

  useEffect(() => {
    if (ctx.error) {
      coreEmitter.emit("ERROR", { error: new APIError(ctx.error) });
    }
  }, [ctx.error]);

  return (
    <MinigameView ref={ballRef} isInProgress={ctx.reelStatus !== "spin-stop"} onClick={onEnter} />
  );
};

interface MinigameViewProps {
  isInProgress: boolean;
  onClick: () => void;
}

export const MinigameView = forwardRef<HTMLImageElement, MinigameViewProps>(
  ({ isInProgress, onClick }, ref) => {
    const { $t } = useTranslate();

    return (
      <div className="fixed inset-0 z-[900] flex-center overflow-auto bg-black lg:bg-opacity-80">
        <div className="relative mobile-only:h-full mobile-only:max-h-[150vmin] mobile-only:w-[150vmin] w-[62.5rem]">
          <div className="h-full">
            <img
              src={minigameBackgroundImg}
              alt="minigame background"
              width="100%"
              height="100%"
              className="mobile-only:size-full object-cover"
            />
          </div>

          <div className="absolute inset-0 flex flex-col items-center">
            <div
              dir="ltr"
              className="absolute bottom-[26.7%] h-[35.4%] mobile-only:w-[90%] w-[36%] *:absolute"
            >
              <div
                id="-js-hook-minigame-jp-ticker-mini"
                className="lg:-translate-x-1/2 top-[15%] min-w-32 mobile-only:text-[3.75vmin] lg:min-w-56"
              >
                <JackpotsTicker type="mini" id={0} />
              </div>
              <div
                id="-js-hook-minigame-jp-ticker-major"
                className="lg:-translate-x-1/2 bottom-[21%] min-w-32 mobile-only:text-[3.75vmin] lg:bottom-[10%] lg:min-w-56"
              >
                <JackpotsTicker type="major" id={2} />
              </div>
              <div
                id="-js-hook-minigame-jp-ticker-grand"
                className="top-[15%] right-0 min-w-32 mobile-only:text-[3.75vmin] lg:min-w-56 lg:translate-x-1/2"
              >
                <JackpotsTicker type="grand" id={1} />
              </div>
              <div
                id="-js-hook-minigame-jp-ticker-ultimate"
                className="right-0 bottom-[21%] min-w-32 mobile-only:text-[3.75vmin] lg:bottom-[10%] lg:min-w-56 lg:translate-x-1/2"
              >
                <JackpotsTicker type="ultimate" id={3} />
              </div>
            </div>

            <Button
              variant="success"
              disabled={isInProgress}
              onClick={onClick}
              className="absolute bottom-2 lg:bottom-[20%]"
            >
              {$t({ defaultMessage: "Strike!" })}
            </Button>

            <img
              ref={ref}
              src={GameBallImg}
              alt="game-ball"
              loading="lazy"
              style={{ perspective: "1000px" }}
              className="absolute bottom-[11%] size-[15vmin] lg:size-12"
            />
          </div>
        </div>
      </div>
    );
  },
);
