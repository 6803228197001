import {
  type CSSProperties,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { remToPx, throttle } from "../../lib";

function getScrollerItem(scrollerComponent: HTMLElement) {
  return scrollerComponent.children[0].children[0];
}

export function VirtualScroller<T>({
  items,
  itemsGap = "0rem",
  itemWidth,
  itemHeight,
  scrollerComponentRef,
  renderItem,
}: {
  items: T[];
  itemsGap?: string;
  itemWidth: string;
  itemHeight: string;
  scrollerComponentRef: RefObject<HTMLDivElement>;
  renderItem: (item: T, index: number) => JSX.Element;
}) {
  const [firstVisibleItemIndex, setFirstVisibleItemIndex] = useState(0);
  const [lastVisibleItemIndex, setLastVisibleItemIndex] = useState(0);

  const visibleItemsCountRef = useRef<number>();

  const dispatchScrollEvent = useCallback(
    throttle(() => scrollerComponentRef.current?.dispatchEvent(new Event("scroll")), 200, {
      leading: false,
    }),
    [],
  );

  useEffect(() => {
    if (!scrollerComponentRef.current) return;

    function handleStepScroll(e: Event, scrollMultiplier: number) {
      if (!e.currentTarget) return;

      const scrollContainer = e.currentTarget as HTMLElement;
      const scrollerItem = getScrollerItem(scrollContainer);
      scrollContainer.scrollBy({
        left: scrollerItem.clientWidth * scrollMultiplier * (document.dir === "rtl" ? -1 : 1),
        behavior: "smooth",
      });
    }

    const controller = new AbortController();

    scrollerComponentRef.current.addEventListener(
      "scroll",
      (e: Event) => {
        e.stopPropagation();

        const { scrollLeft, clientWidth } = e.currentTarget as HTMLDivElement;
        visibleItemsCountRef.current = Math.ceil(clientWidth / remToPx(itemWidth)) + 1;

        /* For RTL mode scrollLeft is negative, so we need to get the absolute value of it */
        const start = Math.max(
          Math.floor(Math.abs(scrollLeft) / (remToPx(itemWidth) + remToPx(itemsGap))),
          0,
        );
        const end = Math.min(start + visibleItemsCountRef.current, items.length);

        setFirstVisibleItemIndex(start);
        setLastVisibleItemIndex(end);
      },
      { signal: controller.signal },
    );

    scrollerComponentRef.current.addEventListener(
      ":scroll-prev",
      (e: Event) => handleStepScroll(e, -1),
      {
        signal: controller.signal,
      },
    );

    scrollerComponentRef.current.addEventListener(
      ":scroll-next",
      (e: Event) => handleStepScroll(e, 1),
      {
        signal: controller.signal,
      },
    );

    /* Trigger scroll event for initial render */
    scrollerComponentRef.current?.dispatchEvent(new Event("scroll"));

    window.addEventListener("resize", () => dispatchScrollEvent(), { signal: controller.signal });

    return () => controller.abort();
  }, [items.length]);

  return (
    <div
      ref={scrollerComponentRef}
      style={
        {
          "--virtual-scroller-items-count": items.length,
          "--virtual-scroller-items-gap": itemsGap,
          "--virtual-scroller-item-width": itemWidth,
          "--virtual-scroller-item-height": itemHeight,
        } as CSSProperties
      }
      className="virtual-scroller"
    >
      <div
        style={
          {
            "--virtual-scroller-first-visible-item-index": firstVisibleItemIndex,
          } as CSSProperties
        }
        className="virtual-scroller__content"
      >
        {items.slice(firstVisibleItemIndex, lastVisibleItemIndex).map(renderItem)}
      </div>
      <div className="virtual-scroller__spacer" />
    </div>
  );
}
