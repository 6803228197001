import { useTranslate } from "@lobby/ocb-intl";
import * as Sentry from "@sentry/react";
import { type ServerErrorEventPayload, useErrorTranslate } from "../../lib";
import { Dialog } from "../dialog";
import type { IDialogProps } from "../dialog";
import { getGMTTime, getLocalTime } from "./helpers";

interface IErrorModalProps extends IDialogProps {
  payload: ServerErrorEventPayload;
}

export function ErrorModal({ payload: { error } }: IErrorModalProps) {
  const { $t } = useTranslate();
  const { formatMessage } = useErrorTranslate();

  const localTime = getLocalTime();
  const GMTTime = getGMTTime();

  const title = $t({ defaultMessage: "error" });

  const details = error?.details ?? {};

  const filteredDetails = Object.entries(details).filter(
    ([key]) => key !== "playerId" && key !== "hallId",
  );

  return (
    <Sentry.ErrorBoundary>
      <Dialog type="error" title={title} icon="errorSign">
        <Dialog.Message>{formatMessage(error)}</Dialog.Message>
        <Dialog.Description>
          {filteredDetails.length > 0 && (
            <ul>
              {filteredDetails.map(([key, value]) => {
                if (!value) return null;

                return (
                  <li key={key} className="first-letter:capitalize">
                    {key}: {String(value)}
                  </li>
                );
              })}
            </ul>
          )}

          <div className="text-[#616161] text-12 mt-3 flex gap-5">
            <div className="whitespace-nowrap">
              <div>
                <span>P:</span> {details.playerId ?? "N/A"}
              </div>
              <div>
                <span>H:</span> {details.hallId ?? "N/A"}
              </div>
            </div>
            <div className="grid grid-cols-[auto,auto] gap-x-1">
              <span>Local time:</span>
              <span>{localTime}</span>
              <span>UTC time:</span>
              <span>{GMTTime}</span>
            </div>
          </div>
        </Dialog.Description>
      </Dialog>
    </Sentry.ErrorBoundary>
  );
}
