import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { Player, usePlayerMoneyFormatter } from "../../../entities";

export function ProfileBalanceDetails({ className }: { className?: string }) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  const { data } = Player.useBalances();

  return (
    <div className={clsx("profile-area", className)}>
      <div className="flex-c-sb gap-2 font-medium">
        <span
          style={{
            color: "var(--text-secondary-color)",
          }}
          className="whitespace-nowrap uppercase"
        >
          {$t({ defaultMessage: "wager" })}
        </span>
        <span>{formatMoney(data?.wager?.remaining ?? 0)}</span>
      </div>
    </div>
  );
}
