import { translateDynamicMessage, useIntlT, useTranslate } from "@lobby/ocb-intl";
import { useMemo } from "react";
import { Payment, usePlayerMoneyFormatter } from "../../../../../entities";
import {
  Icon,
  type TTransactionHistory,
  useInfiniteScroll,
  useMobile,
} from "../../../../../shared";
import { getMobileSectionScrollContainer } from "../../../helpers";
import { NextPageLoader, NoDataPlaceholder, ProfileSection } from "../../components";

type TransactionType = "deposit" | "withdraw";

export function TransactionTable({ type }: { type: TransactionType }) {
  const { $t } = useTranslate();

  const { data, isLoading } = Payment.useTransactionHistory(type);

  const normalizedData = useMemo(
    () => data?.pages.flatMap((page) => page.result?.data).filter(Boolean) ?? [],
    [data],
  );

  return (
    <ProfileSection
      data={normalizedData}
      isLoading={isLoading}
      placeholder={
        <NoDataPlaceholder
          topText={$t({ defaultMessage: "You have no transactions" })}
          icon={<Icon name="creditCard" className="text-[6.25rem]" />}
        />
      }
    >
      {(validatedData) => <TransactionTableView data={validatedData} type={type} />}
    </ProfileSection>
  );
}

function TransactionTableView({
  data,
  type,
}: { data: TTransactionHistory; type: TransactionType }) {
  const isMobile = useMobile();
  const { $t } = useTranslate();
  const { intl } = useIntlT();
  const formatMoney = usePlayerMoneyFormatter();

  const { fetchNextPage, isFetchingNextPage } = Payment.useTransactionHistory(type);

  const scrollContainer = isMobile ? getMobileSectionScrollContainer() : null;

  const scrollRef = useInfiniteScroll(fetchNextPage, scrollContainer);

  return (
    <div className="gutter-stable relative flex min-h-0 flex-col mobile-only:pb-5 lg:h-full lg:max-h-full lg:overflow-y-auto">
      <div ref={scrollRef} className="gutter-stable overflow-y-auto">
        <table className="bonuses-table">
          <thead>
            <tr>
              <th>{$t({ defaultMessage: "Date" })}</th>
              <th>{$t({ defaultMessage: "Payment type" })}</th>
              <th>{$t({ defaultMessage: "Sum" })}</th>
              <th>{$t({ defaultMessage: "Status" })}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>
                  {new Date(item.createdAt).toLocaleString(intl.locale, {
                    timeStyle: "short",
                    dateStyle: "short",
                  })}
                </td>
                <td>{item.type}</td>
                <td>
                  {formatMoney(item.amount ?? 0, {
                    style: "decimal",
                  })}
                </td>
                <td className="first-letter:uppercase">
                  {translateDynamicMessage($t, "transaction_status", item.status)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isFetchingNextPage && <NextPageLoader />}
      </div>
    </div>
  );
}
