import { useModal } from "@lobby/core/src/shared";
import { clsx } from "clsx";

export function MobileMenuButton({ className }: { className?: string }) {
  const { open: openProfile } = useModal("profile");

  return (
    <button
      onClick={openProfile}
      type="button"
      className={clsx(className, "space-y-[0.3125rem] *:h-1 *:rounded-full *:bg-white")}
    >
      <div className="w-[1.125rem]" />
      <div className="w-[1.5625rem]" />
      <div className="w-[0.8125rem]" />
    </button>
  );
}
