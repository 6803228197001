export function throttle<T, U>(
  fn: (...args: T[]) => U,
  ms: number,
  opts: { leading?: boolean; trailing?: boolean } = {},
) {
  let wait = false;
  let result: U;

  const leading = opts?.leading ?? true;
  const trailing = opts?.trailing ?? true;

  return (...input: T[]) => {
    if (!wait) {
      if (leading && !result) {
        result = fn.apply(null, input);
      }

      wait = true;
      setTimeout(() => {
        wait = false;
        if (trailing) {
          result = fn.apply(null, input);
        }
      }, ms);
    }

    return result;
  };
}
