import { NumberUtilities } from "./number-utilities";

export function useLobbyFormatters() {
  function formatMoney(value: unknown, options: Intl.NumberFormatOptions = {}) {
    if (value === null || value === undefined) {
      return "—";
    }

    if (!NumberUtilities.isFinite(value)) {
      return "NaN";
    }

    const computedOptions = options.currency
      ? { style: "currency", currencyDisplay: "code", ...options }
      : options;

    // This is a hack
    let locale = "ru-RU";
    if (document.documentElement.lang === "ar") {
      locale = "ar";
    }

    const formatter = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      trailingZeroDisplay: "stripIfInteger",
      ...computedOptions,
    } as Intl.NumberFormatOptions);

    return formatter
      .formatToParts(value / 100)
      .map((part) => (part.type === "decimal" ? "." : part.value))
      .join("");
  }

  return { formatMoney };
}
