import { clsx } from "clsx";
import { useEffect, useState } from "react";
import { Button, Icon } from "../../shared";

export function GameListPagination({
  className,
  componentRef,
}: {
  className?: string;
  componentRef: HTMLDivElement | null;
}) {
  const [scrollPosition, setScrollPosition] = useState<"start" | "end" | "progress">("start");

  function onClickPrev() {
    componentRef?.dispatchEvent(new Event(":scroll-prev"));
  }

  function onClickNext() {
    componentRef?.dispatchEvent(new Event(":scroll-next"));
  }

  useEffect(() => {
    if (!componentRef) return;

    function scrollListener(e: Event) {
      const { scrollWidth, scrollLeft, clientWidth } = e.currentTarget as HTMLDivElement;
      if (scrollLeft === 0) {
        setScrollPosition("start");
      } else if (Math.abs(scrollWidth - Math.abs(scrollLeft) - clientWidth) < 1) {
        setScrollPosition("end");
      } else {
        setScrollPosition("progress");
      }
    }

    componentRef.addEventListener("scroll", scrollListener);

    return () => componentRef.removeEventListener("scroll", scrollListener);
  }, [componentRef]);

  return (
    <div className={clsx(className, "inline-flex gap-[0.3125rem]")}>
      <Arrow direction="left" onClick={onClickPrev} disabled={scrollPosition === "start"} />
      <Arrow onClick={onClickNext} disabled={scrollPosition === "end"} />
    </div>
  );
}

interface IArrowProps {
  direction?: "left" | "right";
  disabled?: boolean;
  onClick: VoidFunction;
}

function Arrow({ direction, disabled, onClick }: IArrowProps) {
  return (
    <Button
      variant="secondary"
      disabled={disabled}
      onClick={onClick}
      fit
      className={`${direction === "left" ? "" : "rotate-180"}`}
    >
      <Icon className="text-10 rtl:-scale-100" name="arrowLeft" />
    </Button>
  );
}
