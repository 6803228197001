import { useJackpotsContext } from "@lobby/core/src/entities";
import { useAuth } from "@lobby/core/src/shared";
import { clsx } from "clsx";
import { useEffect, useRef, useState } from "react";
import { Skeleton } from "shared/ui/skeleton";
import { JackpotsTicker } from "./jackpots-ticker";

export function JackpotsGuard() {
  const { isAuth } = useAuth();

  return isAuth && <Jackpots />;
}

const getTopOffset = (element: Element) => (element as HTMLElement).offsetTop;

function Jackpots() {
  const jackpotsRef = useRef<HTMLDivElement>(null);
  const [isWrapped, setIsWrapped] = useState(false);

  const { slots } = useJackpotsContext();

  const isSlotsExist = slots && Array.isArray(slots) && slots.length > 0;

  const miniValue = slots?.find(({ name }) => name === "mini")?.value;
  const majorValue = slots?.find(({ name }) => name === "major")?.value;
  const grandValue = slots?.find(({ name }) => name === "grand")?.value;
  const ultimateValue = slots?.find(({ name }) => name === "ultimate")?.value;

  useEffect(() => {
    if (!jackpotsRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const parent = entry.target as HTMLDivElement;
        const children = parent.children;

        if (children.length < 2) return;

        setIsWrapped(getTopOffset(children[1]) > getTopOffset(children[0]));
      }
    });

    observer.observe(jackpotsRef.current);

    return () => observer.disconnect();
  }, [isSlotsExist]);

  return isSlotsExist ? (
    <div ref={jackpotsRef} className={clsx("jackpots", isWrapped && "jackpots_wrapped")}>
      <div className="jackpots__tickers-group">
        <JackpotsTicker type="mini" value={miniValue} id={0} />
        <JackpotsTicker type="major" value={majorValue} id={1} />
      </div>
      <div className="jackpots__tickers-group">
        <JackpotsTicker type="grand" value={grandValue} id={2} />
        <JackpotsTicker type="ultimate" value={ultimateValue} id={3} />
      </div>
    </div>
  ) : (
    <Skeleton className="h-[7.625rem] mobile-only:h-[3.5rem] lg:h-16" />
  );
}
