import type { Output } from "@lobby/api-client/src/codegen";
import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { type ReactNode, memo, useEffect, useRef, useState } from "react";
import { Button, VirtualScroller, isEmptyArray, useNavigateToCategory } from "../../shared";
import { GameCard } from "../GameCard";
import { GameListPagination } from "./GameListPagination";
import { GameListEmpty, GameListHeader } from "./components";

interface IScrollableGameListProps {
  categoryId: string | number;
  CategoryIcon: ReactNode;
  label: string;
  items: Output<"Game.getList">["data"];
}

export const ScrollableGameList = memo(function ScrollableGameList({
  items,
  categoryId,
  CategoryIcon,
  label,
}: IScrollableGameListProps) {
  const { $t } = useTranslate();
  const navigateToCategory = useNavigateToCategory();
  const [isContentOverflow, setIsContentOverflow] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollComponentRef = useRef<HTMLDivElement>(null);
  const itemsCountRef = useRef(0);

  itemsCountRef.current = items.length ?? 0;

  useEffect(() => {
    const checkForContentOverflow = () => {
      const scrollContainer = scrollContainerRef.current;
      const scrollComponent = scrollComponentRef.current;

      if (scrollContainer && scrollComponent) {
        const isContentOverflow = scrollComponent.scrollWidth > scrollContainer.scrollWidth;
        setIsContentOverflow(isContentOverflow);
      }
    };

    checkForContentOverflow();
    window.addEventListener("resize", checkForContentOverflow);
    return () => window.removeEventListener("resize", checkForContentOverflow);
  }, [items]);

  return (
    <section>
      <GameListHeader CategoryIcon={CategoryIcon} label={label}>
        <div
          className={clsx("flex items-center gap-2.5", isContentOverflow ? "visible" : "invisible")}
        >
          <GameListPagination
            className="mobile-only:hidden"
            componentRef={scrollComponentRef.current}
          />
          <Button variant="secondary" onClick={() => navigateToCategory(categoryId)}>
            {$t({ defaultMessage: "Show all" })} ({itemsCountRef.current})
          </Button>
        </div>
      </GameListHeader>

      <div className="mt-5" ref={scrollContainerRef}>
        {!isEmptyArray(items) ? (
          <VirtualScroller
            items={items}
            itemsGap="0.625rem"
            itemHeight="12.125rem"
            itemWidth="9rem"
            scrollerComponentRef={scrollComponentRef}
            renderItem={({
              id,
              pic,
              providerName,
              name,
              stringId,
              isFavourite,
              isWalletIntegration,
            }) => (
              <GameCard
                key={id}
                id={id}
                title={name}
                imgSrc={pic}
                provider={providerName}
                gameStringId={stringId}
                isFavourite={isFavourite}
                showWalletIntegrationWarning={isWalletIntegration}
              />
            )}
          />
        ) : (
          <GameListEmpty />
        )}
      </div>
    </section>
  );
});
