import { clsx } from "clsx";
import type { PropsWithChildren } from "react";

interface IFloatGroupProps {
  className?: string;
}

export function FloatGroup({ className, children }: PropsWithChildren<IFloatGroupProps>) {
  return (
    <div
      className={clsx(
        "fixed flex flex-col items-end lg:ltr:right-10 lg:rtl:left-10 lg:bottom-10 bottom-5 lg:space-y-10 space-y-5 mobile-only:px-2.5 mobile-only:w-full z-[800]",
        className,
      )}
    >
      {children}
    </div>
  );
}
