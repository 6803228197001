import { clsx } from "clsx";
import { useContext } from "react";
import { Player, PlayerSettingsContext, usePlayerMoneyFormatter } from "../../entities";

export function PlayerBalance({ className }: { className?: string }) {
  const playerSettings = useContext(PlayerSettingsContext);
  const formatMoney = usePlayerMoneyFormatter();

  const { data } = Player.useBalances();

  const balance = data?.balance?.balance ?? 0;

  return (
    <div className={clsx("truncate", className)}>
      {playerSettings.isBalanceVisible ? formatMoney(balance ?? 0) : "********"}
    </div>
  );
}
