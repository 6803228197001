import { type ApiClientResponse, apiClient } from "@lobby/api-client";
import { redirect } from "@tanstack/react-router";

import { queryClient } from "../config/query-client";
import { loadGameCategoryList } from "./loaders";

export async function ensureUserIsAuthenticated() {
  const data = await queryClient.ensureQueryData<ApiClientResponse<"Player.getCurrent">>({
    queryKey: ["Player.getCurrent"],
    queryFn: () =>
      apiClient.send({
        endpoint: "Player.getCurrent",
        params: { isActive: "true" },
      }),
  });

  return Boolean(data.result);
}

export function isLobbyDemoMode() {
  return import.meta.env.VITE_IS_DEMO === "true";
}

export async function redirectIfUserIsAuthenticated(
  to: string,
  opts?: Parameters<typeof redirect>,
) {
  const isAuthenticated = await ensureUserIsAuthenticated();
  if (isAuthenticated) {
    throw redirect({
      to,
      ...opts,
    });
  }
}

export async function redirectIfAuthenticatedUserRequired(opts?: Parameters<typeof redirect>[0]) {
  const isAuthenticated = await ensureUserIsAuthenticated();
  if (!isAuthenticated) {
    throw redirect({
      to: "/sign-in",
      replace: true,
      ...opts,
    });
  }
}

export async function redirectIfFortuneWheelIsNotAvailable(opts?: Parameters<typeof redirect>[0]) {
  const data = await queryClient.ensureQueryData<ApiClientResponse<"Lobby.getCurrent">>({
    queryKey: ["Lobby.getCurrent"],
    queryFn: () =>
      apiClient.send({
        endpoint: "Lobby.getCurrent",
      }),
  });

  const isFortuneWheelAvailable = Boolean(data.result?.fortuneWheel?.isActive);
  if (!isFortuneWheelAvailable) {
    throw redirect({
      to: "/",
      replace: true,
      ...opts,
    });
  }
}

export async function redirectToIndexCategory() {
  const categoryListResponse = await loadGameCategoryList();

  const categories = categoryListResponse.result?.data ?? [];
  const indexCategory = categories.find(({ tag }) => tag === "header");

  if (indexCategory) {
    switch (indexCategory.actionType) {
      case "game":
        throw redirect({
          to: "/game/$gameId",
          params: { gameId: indexCategory.actionData as string },
        });
      case "category":
        throw redirect({
          to: "/casino",
          search: { cid: indexCategory.id },
        });
      default:
        throw redirect({
          to: "/casino",
        });
    }
  } else {
    throw redirect({
      to: "/casino",
    });
  }
}
