import { Button, Icon } from "@lobby/core/src/shared";
import { useNavigate } from "@tanstack/react-router";

export function ResetProviderFilterButton() {
  const navigate = useNavigate();

  return (
    <Button
      fit
      variant="secondary"
      onClick={() => {
        navigate({
          replace: true,
          // @ts-ignore
          search: (prev) => ({ ...prev, pid: undefined }),
        });
      }}
    >
      <Icon name="close" />
    </Button>
  );
}
