import { useTranslate } from "@lobby/ocb-intl";
import { Lobby, Player } from "../../entities";
import { Button } from "../../shared";

export function LogoutButton({ className }: { className?: string }) {
  const { $t } = useTranslate();

  const logoutMutation = Player.useLogOut();
  const lobby = Lobby.useLobby();
  const isLogoutButtonEnabled = lobby?.data?.buttons?.logout;

  return (
    isLogoutButtonEnabled && (
      <div className={className}>
        <Button variant="secondary" onClick={() => logoutMutation.mutate()} className="w-full">
          {$t({ defaultMessage: "Sign out" })}
        </Button>
      </div>
    )
  );
}
