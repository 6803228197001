import { clsx } from "clsx";
import type { PropsWithChildren, ReactElement } from "react";
import { Icon, type IconName } from "../icons";

type CategoryButtonProps = {
  isActive: boolean;
  disabled?: boolean;
  iconName?: IconName;
  iconSlot?: ReactElement;
  onClick?: VoidFunction;
};

export function CategoryButton({
  children,
  isActive,
  disabled,
  iconName,
  iconSlot,
  onClick,
}: PropsWithChildren<CategoryButtonProps>) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={clsx("category-btn", isActive && "category-btn-selected")}
      type="button"
    >
      {iconSlot || <Icon className="text-20" name={iconName as IconName} />}
      <div className="truncate">{children}</div>
    </button>
  );
}
