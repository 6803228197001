import { useTranslate } from "@lobby/ocb-intl";
import { useLobbyLoader, useLobbySpinner } from "../../../shared";

export function Spinner({ className }: { className?: string }) {
  const { $t } = useTranslate();
  const Loader = useLobbyLoader();
  const Spinner = useLobbySpinner();

  if (Spinner) {
    return <Spinner className={className} />;
  }

  return (
    <div className={className}>
      <Loader className="animate-spin size-24 text-24" />
      <div className="mt-1 font-medium text-center">{$t({ defaultMessage: "Loading..." })}</div>
    </div>
  );
}
