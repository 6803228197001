import { apiClient } from "@lobby/api-client";
import type { ApiClientResponse, SendOptions } from "@lobby/api-client";
import type { Output } from "@lobby/api-client/src/codegen";
import { useIntlT } from "@lobby/ocb-intl";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isLobbyDemoMode } from "../../app";
import { queryClient } from "../../app/config";
import { useAuth } from "../../shared/lib";

export type TGames = Output<"Game.getList">["data"];
export type TGame = TGames[number];

type TGameRunParams = Omit<NonNullable<SendOptions<"Game.run">["params"]>, "isDemo">;

export const Game = {
  useNoJackpotGames() {
    return useQuery({
      queryKey: ["Game.getNoJackpotGames"],
      queryFn: () => apiClient.send({ endpoint: "Game.getNoJackpotList" }),
      select: (data) => data.result,
    });
  },

  useHeaderCategories() {
    const { data: categoriesList } = this.useCategoriesList();
    return categoriesList?.filter(({ tag }) => tag === "header") || [];
  },

  useNoWagerGames() {
    return useQuery({
      queryKey: ["Game.getNoWagerGames"],
      queryFn: () => apiClient.send({ endpoint: "Game.getNoWagerList" }),
      select: (data) => data.result,
    });
  },

  useGameRun(params: TGameRunParams) {
    const { isAuth } = useAuth();

    return useQuery({
      queryKey: ["Game.run", params, isAuth],
      queryFn: () =>
        apiClient.send({
          endpoint: "Game.run",
          params: {
            ...params,
            isDemo: !isAuth && isLobbyDemoMode(),
          },
        }),
    });
  },

  useCategoriesList() {
    const {
      intl: { locale, defaultLocale },
    } = useIntlT();

    const lang = locale || defaultLocale;

    return useQuery({
      queryKey: ["Game.getCategoriesList", lang],
      queryFn: () =>
        apiClient.send({
          endpoint: "Game.getCategoriesList",
          params: {
            lang,
          },
        }),
      select: (data) => data?.result?.data ?? [],
    });
  },

  useList() {
    return useQuery({
      queryKey: ["Game.getList"],
      queryFn: () => apiClient.send({ endpoint: "Game.getList" }),
      select: (data) => data.result?.data ?? [],
    });
  },

  useProvidersList() {
    return useQuery({
      queryKey: ["Game.getProvidersList"],
      queryFn: async () => apiClient.send({ endpoint: "Game.getProvidersList" }),
    });
  },

  useRecommendedGames() {
    const { isAuth } = useAuth();

    const result = useQuery({
      enabled: isAuth,
      queryKey: ["Game.getRecommendations"],
      queryFn: () => apiClient.send({ endpoint: "Game.getRecommendations" }),
      select: (data) => data.result,
    });

    return result;
  },

  useRedeemProviderBalance() {
    return useMutation({
      mutationKey: ["Game.redeemProviderBalance"],
      mutationFn: (params: SendOptions<"Game.redeemProviderBalance">["params"]) =>
        apiClient.send({ endpoint: "Game.redeemProviderBalance", params }),
    });
  },

  useFavouriteSetter() {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (params: SendOptions<"Game.updateFavouriteStatus">["params"]) =>
        apiClient.send({ endpoint: "Game.updateFavouriteStatus", params }),
      onSuccess: (data, variables) => {
        if (data.result === "success") {
          queryClient.setQueryData<ApiClientResponse<"Game.getList">>(
            ["Game.getList"],
            (oldData) => {
              const oldGameList = oldData?.result.data;

              if (!oldGameList) return oldData;

              const gameIndex = oldGameList.findIndex((game) => game.id === variables?.gameId);
              if (gameIndex !== -1) {
                const newGameList = [...oldGameList];
                newGameList[gameIndex] = {
                  ...oldGameList[gameIndex],
                  isFavourite: !oldGameList[gameIndex].isFavourite,
                };

                return {
                  ...oldData,
                  result: {
                    ...oldData.result,
                    data: newGameList,
                  },
                };
              } else {
                return oldData;
              }
            },
          );
        }
      },
    });
  },

  getGameByStringId(gameStringId: string) {
    const gameList = queryClient.getQueryData([
      "Game.getList",
    ]) as ApiClientResponse<"Game.getList">;
    return gameList?.result?.data?.find((game) => game.stringId === gameStringId);
  },

  getCategoryNameById(id: string | number, lang: string) {
    const categories = queryClient.getQueryData([
      "Game.getCategoriesList",
      lang,
    ]) as ApiClientResponse<"Game.getCategoriesList">;

    return categories?.result?.data?.find((category) => category.id === Number(id))?.name;
  },

  getProviderNameById(id: string | number) {
    const providers = queryClient.getQueryData([
      "Game.getProvidersList",
    ]) as ApiClientResponse<"Game.getProvidersList">;

    return providers?.result?.data?.find((provider) => provider.id === Number(id))?.name;
  },
};
