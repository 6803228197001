import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { memo, useRef } from "react";
import { LanguageSelector, Modal, ThemeSwitch, useLobbyLogo } from "../../../shared";
import { LogoutButton } from "../../LogoutButton";
import {
  ProfileBalanceDetails,
  ProfilePlayerBalanceView,
  ProfileSectionControls,
  useProfileSections,
} from "../common";
import type { TProfileSectionItem } from "../common";
import { ProfileSectionMobile } from "./profile-section.mobile";

interface IProfileComponentMobileProps {
  currentSection: TProfileSectionItem | undefined;
  showLoadingOverlay: boolean;
  close: VoidFunction;
}

export const ProfileComponentMobile = memo(function ProfileComponentMobile({
  currentSection,
  showLoadingOverlay,
  close,
}: IProfileComponentMobileProps) {
  const { $t } = useTranslate();
  const sections = useProfileSections();
  const Logo = useLobbyLogo();

  const transitionContainerRef = useRef<HTMLDivElement>(null);
  const currentSectionRef = useRef(currentSection);

  const isHomeSection = !currentSection;
  currentSectionRef.current = isHomeSection ? currentSectionRef.current : currentSection;

  return (
    <Modal.Panel className="user-profile size-full">
      <Modal.Header isClosable={false} className="items-center">
        <Modal.CloseButton onClick={close} className="me-[0.5625rem]" />
        <Logo />
      </Modal.Header>
      <div
        ref={transitionContainerRef}
        className={clsx("user-profile_body", !isHomeSection && "user-profile_body--shifted")}
      >
        <div
          className="overflow-y-auto overflow-x-hidden transition-opacity duration-200"
          style={{
            opacity: isHomeSection ? 1 : 0,
          }}
        >
          <ProfilePlayerBalanceView />
          <ProfileBalanceDetails className="mt-2" />
          <div className="mt-2">
            <ProfileSectionControls
              currentSectionId={currentSectionRef.current?.id}
              items={sections}
            />
          </div>
          <div className="flex-c-sb py-5.5">
            <div className="flex-center gap-1">
              <span
                style={{
                  color: "var(--text-secondary-color)",
                }}
                className="text-14 font-bold uppercase"
              >
                {$t({ defaultMessage: "language" })}
              </span>
              <LanguageSelector />
            </div>
            {import.meta.env.VITE_THEME_CHANGE_ENABLED === "true" && (
              <div className="flex-center gap-3">
                <span
                  style={{
                    color: "var(--text-secondary-color)",
                  }}
                  className="text-14 font-bold uppercase"
                >
                  {$t({ defaultMessage: "color theme" })}
                </span>
                <ThemeSwitch />
              </div>
            )}
          </div>
          <div className="mt-3">
            <LogoutButton className="mx-auto w-fit" />
          </div>
        </div>

        <ProfileSectionMobile
          currentSection={currentSectionRef.current}
          isActive={!isHomeSection}
        />
      </div>

      <Modal.PendingOverlay isActive={showLoadingOverlay} />
    </Modal.Panel>
  );
});
