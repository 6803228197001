import { createContext, useEffect, useState } from "react";
import type { ComponentType, HTMLAttributes, PropsWithChildren } from "react";
import { emitter } from "../../shared/lib";

interface LobbySettingsContextType {
  theme: "light" | "dark";
  Logo: ComponentType<HTMLAttributes<HTMLDivElement>>;
  Loader: ComponentType<{ className?: string }>;
  Spinner?: ComponentType<HTMLAttributes<HTMLDivElement>>;
}

const initialState = {
  theme: (localStorage.getItem("theme") as "dark" | "light") ?? "dark",
  Logo: () => null,
  Loader: () => null,
  Spinner: undefined,
};

export const LobbySettingsContext = createContext<LobbySettingsContextType>(initialState);

export function LobbySettingsContextProvider({
  children,
  ...contextArgs
}: PropsWithChildren<Partial<LobbySettingsContextType>>) {
  const [state, setState] = useState<LobbySettingsContextType>({ ...initialState, ...contextArgs });

  useEffect(() => {
    const unbind = emitter.on("THEME_CHANGED", (theme) => {
      setState((prev) => ({ ...prev, theme }));
      localStorage.setItem("theme", theme);
      document.documentElement.className = theme;
    });

    return () => unbind();
  }, []);

  return <LobbySettingsContext.Provider value={state}>{children}</LobbySettingsContext.Provider>;
}
