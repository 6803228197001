import { type ReactNode, memo } from "react";
import { isEmptyArray } from "../../shared";
import { GameCard } from "../GameCard";
import { GameListEmpty, GameListHeader } from "./components";

interface IExpandedGameListProps {
  CategoryIcon: ReactNode;
  label: string;
  items: {
    id: number;
    stringId: string;
    name: string;
    pic: string;
    providerName: string;
    isFavourite: boolean;
    isWalletIntegration: boolean;
  }[];
}

export const ExpandedGameList = memo(function ExpandedGameList({
  items,
  label,
  CategoryIcon,
}: IExpandedGameListProps) {
  return (
    <section>
      <GameListHeader CategoryIcon={CategoryIcon} label={label} />

      <div className="mt-5">
        {!isEmptyArray(items) ? (
          <div className="grid gap-2.5 grid-cols-[repeat(auto-fill,minmax(9rem,1fr))]">
            {items.map(
              ({ id, pic, providerName, name, stringId, isFavourite, isWalletIntegration }) => (
                <GameCard
                  key={id}
                  imgSrc={pic}
                  title={name}
                  provider={providerName}
                  id={id}
                  gameStringId={stringId}
                  isFavourite={isFavourite}
                  showWalletIntegrationWarning={isWalletIntegration}
                />
              ),
            )}
          </div>
        ) : (
          <GameListEmpty />
        )}
      </div>
    </section>
  );
});
