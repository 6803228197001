import type { ApiClientResponse } from "@lobby/api-client";
import { useLocale, useTranslate } from "@lobby/ocb-intl";
import { memo, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import type { HTMLAttributes } from "react";
import { queryClient } from "../../app";
import { Chat, Player } from "../../entities";
import { Icon, Spinner, last } from "../../shared";

interface IConversationWindowProps {
  conversationId: number | null;
  pendingMessage: string | null;
  isChatWindowExpanded: boolean;
  onError: (error: { message: string; code: number }) => void;
  onMessagesLoading: (isMessagesLoaded: boolean) => void;
}

export const ConversationWindow = memo(function ConversationWindow({
  conversationId,
  pendingMessage,
  isChatWindowExpanded,
  onError,
  onMessagesLoading,
}: IConversationWindowProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isScrollAtBottom = useRef(false);
  const lastScrollAreaHeight = useRef(0);

  const player = Player.usePlayer();
  const conversations = Chat.useConversationList();
  const {
    data: messagesData,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isMessagesLoading,
  } = Chat.useConversationHistory(conversationId);
  const locale = useLocale();

  const messages = useMemo(
    () => messagesData?.pages.flatMap((page) => page.result).reverse() ?? [],
    [messagesData],
  );

  const messagesExist = messages.length || pendingMessage;

  const conversation = conversations.data?.result?.[0] ?? null;
  const hasUnreadMessages = !(conversation?.isRead ?? true);

  function scrollToBottom() {
    scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight);
  }

  useLayoutEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    if (!isFetchingNextPage) {
      scrollContainer.scrollTo(0, scrollContainer.scrollHeight - lastScrollAreaHeight.current);
    }

    lastScrollAreaHeight.current = scrollContainer.scrollHeight ?? 0;
  }, [isFetchingNextPage]);

  useLayoutEffect(() => {
    onMessagesLoading(!isMessagesLoading);
    scrollToBottom();
  }, [isMessagesLoading]);

  useLayoutEffect(() => {
    if (isScrollAtBottom.current || pendingMessage) {
      scrollToBottom();
    }
  }, [messages, pendingMessage, isChatWindowExpanded]);

  useEffect(() => {
    const error = last(messagesData?.pages)?.error;
    if (error) {
      onError(error);
    }
  }, [messagesData]);

  useEffect(() => {
    if (hasUnreadMessages) {
      /* After fetching new messages (Conversation.getMessages), server marks them as read,
         and we need to make the same changes in the local cache to keep the data consistent.
         @see https://git.casinomodule.org/casino23/tickets/-/issues/3035#note_211671
      */
      queryClient.setQueryData<ApiClientResponse<"Conversation.getList">>(
        ["Conversation.getList"],
        (prevData) => {
          if (!prevData || !prevData.result) return prevData;

          prevData.result[0].isRead = true;

          return {
            ...prevData,
            result: {
              ...prevData.result,
            },
          };
        },
      );

      if (queryClient.isFetching({ queryKey: ["Conversation.getMessages"] }) === 0) {
        queryClient.invalidateQueries({ queryKey: ["Conversation.getMessages"] });
      }
    }
  }, [messagesData, hasUnreadMessages]);

  useEffect(() => {
    const handleScrollEvent = () => {
      const scrollContainer = scrollContainerRef.current;
      if (!scrollContainer) return;

      isScrollAtBottom.current =
        scrollContainer.scrollTop + scrollContainer.clientHeight === scrollContainer.scrollHeight;

      if (scrollContainer.scrollTop === 0) {
        fetchNextPage();
      }
    };

    scrollContainerRef.current?.addEventListener("scroll", handleScrollEvent);
    return () => scrollContainerRef.current?.removeEventListener("scroll", handleScrollEvent);
  }, [scrollContainerRef.current, messagesExist]);

  return (
    <div className="support-chat-window__conversation-window">
      {messagesExist ? (
        <div
          ref={scrollContainerRef}
          className="relative h-full overflow-y-auto overscroll-contain p-2.5"
        >
          {isFetchingNextPage && (
            <div className="pointer-events-none absolute top-5 right-0 left-0 flex items-center justify-center">
              <Icon className="animate-spin text-20 text-accent" name="reload" />
            </div>
          )}
          <ul id="chat-history-list" className="space-y-2.5">
            {messages.map((message) => (
              <li key={message.seqNum}>
                <ChatMessage
                  sender={message.from.userName}
                  message={message.text}
                  timestamp={message.createdAt * 1000}
                  isFromCurrentUser={message.from.userId === player.data?.id}
                  locale={locale}
                />
              </li>
            ))}

            {pendingMessage && (
              <li style={{ opacity: 0.5 }}>
                <ChatMessage
                  sender={player.data?.name ?? ""}
                  message={pendingMessage}
                  timestamp={Date.now()}
                  locale={locale}
                  isFromCurrentUser
                />
              </li>
            )}
          </ul>
        </div>
      ) : isMessagesLoading ? (
        <div className="absolute inset-0 flex-center">
          <Spinner />
        </div>
      ) : (
        <EmptyChatPlaceholder />
      )}
    </div>
  );
});

interface IChatMessageProps {
  sender: string;
  message: string;
  locale: string;
  isFromCurrentUser: boolean;
  timestamp: number;
}

function formatDate(timestamp: number, locale: string) {
  const dt = new Date(timestamp);
  const diffDays = new Date().getDate() - dt.getDate();
  const diffMonths = new Date().getMonth() - dt.getMonth();
  const diffYears = new Date().getFullYear() - dt.getFullYear();

  const timeString = new Date(timestamp).toLocaleTimeString(locale, { timeStyle: "short" });
  const dateString = new Date(timestamp).toLocaleDateString(locale, { dateStyle: "short" });

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return timeString;
  } else {
    return `${timeString}, ${dateString}`;
  }
}

function ChatMessage({ sender, message, locale, timestamp, isFromCurrentUser }: IChatMessageProps) {
  return (
    <div className={`support-chat-message ${isFromCurrentUser ? "-user" : "-support"}`}>
      <span className="support-chat-message__sender-name">{sender}</span>
      <p className="leading-[1.125]">{message}</p>
      <span className="support-chat-message__timestamp">{formatDate(timestamp, locale)}</span>
    </div>
  );
}

export function SendMessageBtn({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: HTMLAttributes<HTMLButtonElement>["onClick"];
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}
      className="support-chat-window__send-message-btn"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 20">
        <path
          fill="currentColor"
          d="M.68 10.836c.885.31 2.041.729 3.577 1.166 1.545.447 2.274.525 3.84-.496 1.564-1.02 6.968-4.714 7.649-5.19.68-.466 2.002-1.244 2.255-1.06.252.185-.321 1.06-1.186 1.896-.865.836-4.656 4.325-5.599 5.268-.943.943-.933 1.633.04 2.42.961.788 1.311.933 5.131 3.548 3.82 2.615 4.724 1.74 5.093-.428.37-2.167 2.07-13.18 2.382-15.318.165-1.098.291-1.934-.292-2.226-.573-.291-1.254-.136-2.624.438-2.78 1.176-17.7 7.25-19.556 8.038-1.857.777-1.594 1.633-.71 1.944Z"
        />
      </svg>
    </button>
  );
}

export function EmptyChatPlaceholder() {
  const { $t } = useTranslate();

  return (
    <div className="support-chat-window-nodata-placeholder">
      <svg
        className="support-chat-window-nodata-placeholder__icon"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 112 112"
      >
        <path
          fill="currentColor"
          strokeWidth="3"
          d="m10.23 49.39-.25 1.7h6.82a4.1 4.1 0 0 1 4.1 4.1V84a4.1 4.1 0 0 1-4.1 4.1h-5.6c-5.46 0-9.7-4.1-9.7-8.9V56A54.56 54.56 0 0 1 56 1.5 54.56 54.56 0 0 1 110.5 56v23.2c0 4.8-4.25 8.9-9.7 8.9h-1.5v1.5c0 11.53-9.37 20.9-20.9 20.9H46.3V96.7h19.4v5.6h12.7c7 0 12.7-5.7 12.7-12.7V55.2a4.1 4.1 0 0 1 4.1-4.1h6.82l-.25-1.71A46.32 46.32 0 0 0 56 9.7a46.32 46.32 0 0 0-45.77 39.69Z"
        />
        <path
          fill="currentColor"
          d="M67.17 70.57a950.24 950.24 0 0 1 1.83-8.7l.84-1.05 1.55-7.37A1097.8 1097.8 0 0 1 73.18 45l6.02-2.63a1252.58 1252.58 0 0 0-5.27 5.84l-5.6 6.24a456.47 456.47 0 0 0-4.54 5.04l-.27-.99h8.2l.83-.57h8.84l-1.4 6.9h-24.1l1.1-5.36 2.26-2.52 2.52-2.82c.86-.93 1.64-1.8 2.36-2.6l4.34-4.85 2.27-2.52 2.27-2.52h8.34a4065.6 4065.6 0 0 0-2.12 10.1L77.1 61.85a5782.42 5782.42 0 0 0-1.82 8.72h-8.1ZM30 70.57l1.16-5.38a621 621 0 0 0 2.47-1.78c.81-.6 1.64-1.18 2.48-1.77a152.7 152.7 0 0 0 5.06-3.8 24.2 24.2 0 0 0 3.53-3.27 7.42 7.42 0 0 0 1.7-3.22c.24-1.06.08-1.93-.48-2.6-.55-.67-1.45-1-2.71-1-.62 0-1.27.08-1.95.26-.69.19-1.4.43-2.13.74-.72.3-1.47.66-2.22 1.07-.74.4-1.5.81-2.25 1.26l.95-7.98c.68-.3 1.39-.57 2.12-.82.73-.25 1.48-.47 2.27-.65a22.6 22.6 0 0 1 5.27-.63c2.42 0 4.4.44 5.96 1.32a7.16 7.16 0 0 1 3.28 3.64c.63 1.52.73 3.26.31 5.2-.3 1.45-.85 2.78-1.64 4.02a17.18 17.18 0 0 1-3.23 3.6 44.47 44.47 0 0 1-5.19 3.79l-4.43 2.85-.82-1.74h12.86l-1.45 6.89H30Z"
        />
      </svg>
      <div>{$t({ defaultMessage: "Have a question? Ask us!" })}</div>
    </div>
  );
}
