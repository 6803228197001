import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";

export default function ErrorPlaceholder({
  className,
}: {
  className?: string;
}) {
  const { $t } = useTranslate();

  return (
    <div className={clsx("error-placeholder", className)}>
      <p>{$t({ defaultMessage: "An error has occurred" })}</p>
    </div>
  );
}
