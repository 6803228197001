import { apiClient } from "@lobby/api-client";
import { useIntlT } from "@lobby/ocb-intl";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Lobby } from "../lobby";

export const FortuneWheel = {
  useWheelSettings() {
    const {
      intl: { locale, defaultLocale },
    } = useIntlT();

    const lang = locale || defaultLocale;

    return useQuery({
      refetchInterval: 1000 * 15,
      queryKey: ["FortuneWheel.getSettings", lang],
      queryFn: () =>
        apiClient.send({
          params: { lang },
          endpoint: "FortuneWheel.getSettings",
        }),
    });
  },
  useWheelWinner() {
    return useQuery({
      queryKey: ["FortuneWheel.getWin"],
      queryFn: () =>
        apiClient.send({
          endpoint: "FortuneWheel.getWin",
        }),
      select: (data) => data.result,
    });
  },
  useWheelSpin() {
    return useMutation({
      mutationKey: ["FortuneWheel.getWin"],
      mutationFn: () =>
        apiClient.send({
          endpoint: "FortuneWheel.getWin",
        }),
    });
  },
  useIsWheelAvailable() {
    const { data } = Lobby.useLobby();
    return Boolean(data?.fortuneWheel?.isActive);
  },
};
