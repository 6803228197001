import { Field, Input, Label, RadioGroup } from "@headlessui/react";
import { useTranslate } from "@lobby/ocb-intl";
import { useEffect, useRef, useState } from "react";
import type { FormEvent } from "react";
import { Payment } from "../../../../../entities";
import {
  APIError,
  Button,
  Icon,
  Spinner,
  type TPaymentMethodsList,
  emitter,
  first,
} from "../../../../../shared";
import { NoDataPlaceholder, ProfileSection } from "../../components";
import { PaymentMethodCard } from "./components";
import { penniesToDollars } from "./helpers";

export function DepositTab() {
  const { $t } = useTranslate();

  const { data, isLoading } = Payment.useMethodList("deposit");

  return (
    <ProfileSection
      isLoading={isLoading}
      data={data}
      placeholder={
        <NoDataPlaceholder
          topText={$t({
            defaultMessage:
              "Currently, there are no available methods for depositing funds.",
          })}
          bottomText={$t({
            defaultMessage: "Please contact the support chat to deposit funds.",
          })}
          icon={<Icon name="creditCard" className="text-[6.25rem]" />}
        />
      }
    >
      {(validData) => <DepositTabView data={validData} />}
    </ProfileSection>
  );
}

function DepositTabView({ data }: { data: TPaymentMethodsList["data"] }) {
  const { $t } = useTranslate();
  const [paymentMethod, setPaymentMethod] = useState(first(data)?.id);
  const [paymentAmount, setPaymentAmount] = useState<string>("");
  const [validationError, setValidationError] = useState("");

  const formRef = useRef<HTMLFormElement>(null);

  const { mutate: deposit, isPending } = Payment.useDeposit();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setValidationError("");

    if (!formRef.current) return;

    const data = new FormData(formRef.current);
    data.set(
      "payment-amount",
      String(Number(data.get("payment-amount")) * 100)
    );

    if (validateForm(data)) {
      deposit(
        {
          methodId: Number(data.get("payment-method")),
          amount: Number(data.get("payment-amount")),
        },
        {
          onSuccess: (data) => {
            if (data.error) {
              const { message, code } = data.error;
              emitter.emit("ERROR", { error: new APIError(message, { code }) });
            } else if (data.result.paymentUrl) {
              window.location.href = data.result.paymentUrl;
            }
          },
        }
      );
    } else {
      console.error("Validation error");
    }
  }

  function validatePaymentMethod(method: number) {
    return data.some((item) => item.id === method);
  }

  function validatePaymentAmount(amount: number, method: number) {
    const selectedMethod = data.find((item) => item.id === method);

    if (!selectedMethod) return false;

    const isDepositMethodWithLimits = Boolean(selectedMethod.limits);

    if (isDepositMethodWithLimits) {
      const min = selectedMethod.limits?.min ?? 0;
      const max = selectedMethod.limits?.max ?? 0;

      return amount && amount >= min && amount <= max;
    }
    return amount && amount > 0;
  }

  function validateForm(data: FormData) {
    const paymentMethod = Number(data.get("payment-method"));
    const paymentAmount = Number(data.get("payment-amount"));

    return (
      validatePaymentMethod(paymentMethod) &&
      validatePaymentAmount(paymentAmount, paymentMethod)
    );
  }

  const selectedMethod = data.find((item) => item.id === Number(paymentMethod));
  const isDepositMethodWithLimits = Boolean(selectedMethod?.limits);

  let paymentAmountMin;
  let paymentAmountMax;

  if (isDepositMethodWithLimits) {
    paymentAmountMin = penniesToDollars(selectedMethod?.limits?.min);
    paymentAmountMax = penniesToDollars(selectedMethod?.limits?.max);
  }

  useEffect(() => {
    const defaultAmount = selectedMethod?.limits?.default;
    if (defaultAmount !== undefined) {
      setPaymentAmount(String(penniesToDollars(defaultAmount)));
    }
  }, [paymentMethod]);

  return (
    <div className="gutter-stable relative min-h-0 py-1 mobile-only:pb-5 lg:h-full lg:max-h-full lg:overflow-y-auto">
      <form
        className={`text-14 ${isPending ? "blur-sm" : ""}`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <ol className="list-outside list-decimal space-y-10 ps-5">
          <li>
            {$t({ defaultMessage: "Choose one of the payment methods" })}
            <RadioGroup
              value={paymentMethod}
              onChange={setPaymentMethod}
              name="payment-method"
              className="mt-2.5"
            >
              <ul className="flex flex-wrap gap-2.5">
                {data.map(({ id, name, logoUrl, commission, limits }, i) => (
                  <li key={i}>
                    <PaymentMethodCard
                      id={id}
                      name={name}
                      logoUrl={logoUrl}
                      commission={commission}
                      limits={limits}
                    />
                  </li>
                ))}
              </ul>
            </RadioGroup>
          </li>
          <li>
            {$t({ defaultMessage: "Enter the amount you want to deposit" })}
            <Field disabled={!selectedMethod}>
              <Label className="data-[disabled]:opacity-50">
                {$t({ defaultMessage: "Amount" })}:
              </Label>
              <div className="flex mobile-only:flex-col gap-3 lg:gap-2.5">
                <Input
                  className="w-52 appearance-none data-[disabled]:opacity-50"
                  placeholder={$t({ defaultMessage: "Enter the amount" })}
                  name="payment-amount"
                  type="number"
                  inputMode="numeric"
                  value={paymentAmount}
                  pattern="[0-9]*"
                  // onChange remove all non-numeric characters
                  onChange={(e) => setPaymentAmount(e.target.value)}
                  min={paymentAmountMin}
                  max={paymentAmountMax}
                  step={0.01}
                  required
                />
                <Button
                  variant="success"
                  type="submit"
                  disabled={!paymentAmount}
                  className="mobile-only:mx-auto mobile-only:w-fit"
                >
                  {$t({ defaultMessage: "Deposit" })}
                </Button>
              </div>
            </Field>

            {validationError && (
              <p className="text-center text-alizarin-crimson leading-none">
                {validationError}
              </p>
            )}

            <div
              style={{
                color: "var(--text-secondary-color)",
              }}
              className="mt-2 text-12"
            >
              {$t({
                defaultMessage:
                  "After clicking 'Deposit', you will be redirected to the payment page.",
              })}
            </div>
          </li>
        </ol>
      </form>

      {isPending && (
        <div className="absolute inset-0 flex-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}
