import { Tab as HeadlessTab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { Fragment, type ReactNode } from "react";

export type TabsProps = {
  items: TabItemType[];
};

export type TabItemType = {
  label: ReactNode;
  children: ReactNode;
  disabled?: boolean;
};

export function Tabs({ items }: TabsProps) {
  return (
    <TabGroup className="contents">
      <TabList
        style={{
          color: "var(--tab-color-default)",
        }}
        className="grid grid-cols-[repeat(auto-fit,minmax(1px,1fr))] border-b-2"
      >
        {items.map(({ label, disabled }, idx) => (
          <Tab key={idx} label={label} disabled={disabled} />
        ))}
      </TabList>
      <TabPanels className="mt-5 h-full min-h-0 *:h-full lg:mt-5">
        {items.map((item, idx) => (
          <TabPanel key={idx}>{item.children}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
}

function Tab({ label, disabled }: { label: ReactNode; disabled?: boolean }) {
  const { $t } = useTranslate();

  return (
    <HeadlessTab as={Fragment} disabled={disabled}>
      {({ selected }) => (
        <button
          type="button"
          style={{
            color: selected ? "var(--accent-color)" : "inherit",
          }}
          className={clsx(
            "translate-y-[0.125rem] whitespace-normal border-b-2 font-medium text-sm focus:outline-none focus-visible:outline-0 lg:text-16/none",
            selected ? "border-current" : "border-transparent",
            disabled ? "opacity-50 lg:px-3" : "p-2 lg:p-5 lg:pt-2.5",
          )}
        >
          <div className="flex-center flex-col">
            <span className="uppercase">{label}</span>
            {disabled && (
              <span className="lg:mt-1.5 text-10/none">
                {$t({ defaultMessage: "Not available for you" })}
              </span>
            )}
          </div>
        </button>
      )}
    </HeadlessTab>
  );
}
