import { FortuneWheel } from "@lobby/core/src/entities";
import { ErrorPlaceholder } from "@lobby/core/src/shared";
import * as Sentry from "@sentry/react";
import type { PropsWithChildren } from "react";
import { Skeleton } from "shared/ui";

export default Sentry.withErrorBoundary(RulesSectionDesktop, {
  fallback: (
    <RulesContainer>
      <ErrorPlaceholder className="w-96 h-64" />
      <ErrorPlaceholder className="w-96 h-64" />
    </RulesContainer>
  ),
});

function RulesSectionDesktop() {
  const { data, isLoading } = FortuneWheel.useWheelSettings();

  if (isLoading) {
    return (
      <RulesContainer>
        <RulesContentSkeleton />
        <RulesContentSkeleton />
      </RulesContainer>
    );
  }

  const rules = (data as NonNullable<typeof data>).result.rules;

  return (
    <RulesContainer>
      <RulesContent
        title={rules.howToWork.title}
        description={rules.howToWork.body}
      />
      <RulesContent
        title={rules.dailyRules.title}
        description={rules.dailyRules.body}
      />
    </RulesContainer>
  );
}

function RulesContainer({ children }: PropsWithChildren) {
  return (
    <div className="pointer-events-none *:pointer-events-auto absolute w-full max-w-screen-3xl flex-c-sb">
      {children}
    </div>
  );
}

function RulesContent({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <RulesCard>
      <div className="mb-2.5 font-bold text-22 uppercase">{title}</div>
      <p className="whitespace-pre-line text-18">{description}</p>
    </RulesCard>
  );
}

function RulesContentSkeleton() {
  return (
    <RulesCard>
      <Skeleton className="mb-2.5 h-7.5" />
      <div className="flex w-full flex-col gap-1.5 *:h-[1.625rem] *:w-full">
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </RulesCard>
  );
}

function RulesCard({ children }: PropsWithChildren) {
  return (
    <div className="isolate w-96 rounded-5 bg-white/10 p-2.5">
      <div className="rounded-inherit bg-raisin-black px-8 py-6">
        {children}
      </div>
    </div>
  );
}
