import { Field, Input, Label } from "@headlessui/react";
import { useTranslate } from "@lobby/ocb-intl";
import { type FormEvent, useState } from "react";
import { Player } from "../../../../../entities";
import { Button } from "../../../../../shared";

export function UserDataForm() {
  const [canUpdate, setCanUpdate] = useState(false);
  const { $t } = useTranslate();

  const updateMutation = Player.useUpdatePlayer();
  const { data: player } = Player.usePlayer();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const form = new FormData(e.currentTarget);

    if (validateForm(form)) {
      updateUserData(form);
    }
  }

  function validateInputValuesChanged(form: FormData) {
    const displayableName = form.get("username") as string;
    const phone = form.get("phone") as string;

    const isDisplayableNameUpdated = player?.displayableName !== displayableName;
    const isPhoneUpdated = player?.phone !== phone;

    return isDisplayableNameUpdated || isPhoneUpdated;
  }

  function validateForm(form: FormData) {
    return validateInputValuesChanged(form);
  }

  function updateUserData(form: FormData) {
    updateMutation.mutate(
      {
        displayableName: form.get("username") as string,
        phone: form.get("phone") as string,
      },
      {
        onSuccess: (data) => {
          if (!data.error) {
            setCanUpdate(false);
          }
        },
      },
    );
  }

  function handleChange(e: FormEvent<HTMLFormElement>) {
    const form = new FormData(e.currentTarget);
    setCanUpdate(validateInputValuesChanged(form));
  }

  return (
    <form className="relative h-full" onSubmit={handleSubmit} onChange={handleChange}>
      <Field className="flex flex-col">
        <Label>{$t({ defaultMessage: "Name" })}:</Label>
        <Input
          type="text"
          name="username"
          placeholder={$t({ defaultMessage: "Username" })}
          pattern="[a-zA-Z0-9_\- ]{3,50}"
          title={$t({ defaultMessage: "3 to 50 letters, numbers, hyphens or underscores" })}
          defaultValue={String(player?.displayableName)}
          required
        />
      </Field>
      <Field className="flex flex-col mt-5">
        <Label>{$t({ defaultMessage: "Phone" })}:</Label>
        <Input
          type="tel"
          name="phone"
          placeholder={$t({ defaultMessage: "Phone" })}
          pattern="[0-9]{6,100}"
          title={$t({ defaultMessage: "6 to 100 numbers" })}
          defaultValue={player?.phone ?? ""}
        />
        {updateMutation.data?.error && (
          <div
            style={{
              color: "var(--error-accent-color)",
            }}
            className="text-12/5 lg:text-14/5"
          >
            {updateMutation.data.error.message}
          </div>
        )}
      </Field>

      <div className="flex items-center gap-3 mt-6 mobile-only:justify-end">
        <Button
          variant="secondary"
          type="submit"
          loading={updateMutation.isPending}
          disabled={!canUpdate}
          className="mx-auto"
        >
          {$t({ defaultMessage: "Update" })}
        </Button>
      </div>
    </form>
  );
}
