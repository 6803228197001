import { createNanoEvents } from "nanoevents";
import type { TLocale } from "./locales";
import type {
  ICongratsModalProps,
  ServerErrorEventPayload,
  SuccessEventPayload,
  WalletIntegrationWarningEventPayload,
  WarningEventPayload,
} from "./types";

export interface Events {
  CONGRATS_MODAL_OPEN: (params: ICongratsModalProps) => void;
  ERROR: (payload: ServerErrorEventPayload) => void;
  PLAYER_BALANCE_VISIBILITY_CHANGED: (isVisible: boolean) => void;
  PLAYER_LOGIN: () => void;
  PLAYER_LOGOUT: () => void;
  SET_LOCALE: (locale: TLocale) => void;
  SUCCESS_MODAL_OPEN: (payload: SuccessEventPayload) => void;
  SUPPORT_CHAT_OPEN: () => void;
  THEME_CHANGED: (theme: "dark" | "light") => void;
  WARNING: (payload: WarningEventPayload) => void;
  WALLET_INTEGRATION_WARNING_MODAL_OPENED: (payload: WalletIntegrationWarningEventPayload) => void;
}

export const emitter = createNanoEvents<Events>();
