import type { ApiClientResponse } from "@lobby/api-client";
import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { clsx } from "clsx";
import type { PropsWithChildren, ReactNode } from "react";
import { usePlayerMoneyFormatter } from "../../../../../entities";
import { formatOdds } from "./helpers";

type TCoupon = ApiClientResponse<"History.getSport">["result"]["data"][number];

export function Coupon({
  createdAt,
  uid,
  eventsToGuessNum,
  description,
  typeEx,
  odds,
  bet,
  win,
  currency,
  status,
  bonusAmount = 0,
  outcomeStatus,
  events,
}: Omit<TCoupon, "type">) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  const isCancelled = status === "cancelled" || outcomeStatus === "cancelled";
  const isWin = outcomeStatus === "win";
  const isHalfWin = outcomeStatus === "half_win";
  const isHalfLost = outcomeStatus === "half_lost";
  const isDraw = outcomeStatus === "draw";
  const isLost = outcomeStatus === "lost";

  const hasBonus = bonusAmount > 0;

  const formattedDate = new Date(createdAt).toLocaleDateString();
  const formattedUid = uid?.match(/.{1,4}/g)?.join(" ") ?? uid;
  const formattedOdds = formatOdds(odds);
  const formattedBonus = formatMoney(bonusAmount, { currency });
  const formattedBet = formatMoney(bet, { currency });
  const formattedWin = formatMoney(win, { currency });

  let BottomRightFooterComponent: ReactNode = <></>;
  if (hasBonus && (isWin || isHalfWin || isHalfLost || isDraw)) {
    BottomRightFooterComponent = (
      <>
        <span className="text-secondary">{$t({ defaultMessage: "Bonus" }) + skipIntl(": ")}</span>
        <span className="font-bold">{formattedBonus}</span>
      </>
    );
  } else if (status === "cashout") {
    BottomRightFooterComponent = (
      <div className="font-bold uppercase text-secondary">{$t({ defaultMessage: "cashout" })}</div>
    );
  }

  let TopRightFooterComponent = <></>;
  if (status === "cashout") {
    TopRightFooterComponent = (
      <OutcomeStatus>
        <MoneyAmount>{formattedWin}</MoneyAmount>
      </OutcomeStatus>
    );
  } else if (isWin) {
    TopRightFooterComponent = (
      <OutcomeStatus>
        <span className="text-secondary">{$t({ defaultMessage: "Win" }) + skipIntl(": ")}</span>
        <MoneyAmount>{formattedWin}</MoneyAmount>
        <WinArrow />
      </OutcomeStatus>
    );
  } else if (isHalfWin) {
    TopRightFooterComponent = (
      <OutcomeStatus>
        <span className="text-secondary">
          {$t({ defaultMessage: "Half win" }) + skipIntl(": ")}
        </span>
        <MoneyAmount>{formattedWin}</MoneyAmount>
        <WinArrow />
      </OutcomeStatus>
    );
  } else if (isHalfLost) {
    TopRightFooterComponent = (
      <OutcomeStatus>
        <span className="text-secondary">
          {$t({ defaultMessage: "Half lose" }) + skipIntl(": ")}
        </span>
        <MoneyAmount>{formattedWin}</MoneyAmount>
        <WinArrow />
      </OutcomeStatus>
    );
  } else if (isDraw) {
    TopRightFooterComponent = (
      <OutcomeStatus>
        <span className="text-secondary">{skipIntl("Draw: ")}</span>
        <MoneyAmount>{formattedWin}</MoneyAmount>
      </OutcomeStatus>
    );
  } else if (isLost) {
    TopRightFooterComponent = (
      <OutcomeStatus>
        <span className="font-bold uppercase text-secondary">{$t({ defaultMessage: "Lose" })}</span>
      </OutcomeStatus>
    );
  } else if (isCancelled) {
    TopRightFooterComponent = (
      <OutcomeStatus>
        <span className="font-bold uppercase text-secondary">
          {$t({ defaultMessage: "Cancelled" })}
        </span>
      </OutcomeStatus>
    );
  }

  return (
    <div className={`sport-coupon sport-coupon--status-${outcomeStatus}`}>
      <Header
        createdAt={formattedDate}
        typeEx={typeEx}
        description={description}
        eventsToGuessNum={eventsToGuessNum}
        odds={formattedOdds}
      />
      <Body events={events} />
      <Footer
        uid={formattedUid}
        bet={formattedBet}
        topRightSlot={TopRightFooterComponent}
        bottomRightSlot={BottomRightFooterComponent}
      />
    </div>
  );
}

interface IHeaderProps {
  createdAt: string;
  typeEx: string;
  description: string;
  eventsToGuessNum: number;
  odds: number;
}

function Header({ createdAt, typeEx, description, eventsToGuessNum, odds }: IHeaderProps) {
  return (
    <div className="header">
      <div className="flex-c-sb uppercase">
        <div className="whitespace-pre">
          <span className="font-bold">{createdAt}</span>{" "}
          {typeEx === "single" ? typeEx : description}
        </div>
        {eventsToGuessNum > 1 && <div className="font-bold">{odds}</div>}
      </div>
    </div>
  );
}

interface IBodyProps {
  events: TCoupon["events"];
}

function Body({ events }: IBodyProps) {
  return (
    <div className="body">
      {events.map((ev, idx) => {
        const isWinEvent = ev.status === "win";
        const isLoseEvent = ev.status === "lose";

        return (
          <div className={clsx("coupon-event", `coupon-event--status-${ev.status}`)} key={idx}>
            <div className="flex-c-sb font-bold">
              <div>{ev.name}</div>
              <div>{formatOdds(ev.odds)}</div>
            </div>
            <div className="flex-c-sb">
              <div
                style={{
                  color: "var(--accent-color)",
                }}
              >
                {ev.sport}
              </div>
              <div>{ev.categoryName}</div>
            </div>
            <div className="flex-c-sb">
              <div>
                <span className="font-bold">{ev.selName}</span>
                {skipIntl(" ")}
                <span className="font-light">{skipIntl(`(${ev.marketName})`)}</span>
              </div>
              <div className="size-4 lg:size-5.5">
                {isWinEvent ? (
                  <svg
                    style={{
                      color: "var(--success-accent-color)",
                    }}
                    width="100%"
                    height="100%"
                    viewBox="0 0 20 21"
                  >
                    <path
                      fill="currentColor"
                      d="M10 20.04A10 10 0 1 1 10.01.03a10 10 0 0 1-.01 20ZM16.47 6a1.33 1.33 0 0 0-1.88 0L9 11.58 6.03 8.65a1.33 1.33 0 0 0-1.88 1.87l3.8 3.76c.57.58 1.51.58 2.09 0l6.43-6.4c.52-.51.52-1.35 0-1.87Z"
                    />
                  </svg>
                ) : (
                  isLoseEvent && (
                    <svg
                      style={{
                        color: "var(--error-accent-color)",
                      }}
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 21"
                    >
                      <path
                        fill="currentColor"
                        d="M10 20.04A10 10 0 1 1 10.01.03a10 10 0 0 1-.01 20Zm4.89-12.72c.53-.52.53-1.37 0-1.89a1.35 1.35 0 0 0-1.9 0L10 8.4 7 5.43a1.35 1.35 0 0 0-1.89 0 1.32 1.32 0 0 0 0 1.89l2.72 2.72-2.73 2.71a1.32 1.32 0 0 0 0 1.89c.53.52 1.38.52 1.9 0l3-2.97 3 2.97c.52.52 1.37.52 1.89 0 .53-.52.53-1.37 0-1.89l-2.73-2.71 2.73-2.72Z"
                      />
                    </svg>
                  )
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

interface IFooterProps {
  uid: string;
  bet: string;
  topRightSlot: ReactNode;
  bottomRightSlot: ReactNode;
}

function Footer({ uid, bet, topRightSlot, bottomRightSlot }: IFooterProps) {
  const { $t } = useTranslate();

  return (
    <div className="footer">
      <hr />
      <div className="footer-inner">
        <div className="tl whitespace-pre font-medium">
          <span className="text-secondary">{$t({ defaultMessage: "Bet" }) + skipIntl(": ")}</span>
          <MoneyAmount>{bet}</MoneyAmount>
        </div>

        <div className="tr whitespace-pre">{topRightSlot}</div>

        <div className="bl self-end text-[0.6875em] text-secondary">
          {$t({ defaultMessage: "ID" }) + skipIntl(": ")} {uid}
        </div>

        <div className="br self-end text-secondary">{bottomRightSlot}</div>
      </div>
    </div>
  );
}

function MoneyAmount({ children }: PropsWithChildren) {
  return <span className="money-amount">{children}</span>;
}

function OutcomeStatus({ children }: PropsWithChildren) {
  return <div className="flex items-center justify-end">{children}</div>;
}

function WinArrow() {
  return (
    <svg
      height="1em"
      width="1em"
      viewBox="0 0 14 15"
      style={{ color: "var(--success-accent-color)" }}
      className="ms-1"
    >
      <path fill="currentColor" d="M14 8.275 7.368.905.734 8.275h3.683v6.628h5.896V8.275h3.688Z" />
    </svg>
  );
}
