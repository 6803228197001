import { Switch } from "@headlessui/react";
import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { useContext } from "react";
import { LobbySettingsContext } from "../../../entities";
import { emitter } from "../../lib";

interface IThemeSwitchProps {
  className?: string;
  onClick?: (checked: boolean) => void;
}

export function ThemeSwitch(props: IThemeSwitchProps) {
  if (import.meta.env.VITE_THEME_CHANGE_ENABLED !== "true") {
    return null;
  }

  return <ThemeSwitchView {...props} />;
}

function ThemeSwitchView({ className, onClick }: IThemeSwitchProps) {
  const lobbyContext = useContext(LobbySettingsContext);
  const { $t } = useTranslate();
  const isChecked = lobbyContext.theme === "dark";

  function handleChange(checked: boolean) {
    const theme = checked ? "dark" : "light";

    emitter.emit("THEME_CHANGED", theme);

    if (onClick) {
      onClick(checked);
    }
  }

  return (
    <Switch
      checked={isChecked}
      onChange={handleChange}
      className={clsx(
        className,
        "border-keppel dark:border-java border-2 rounded-[6.25rem] relative flex items-center h-7.5 w-13 cursor-pointer",
      )}
    >
      <span className="sr-only">{$t({ defaultMessage: "Switch theme" })}</span>
      <svg
        className="absolute top-1/2 left-1.5 size-3 -translate-y-1/2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11 10"
      >
        <path
          className="fill-alto"
          fillRule="evenodd"
          d="M2.284 7.984A5 5 0 0 0 7.932.697a5.002 5.002 0 0 1-4.01 9.03 4.985 4.985 0 0 1-3.06-2.373c.427.276.904.491 1.422.63Z"
          clipRule="evenodd"
        />
      </svg>
      <svg
        className="absolute top-1/2 right-[0.1875rem] size-4.5 -translate-y-1/2 "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          className="stroke-keppel dark:stroke-java"
          d="M9.458 2.8 9 1.747 8.542 2.8 7.65 4.847 5.727 3.715l-.99-.583.248 1.122.483 2.18-2.222.214-1.144.11.86.762L4.634 9l-1.672 1.48-.86.761 1.144.11 2.222.215-.483 2.18-.248 1.122.99-.583 1.924-1.132.89 2.046L9 16.253l.458-1.053.891-2.047 1.924 1.132.99.583-.248-1.122-.483-2.18 2.222-.214 1.144-.11-.86-.762L13.366 9l1.672-1.48.86-.761-1.144-.11-2.222-.215.483-2.18.248-1.122-.99.583-1.924 1.132L9.46 2.8Z"
        />
      </svg>
      <span
        className={`bg-keppel dark:bg-java rounded-full absolute top-1/2 left-[0.1875rem] -translate-y-1/2 transition-transform duration-150 w-5 h-5 ${isChecked && "translate-x-[1.375rem]"}`}
      />
    </Switch>
  );
}
