import { memo, useEffect, useRef, useState } from "react";
import { Modal, useLobbyLogo } from "../../../shared";
import {
  ProfileBalanceDetails,
  ProfilePlayerBalanceView,
  ProfileSectionControls,
  type TProfileSectionItem,
  useProfileSections,
} from "../common";

interface IProfileComponentDesktopProps {
  currentSection: TProfileSectionItem;
  showLoadingOverlay: boolean;
  close: VoidFunction;
}

export const ProfileComponentDesktop = memo(function ProfileComponentDesktop({
  currentSection = {} as TProfileSectionItem,
  showLoadingOverlay,
  close,
}: IProfileComponentDesktopProps) {
  const [panelHeight, setPanelHeight] = useState(0);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const Logo = useLobbyLogo();
  const sections = useProfileSections();

  useEffect(() => {
    if (!sidebarRef.current) return;

    const observer = new ResizeObserver((entries) => {
      entries.forEach(({ contentRect }) => setPanelHeight(contentRect.height));
    });

    observer.observe(sidebarRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <Modal.Panel className="user-profile top-18 w-[62.5rem]">
      <Modal.Header onCloseButtonClick={close}>
        <Logo />
      </Modal.Header>

      <div className="grid h-full min-h-0 grid-cols-[minmax(18.5rem,22rem)_1fr] gap-10 *:min-h-0">
        <div ref={sidebarRef} className="flex h-fit flex-col gap-5">
          <ProfilePlayerBalanceView />
          <ProfileBalanceDetails />
          <ProfileSectionControls currentSectionId={currentSection.id} items={sections} />
        </div>
        <div
          className="relative flex grow flex-col overflow-hidden"
          style={{
            height: `${panelHeight}px`,
          }}
        >
          <div className="section-title">{currentSection.title}</div>
          {currentSection.component}
        </div>
      </div>

      <Modal.PendingOverlay isActive={showLoadingOverlay} />
    </Modal.Panel>
  );
});
