import * as Sentry from "@sentry/react";
import { Icons } from "./icons";

interface IIconProps {
  className?: string;
  name: keyof typeof Icons;
}

export function Icon({ className, name }: IIconProps) {
  return (
    <Sentry.ErrorBoundary fallback={<pre>%</pre>}>
      <IconComponent className={className} name={name} />
    </Sentry.ErrorBoundary>
  );
}

function IconComponent({ className, name }: IIconProps) {
  const Icon = Icons[name];

  return <Icon className={className} />;
}

export type IconName = IIconProps["name"];
