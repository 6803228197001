import { clsx } from "clsx";
import type { MouseEvent, ReactNode } from "react";

interface IAlertProps {
  message: ReactNode;
  description?: ReactNode;
  type: "error" | "warning" | "info" | "success";
  closable?: boolean;
  onClose?: (e: MouseEvent<HTMLButtonElement>) => void;
  afterClose?: VoidFunction;
}

export function Alert({
  message,
  description,
  type = "error",
  closable = false,
  onClose,
  afterClose,
}: IAlertProps) {
  function handleClose(e: MouseEvent<HTMLButtonElement>) {
    onClose?.(e);

    const parent = e.currentTarget.parentElement;
    if (!parent) return;

    const parentHeight = parent.offsetHeight;
    parent.style.maxHeight = parentHeight + "px";
    parent.classList.add("alert-motion_leave", "alert-motion_leave-active");

    parent.addEventListener("transitionend", (e) => {
      e.propertyName === "opacity" && afterClose?.();
    });
  }

  return (
    <div
      className={clsx(`alert alert-motion_show alert_type_${type}`, {
        "alert_with-description": Boolean(description),
      })}
    >
      <div className="min-w-0 flex-1 space-y-2 text-black/90 dark:text-white/85">
        <div className="alert__message">{message}</div>
        {description && <div className="alert__description">{description}</div>}
      </div>
      {closable && (
        <button className="ms-2" type="button" onClick={handleClose}>
          <span>
            <svg viewBox="64 64 896 896" width="1em" height="1em" fill="currentColor">
              <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
            </svg>
          </span>
        </button>
      )}
    </div>
  );
}
