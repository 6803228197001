import { useNavigate } from "@tanstack/react-router";
import { LogoutButton } from "../../../components/LogoutButton";
import { CategoryButton, type IconName, useMobile } from "../../../shared";
import type { ProfileSection } from "../const";
import type { TProfileSectionItem } from "./profile-sections";

interface IProfileSectionControlsProps {
  currentSectionId: ProfileSection | undefined;
  items: TProfileSectionItem[];
}

export function ProfileSectionControls({ items, currentSectionId }: IProfileSectionControlsProps) {
  const navigate = useNavigate();
  const isMobile = useMobile();

  return (
    <div className="profile-area flex flex-col">
      <ul className="flex mobile-only:max-h-max flex-col gap-2.5 py-1 lg:h-[17.5rem] lg:overflow-y-scroll">
        {items.map(({ title, icon, id }, idx) => (
          <li key={idx}>
            <CategoryButton
              iconName={icon as IconName}
              isActive={id === currentSectionId}
              onClick={() =>
                navigate({
                  // @ts-ignore
                  search: (prev) => ({ ...prev, profileSection: id }),
                })
              }
            >
              {title}
            </CategoryButton>
          </li>
        ))}
      </ul>
      {!isMobile && <LogoutButton className="mt-5" />}
    </div>
  );
}
