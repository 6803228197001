import { useTranslate } from "@lobby/ocb-intl";
import { FormattedMessage } from "react-intl";
import type { WalletIntegrationWarningEventPayload } from "../../lib";
import { Dialog, type IDialogProps } from "../dialog";

interface IWalletIntegrationWarningModalProps extends IDialogProps {
  payload: WalletIntegrationWarningEventPayload;
}

export function WalletIntegrationWarningModal({ payload }: IWalletIntegrationWarningModalProps) {
  const { $t } = useTranslate();

  const title = $t({ defaultMessage: "please note" });

  return (
    <Dialog type="warning" title={title} icon="warningSign" onClose={() => payload.onOk()}>
      <Dialog.Message>
        <FormattedMessage
          defaultMessage={
            "When you switch to the game, your entire balance will be transferred to the game.{br}This is a feature of this game provider."
          }
          values={{ br: <br /> }}
        />
      </Dialog.Message>
      <Dialog.Description>
        {$t({
          defaultMessage:
            "There may be a situation where the balance in the system and in the game is different.",
        })}
        <br />
        {$t({
          defaultMessage:
            "In order to return the balance from the game to system, you need to click EXIT (cross) on the screen in the game.",
        })}
      </Dialog.Description>
    </Dialog>
  );
}
