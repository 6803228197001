import { createFileRoute } from "@tanstack/react-router";
import {
  loadBannerList,
  loadFortuneWheelSettings,
  loadGameCategoryList,
  loadGameList,
  loadProviderList,
  loadRecommendedGames,
} from "app/lib/loaders";
import { HomePage } from "pages/home";

export const Route = createFileRoute("/_auth/casino")({
  loader: () =>
    Promise.all([
      loadBannerList(),
      loadGameCategoryList(),
      loadGameList(),
      loadProviderList(),
      loadRecommendedGames(),
      loadFortuneWheelSettings(),
    ]),
  component: HomePage,
  onError: console.error,
});
