import { useTranslate } from "@lobby/ocb-intl";
import { Tabs } from "../../../../../shared";
import { TransactionTable } from "./components";

export function TransactionHistory() {
  const { $t } = useTranslate();

  return (
    <div className="mobile-only:relative flex h-full min-h-0 flex-col">
      <Tabs
        items={[
          {
            label: $t({ defaultMessage: "Deposit" }),
            children: <TransactionTable type="deposit" />,
          },
          {
            label: $t({ defaultMessage: "Withdraw" }),
            children: <TransactionTable type="withdraw" />,
          },
        ]}
      />
    </div>
  );
}
