import { translateDynamicMessage, useIntlT, useTranslate } from "@lobby/ocb-intl";
import { Player, usePlayerMoneyFormatter } from "../../../../../entities";
import { Icon, type TBonusHistory } from "../../../../../shared";
import { NoDataPlaceholder, ProfileSection } from "../../components";

export function Bonuses() {
  const { $t } = useTranslate();
  const { data, isLoading } = Player.useBonusHistory();

  return (
    <ProfileSection
      data={data}
      isLoading={isLoading}
      placeholder={
        <NoDataPlaceholder
          topText={$t({ defaultMessage: "Your bonus will be here soon" })}
          icon={<Icon name="bonusGift" className="text-[4.5rem]" />}
        />
      }
    >
      {(validatedData) => <BonusesView data={validatedData} />}
    </ProfileSection>
  );
}

function BonusesView({ data }: { data: TBonusHistory }) {
  const { $t } = useTranslate();
  const { intl } = useIntlT();

  const formatMoney = usePlayerMoneyFormatter();

  return (
    <div className="mobile-only:relative flex h-full max-h-full min-h-0 flex-col">
      <div className="gutter-stable overflow-y-auto">
        <table>
          <thead>
            <tr>
              <th>{$t({ defaultMessage: "Date" })}</th>
              <th>{$t({ defaultMessage: "Bonus" })}</th>
              <th>{$t({ defaultMessage: "Wager" })}</th>
              <th>{$t({ defaultMessage: "Amount" })}</th>
              <th>{$t({ defaultMessage: "Status" })}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((bonus) => (
              <tr key={bonus.id}>
                <td>
                  {new Date(bonus.lastStatusChangeAt * 1e3).toLocaleString(intl.locale, {
                    timeStyle: "short",
                    dateStyle: "short",
                  })}
                </td>
                <td>{bonus.type}</td>
                <td>
                  {formatMoney(bonus.initialWager ?? 0, {
                    style: "decimal",
                  })}
                </td>
                <td>{formatMoney(bonus.amount ?? 0, { style: "decimal" })}</td>
                <td>{translateDynamicMessage($t, "bonus_status", bonus.status)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
