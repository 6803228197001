import { useTranslate } from "@lobby/ocb-intl";

import { APIError } from "../api-error";
import { ClientError } from "../client-error";

export function useErrorTranslate() {
  const { $t: tt } = useTranslate();

  function formatMessage(error: APIError | ClientError | string) {
    if (error instanceof APIError) {
      return tt({
        id: `api_errors/code_${error.details.code}`,
        defaultMessage: error.message,
      });
    }

    if (error instanceof ClientError) {
      return tt({
        id: error.messageId,
        defaultMessage: error.message,
      });
    }

    return String(error);
  }

  return { formatMessage };
}
