import { useTranslate } from "@lobby/ocb-intl";
import type { SuccessEventPayload } from "../../lib";
import { Dialog, type IDialogProps } from "../dialog";

interface ISuccessModalProps extends IDialogProps {
  payload: SuccessEventPayload;
}

export function SuccessModal({ payload }: ISuccessModalProps) {
  const { $t } = useTranslate();

  const title = $t({ defaultMessage: "success" });

  return (
    <Dialog type="success" title={title} icon="successSign">
      <Dialog.Message>{payload.message}</Dialog.Message>
    </Dialog>
  );
}
