import { defineMessages } from "react-intl";

export const clientErrorMessages = defineMessages({
  NETWORK_OFF: {
    id: "client_errors/code_0",
    defaultMessage:
      "It looks like you are not connected to the Internet.\nCheck the connection and restart the page.",
  },
  SAME_PASSWORD_ERROR: {
    id: "client_errors/code_1",
    defaultMessage: "Old and new passwords must be different",
  },
});
