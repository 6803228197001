import { Input, type InputProps } from "@headlessui/react";
import { useToggle } from "../../lib";
import { Icon } from "../icons";

export function InputPassword(props: InputProps) {
  const [isPasswordVisible, togglePasswordVisibility] = useToggle(false);
  return (
    <div className="relative">
      <Input
        type={isPasswordVisible ? "text" : "password"}
        minLength={6}
        maxLength={255}
        required
        {...props}
      />
      <div
        style={{
          color: "var(--input-password-eye-icon-color)",
        }}
        onClick={togglePasswordVisibility}
        className="-translate-y-1/2 absolute top-1/2 rtl:left-0 ltr:right-0 p-3 text-20 hover:cursor-pointer"
      >
        {isPasswordVisible ? <Icon name="openedEye" /> : <Icon name="closedEye" />}
      </div>
    </div>
  );
}
