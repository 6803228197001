import { Field, Label } from "@headlessui/react";
import { clientErrorMessages } from "@lobby/i18n";
import { useTranslate } from "@lobby/ocb-intl";
import { type FormEvent, useState } from "react";
import { Player } from "../../../../../entities";
import { APIError, Button, ClientError, Input, useErrorTranslate } from "../../../../../shared";

const passwordValidationError = {
  samePasswords: clientErrorMessages.SAME_PASSWORD_ERROR,
};

export function ChangePasswordForm() {
  const { $t } = useTranslate();
  const { formatMessage } = useErrorTranslate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [validationError, setValidationError] = useState("");
  const [canUpdate, setCanUpdate] = useState(false);

  const updateMutation = Player.useChangePassword();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setValidationError("");

    const form = new FormData(e.currentTarget);

    if (validateForm(form)) {
      changePassword(form);
    }
  }

  function changePassword(form: FormData) {
    updateMutation.mutate(
      {
        oldPassword: form.get("old-password") as string,
        newPassword: form.get("new-password") as string,
      },
      {
        onSuccess: ({ error }) => {
          if (error) {
            const message = formatMessage(new APIError(error.message, { code: error.code }));
            setValidationError(message);
          } else {
            setValidationError("");
            setOldPassword("");
            setNewPassword("");
          }
        },
      },
    );
  }

  function validateForm(form: FormData) {
    if (!validatePasswords(form)) {
      const error = formatMessage(new ClientError(passwordValidationError.samePasswords));
      setValidationError(error);
      return false;
    }

    return true;
  }

  function validatePasswords(form: FormData) {
    return validatePasswordsSet(form) && validatePasswordsDiffer(form);
  }

  function validatePasswordsSet(form: FormData) {
    return Array.from(form.values()).every(Boolean);
  }

  function validatePasswordsDiffer(form: FormData) {
    return form.get("old-password") !== form.get("new-password");
  }

  function handleChange(e: FormEvent<HTMLFormElement>) {
    const form = new FormData(e.currentTarget);
    setCanUpdate(validatePasswordsSet(form));
  }

  return (
    <form className="relative h-full" onSubmit={handleSubmit} onChange={handleChange}>
      <Field className="flex flex-col">
        <Label>{$t({ defaultMessage: "Current Password" })}:</Label>
        <Input.Password
          className="w-full pe-13"
          name="old-password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder={$t({ defaultMessage: "Current Password" })}
          autoComplete="current-password"
        />
      </Field>
      <Field className="flex flex-col mt-5">
        <Label>{$t({ defaultMessage: "New Password" })}:</Label>
        <Input.Password
          className="w-full pe-13"
          name="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder={$t({ defaultMessage: "New Password" })}
          autoComplete="new-password"
        />
        {validationError && (
          <div
            style={{
              color: "var(--error-accent-color)",
            }}
            className="text-12/5 lg:text-14/5"
          >
            {formatMessage(validationError)}
          </div>
        )}
      </Field>

      <div className="flex items-center gap-3 mt-6 mobile-only:justify-end">
        <Button
          variant="secondary"
          type="submit"
          loading={updateMutation.isPending}
          disabled={!canUpdate}
          className="mx-auto"
        >
          {$t({ defaultMessage: "Update" })}
        </Button>
      </div>
    </form>
  );
}
