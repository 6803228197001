import { clsx } from "clsx";
import type { HTMLAttributes, ReactNode } from "react";
import { Icon, Spinner, isEmptyArray, isEmptyObject, isNil } from "../../../shared";

export function NextPageLoader({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        color: "var(--accent-color)",
      }}
      className={clsx("my-2 flex h-10 items-center justify-center", className)}
      {...restProps}
    >
      <Icon className="animate-spin text-20" name="reload" />
    </div>
  );
}

interface IProfileSectionProps<T> {
  placeholder: ReactNode;
  isLoading: boolean;
  data: T | undefined;
  children: (data: T) => ReactNode;
}

export function ProfileSection<T>({
  children,
  placeholder,
  isLoading,
  data,
}: IProfileSectionProps<T>) {
  if (isLoading) {
    return (
      <div className="flex-center h-full">
        <Spinner />
      </div>
    );
  }

  if (isNil(data) || isEmptyArray(data) || isEmptyObject(data)) {
    return placeholder;
  }

  return <>{children(data)}</>;
}

interface INoDataPlaceholderProps {
  topText?: string;
  bottomText?: string;
  icon: ReactNode;
}

export function NoDataPlaceholder({ topText, bottomText, icon }: INoDataPlaceholderProps) {
  return (
    <div className="data-placeholder">
      <div className="text-center font-medium text-16 lg:text-22">
        {topText && <div>{topText}</div>}
        <div className="inline-block my-2">{icon}</div>
        {bottomText && <div>{bottomText}</div>}
      </div>
    </div>
  );
}
