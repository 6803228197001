import { Field, Input, Label, Radio } from "@headlessui/react";
import { useTranslate } from "@lobby/ocb-intl";
import type { ChangeEventHandler } from "react";
import { usePlayerMoneyFormatter } from "../../../../../entities";
import { isNil } from "../../../../../shared";

interface IPaymentAccountFieldProps {
  value: string | number;
  disabled: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export function BankAccountField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter bank account number" })}</span>
      <Field disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Account number" })}:
        </Label>
        <Input
          className="w-80 appearance-none"
          placeholder={$t({ defaultMessage: "Bank account number" })}
          name="payment-account"
          type="text"
          value={value}
          required
          onChange={onChange}
        />
      </Field>
    </>
  );
}

export function CryptoWalletField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter crypto wallet address" })}</span>
      <Field disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Crypto wallet address" })}:
        </Label>
        <Input
          className="w-80 appearance-none"
          placeholder={$t({ defaultMessage: "Crypto wallet address" })}
          name="payment-account"
          type="text"
          value={value}
          required
          onChange={onChange}
        />
      </Field>
    </>
  );
}

export function PhoneNumberField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter phone number" })}</span>
      <Field disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Phone number" })}:
        </Label>
        <Input
          className="w-80 appearance-none"
          placeholder={$t({ defaultMessage: "Phone number" })}
          name="payment-account"
          autoComplete="tel"
          type="phone"
          value={value}
          required
          pattern="[0-9]{6,100}"
          onChange={onChange}
        />
      </Field>
    </>
  );
}

export function CardNumberField({ value, disabled, onChange }: IPaymentAccountFieldProps) {
  const { $t } = useTranslate();

  return (
    <>
      <span>{$t({ defaultMessage: "Enter card number" })}</span>
      <Field disabled={disabled}>
        <Label className="block font-bold data-[disabled]:opacity-50">
          {$t({ defaultMessage: "Card number" })}:
        </Label>
        <Input
          className="w-80 appearance-none"
          placeholder="xxxx xxxx xxxx xxxx"
          name="payment-account"
          autoComplete="cc-number"
          inputMode="numeric"
          type="tel"
          pattern="[0-9\s]{13,19}"
          required
          maxLength={19}
          value={value}
          onChange={onChange}
        />
      </Field>
    </>
  );
}

interface IPaymentMethodCardProps {
  id: number;
  name: string;
  logoUrl: string | null;
  commission?: string | null;
  limits?: {
    min: number;
    max: number;
  };
}

export function PaymentMethodCard({
  id,
  name,
  logoUrl,
  commission,
  limits,
}: IPaymentMethodCardProps) {
  const { $t } = useTranslate();
  const formatMoney = usePlayerMoneyFormatter();

  return (
    <Radio as="div" value={id} className="payment-method-card">
      <div className="relative grow">
        <img
          className="px-3.5 pt-2.5 pb-5 object-contain absolute size-full"
          src={logoUrl ?? ""}
          alt={name}
        />
        {commission && Number(commission) > 0 && (
          <div
            style={{
              color: "var(--text-secondary-color)",
            }}
            className="absolute right-0 bottom-1 left-0 px-2.5 text-center font-medium text-10/none uppercase"
          >
            {$t({ defaultMessage: "commission: {commission}%" }, { commission })}
          </div>
        )}
      </div>

      {!isNil(limits) && (
        <div
          style={{
            background: "var(--bg-level-0)",
          }}
          className="flex-center whitespace-nowrap px-2.5 py-1.5 text-center text-14/none"
        >
          {formatMoney(limits.min ?? 0, { style: "decimal" })}
          {" - "}
          {formatMoney(limits.max ?? 0)}
        </div>
      )}
    </Radio>
  );
}
