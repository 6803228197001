import { type ProfileSection, profileSections } from "../../components/UserProfile";
import { isLobbyDemoMode } from "./helpers";

export function validateSearchModal(search: Record<string, unknown>) {
  if (search.modal === "log-in") {
    return isLobbyDemoMode() ? "log-in" : undefined;
  }
  return search.modal as string;
}

export function validateSearchProfileSection(
  search: Record<string, unknown>,
): ProfileSection | undefined {
  if (search.modal !== "profile") return;

  const profileSection = search.profileSection as ProfileSection;
  if (profileSection && profileSections.includes(profileSection)) {
    return profileSection;
  }
}
