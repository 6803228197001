import * as Sentry from "@sentry/react";
import { clsx } from "clsx";
import { useContext, useEffect, useRef } from "react";
import { LobbySettingsContext } from "../../../entities";
import { getCategoryImageSrc } from "../../lib";

interface IExternalIconProps {
  id: string | number;
  className?: string;
}

export function ExternalIcon({ id, className }: IExternalIconProps) {
  const { theme } = useContext(LobbySettingsContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchIcon = async () => {
      try {
        const response = await fetch(getCategoryImageSrc(theme, id), { signal });
        if (!response.ok) throw new Error("Failed to fetch");

        const svg = await response.text();
        if (ref.current) {
          ref.current.innerHTML = svg;
        }
      } catch (_) {
        return;
      }
    };

    fetchIcon();

    return () => controller.abort();
  }, [id, theme]);

  return (
    <Sentry.ErrorBoundary fallback={<pre>??</pre>}>
      <div ref={ref} className={clsx("external-icon", className)} />
    </Sentry.ErrorBoundary>
  );
}
