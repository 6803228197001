import type { ApiClientResponse } from "@lobby/api-client";
import { queryClient } from "../../app";

type APIErrorDetails = Record<string, string | number | boolean | null>;

export class APIError extends Error {
  details: APIErrorDetails;
  timestamp: Date;

  constructor(message: string, details?: APIErrorDetails, options?: ErrorOptions) {
    super(message, options);

    this.details = details ?? { code: -1 };
    this.timestamp = new Date();

    const player = queryClient.getQueryData<ApiClientResponse<"Player.getCurrent">>([
      "Player.getCurrent",
    ])?.result;

    if (player) {
      this.details = {
        ...this.details,
        playerId: player?.id,
        hallId: player?.hallId,
      };
    }
  }
}
