import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import type { PropsWithChildren } from "react";
import { Icon } from "../../../shared";

interface INavigationBackMobileProps {
  className?: string;
  onClick: VoidFunction;
}

export function NavigationBackMobile({
  className,
  children,
  onClick,
}: PropsWithChildren<INavigationBackMobileProps>) {
  const { $t } = useTranslate();
  return (
    <div
      style={{
        background: "var(--bg-level-1)",
      }}
      className={clsx("rounded-4 p-3 font-medium uppercase", className)}
    >
      <div className="font-bold">{children}</div>
      <button
        type="button"
        onClick={onClick}
        style={{
          background: "var(--bg-level-2)",
          color: "var(--accent-color)",
        }}
        className="mt-2 flex w-full items-center uppercase justify-between rounded-inherit px-3 py-2 text-14"
      >
        <span>{$t({ defaultMessage: "back to menu" })}</span>
        <Icon name="arrowLeft" className="rtl:-scale-100" />
      </button>
    </div>
  );
}
