import { FortuneWheel, Lobby } from "@lobby/core/src/entities";
import { formatTime, useFortuneWheelCountdown } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import fortuneWheelBtnImg from "assets/fw-btn-bg.avif";
import { clsx } from "clsx";

export function FortuneWheelButton() {
  const { $t } = useTranslate();
  const { data: lobbyData } = Lobby.useLobby();
  const { data: settingsData } = FortuneWheel.useWheelSettings();

  const countdown = useFortuneWheelCountdown(lobbyData?.fortuneWheel?.nextSpinTime as number);

  const isCountdownActive = countdown > 0;

  if (settingsData?.error) {
    return null;
  }

  return (
    <div className="relative p-2.5 [background-image:linear-gradient(90deg,#202020,#292411_12%,#443606_27.5%,#443606_78%,#292411_88%,#202020)]">
      <div className="flex h-full items-center justify-between gap-2.5">
        <div className="relative z-[1] w-24 flex-center flex-shrink-0 animate-step-spin">
          <img
            className="absolute size-24 object-contain"
            src={fortuneWheelBtnImg}
            alt="fortune-wheel-preview"
          />
        </div>

        <Link
          to="/fortune-wheel"
          className={clsx(
            "flex-center grow rounded-4 border-2 border-dark-charcoal p-4 no-underline [background:radial-gradient(50%_97.48%_at_50%_50%,rgba(91,91,91,0.5),rgba(0,0,0,0.5)_80%),#000000] hover:text-inherit",
            !isCountdownActive && "hover:[box-shadow:0_0_0.625rem_#FFBE01]",
          )}
        >
          <div className="whitespace-normal text-center font-commissioner font-extrabold uppercase italic leading-4 [text-shadow:0_0_1.5625rem_#6880FF]">
            {isCountdownActive ? (
              <div className="flex-center gap-1.5">
                <svg width="1em" height="1.125em" viewBox="0 0 18 20">
                  <path
                    fill="#fff"
                    d="M9 20c4.606-.013 8.334-2.236 8.334-4.77 0-2.541-.464-5.882-.464-5.882-.188-.946-1.243-2.123-2.234-2.123V5.83C14.636 2.623 12.116.02 9 0 5.884.021 3.365 2.623 3.365 5.83v1.395c-.991 0-2.047 1.177-2.235 2.123 0 0-.463 3.34-.463 5.882C.667 17.764 4.394 19.987 9 20Zm1.023-6.97.317 2.61c0 .387-.36.7-.805.7H8.48c-.445 0-.805-.313-.805-.7l.37-2.61a1.943 1.943 0 0 1-.892-1.643c0-1.068.843-1.934 1.882-1.934 1.04 0 1.881.866 1.881 1.934 0 .694-.357 1.301-.892 1.642ZM6.191 5.657c0-1.6 1.255-2.9 2.81-2.918 1.553.018 2.808 1.317 2.808 2.918v1.324H6.191V5.658Z"
                  />
                </svg>
                <span className="w-[4.375em] text-left">{formatTime(countdown)}</span>
              </div>
            ) : (
              <>
                <div>{$t({ defaultMessage: "fortune wheel" })}</div>{" "}
                <div>{$t({ defaultMessage: "ready to spin" })}</div>
              </>
            )}
          </div>
        </Link>
        <hr className="absolute top-0 left-0 h-0.5 w-full border-none [background-image:linear-gradient(90deg,#202020,#82641D_40%,#FFFFFF_50%,#82641D_60%,#202020)]" />
        <hr className="absolute bottom-0 left-0 h-0.5 w-full border-none [background-image:linear-gradient(90deg,#202020,#82641D_40%,#FFFFFF_50%,#82641D_60%,#202020)]" />
      </div>
    </div>
  );
}
