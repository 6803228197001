import {
  BankAccountField,
  CardNumberField,
  CryptoWalletField,
  PhoneNumberField,
} from "./components";

export function penniesToDollars(amount = 0): number {
  return amount / 100;
}

export function selectPaymentAccountComponent(type = "") {
  switch (type) {
    case "crypto":
      return CryptoWalletField;
    case "bank_account":
      return BankAccountField;
    case "card":
      return CardNumberField;
    case "phone":
      return PhoneNumberField;
    default:
      return BankAccountField;
  }
}
