import { type ComponentType, useCallback, useEffect, useState } from "react";
import {
  ErrorModal,
  SuccessModal,
  WalletIntegrationWarningModal,
  WarningModal,
  emitter,
} from "../../shared";

const modalMap: Record<EventType, ComponentType<any>> = {
  WALLET_INTEGRATION_WARNING_MODAL_OPENED: WalletIntegrationWarningModal,
  ERROR: ErrorModal,
  WARNING: WarningModal,
  SUCCESS_MODAL_OPEN: SuccessModal,
} as const;

type EventType =
  | "ERROR"
  | "WARNING"
  | "SUCCESS_MODAL_OPEN"
  | "WALLET_INTEGRATION_WARNING_MODAL_OPENED";
type EventPayload = Record<string, unknown>;

type ModalQueue = {
  eventType: EventType;
  payload: EventPayload;
}[];

export function ModalManager() {
  const [modalQueue, setModalQueue] = useState<ModalQueue>([]);
  const [activeModal, setActiveModal] = useState<{
    eventType: EventType;
    payload: EventPayload;
  } | null>(null);

  const addModalToQueue = useCallback((eventType: EventType, payload: EventPayload) => {
    if (modalMap[eventType]) {
      setModalQueue((prevQueue) => [...prevQueue, { eventType, payload }]);
    }
  }, []);

  const closeModal = () => setActiveModal(null);

  useEffect(() => {
    if (!activeModal && modalQueue.length > 0) {
      setActiveModal(modalQueue[0]);
      setModalQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [activeModal, modalQueue]);

  useEffect(() => {
    const unbinds = (Object.keys(modalMap) as EventType[]).map((eventType) => {
      return emitter.on(eventType, (payload: EventPayload) => {
        addModalToQueue(eventType, payload);
      });
    });

    return () => unbinds.forEach((unbind) => unbind());
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    window.addEventListener(
      "close",
      ({ target }) => {
        if ((target as HTMLDialogElement)?.tagName === "DIALOG") {
          closeModal();
        }
      },
      { signal: controller.signal, capture: true },
    );

    return () => controller.abort();
  }, []);

  const ActiveModalComponent = activeModal ? modalMap[activeModal.eventType] : null;
  const modalPayload = activeModal?.payload ?? {};

  return ActiveModalComponent && <ActiveModalComponent payload={modalPayload} />;
}
