import { ReactIntlProvider } from "@lobby/ocb-intl";
import { type PropsWithChildren, useEffect, useState } from "react";
import { type TLocale, emitter } from "../../shared/lib";

export function LocaleProvider({ children }: PropsWithChildren) {
  const langStorage = (localStorage.getItem("locale") ?? "en") as TLocale;
  const [locale, setLocale] = useState<TLocale>(langStorage);

  useEffect(() => {
    if (locale === "ar") {
      document.documentElement.dir = "rtl";
    } else {
      document.documentElement.dir = "ltr";
    }

    document.documentElement.lang = locale;
  }, [locale]);

  useEffect(() => {
    const unbind = emitter.on("SET_LOCALE", setLocale);
    return () => unbind();
  }, []);

  return (
    <ReactIntlProvider
      locale={locale}
      basePath={import.meta.env.VITE_PUBLIC_PATH}
      version={import.meta.env.VITE_APP_VERSION}
    >
      {children}
    </ReactIntlProvider>
  );
}
