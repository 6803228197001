import { useTranslate } from "@lobby/ocb-intl";
import { type PropsWithChildren, useEffect, useRef } from "react";
import { Button } from "../button";
import { Icon, type IconName } from "../icons";

interface IWalletIntegrationWarningModalProps {
  title: string;
  icon: IconName;
  type: "success" | "warning" | "error";
  onClose?: VoidFunction;
}

const buttonVariantMap = {
  success: "success",
  warning: "warning",
  error: "danger",
} as const;

function DialogComponent({
  children,
  title,
  icon,
  type,
  onClose,
}: PropsWithChildren<IWalletIntegrationWarningModalProps>) {
  const { $t } = useTranslate();

  const dialogRef = useRef<HTMLDialogElement>(null);

  function handleOk() {
    dialogRef.current?.close();
    onClose?.();
  }

  useEffect(() => {
    dialogRef.current?.showModal();
  }, []);

  return (
    <dialog ref={dialogRef} data-type={type}>
      <div className="dialog-inner">
        <div className="dialog-title">
          <Icon name={icon} className="text-[2.5rem]" />
          <div>{title}</div>
        </div>

        {children}

        <div className="mx-auto mt-5 lg:mt-7 w-fit">
          <Button variant={buttonVariantMap[type]} onClick={handleOk}>
            {$t({ defaultMessage: "ok" })}
          </Button>
        </div>
      </div>
    </dialog>
  );
}

function Message({ children }: PropsWithChildren) {
  return <div className="font-medium text-14 lg:text-18 whitespace-pre-wrap">{children}</div>;
}

function Description({ children }: PropsWithChildren) {
  return <div className="mt-3 text-12 lg:mt-4 lg:text-14 whitespace-pre-wrap">{children}</div>;
}

export const Dialog = Object.assign(DialogComponent, {
  Message,
  Description,
});
