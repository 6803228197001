import type { ApiClientResponse } from "@lobby/api-client";
import { Game } from "../../../../../entities";
import { isEmptyArray, isNil } from "../../../../../shared";

type TGames = ApiClientResponse<"Game.getNoWagerList">["result"]["data"];

export function useNoWagerGames() {
  const { data: noWagerGames, isLoading: isNoWagerLoading } = Game.useNoWagerGames();
  const { data: noJackpotGames, isLoading: isNoJackpotLoading } = Game.useNoJackpotGames();

  function filteredByProviders(games: TGames) {
    if (isNil(games) || isEmptyArray(games)) return;

    return Object.groupBy(games, ({ providerName }) => providerName) as Record<string, TGames>;
  }

  return {
    noWagerGames: noWagerGames ? filteredByProviders(noWagerGames.data) : {},
    noJackpotGames: noJackpotGames ? filteredByProviders(noJackpotGames.data) : {},
    isLoading: isNoWagerLoading || isNoJackpotLoading,
  };
}
