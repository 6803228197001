import { FortuneWheel } from "@lobby/core/src/entities";
import { ErrorPlaceholder } from "@lobby/core/src/shared/ui";
import * as Sentry from "@sentry/react";
import type { PropsWithChildren } from "react";
import { Skeleton } from "shared/ui";

export default Sentry.withErrorBoundary(RulesSectionMobile, {
  fallback: <ErrorPlaceholder className="h-40" />,
});

function RulesSectionMobile() {
  const { data, isLoading } = FortuneWheel.useWheelSettings();

  if (isLoading) {
    return (
      <RulesContainer>
        <RulesContentSkeleton />
        <RulesContentSkeleton />
      </RulesContainer>
    );
  }
  const rules = (data as NonNullable<typeof data>).result.rules;

  return (
    <RulesContainer>
      <RulesContent
        title={rules.howToWork.title}
        description={rules.howToWork.body}
      />
      <RulesContent
        className="text-end"
        title={rules.dailyRules.title}
        description={rules.dailyRules.body}
      />
    </RulesContainer>
  );
}

function RulesContainer({ children }: PropsWithChildren) {
  return (
    <div className="rounded-5 bg-white/10 p-2.5">
      <div className="flex justify-between gap-8 rounded-inherit bg-raisin-black px-3 py-2">
        {children}
      </div>
    </div>
  );
}

function RulesContent({
  className,
  title,
  description,
}: {
  className?: string;
  title: string;
  description: string;
}) {
  return (
    <RulesCard className={className}>
      <div className="mb-3 font-bold text-14 uppercase">{title}</div>
      <p className="whitespace-pre-line text-12">{description}</p>
    </RulesCard>
  );
}

function RulesContentSkeleton() {
  return (
    <div className="flex flex-col w-full">
      <Skeleton className="mb-3 h-5" />
      <div className="space-y-1 *:h-3 *:w-full">
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  );
}

function RulesCard({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return <div className={className}>{children}</div>;
}
