import type { ApiClientResponse } from "@lobby/api-client";
import { clientErrorMessages } from "@lobby/i18n";
import { queryClient } from "../../app";

const clientErrorCodes = {
  NETWORK_OFF: 0,
} as const;

export const CLIENT_ERRORS = {
  [clientErrorCodes.NETWORK_OFF]: clientErrorMessages.NETWORK_OFF,
} as const;

type ClientErrorDetails = Record<string, string | number | boolean | null>;

export class ClientError {
  messageId: string;
  message: string;
  details: ClientErrorDetails;
  timestamp: Date;

  constructor(message: { id: string; defaultMessage: string }, details?: ClientErrorDetails) {
    this.message = message.defaultMessage;
    this.messageId = message.id;
    this.details = details ?? { code: message.id };
    this.timestamp = new Date();

    const player = queryClient.getQueryData<ApiClientResponse<"Player.getCurrent">>([
      "Player.getCurrent",
    ])?.result;

    if (player) {
      this.details = {
        ...this.details,
        playerId: player?.id,
        hallId: player?.hallId,
      };
    }
  }
}
