import { clsx } from "clsx";
import type { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { Button } from "../button";
import { Icon } from "../icons";
import { Spinner } from "../spinner";

function Portal({ children }: PropsWithChildren) {
  // @ts-ignore
  return <>{createPortal(children, document.getElementById("root"))}</>;
}

function Overlay({ onClick }: { onClick?: VoidFunction }) {
  return <div className="modal-window-overlay" onClick={onClick} />;
}

function PendingOverlay({ isActive }: { isActive: boolean }) {
  return (
    <div className={clsx("modal-window-pending-overlay", isActive ? "visible" : "invisible")}>
      <div className="absolute inset-0 flex-center">
        <Spinner />
      </div>
    </div>
  );
}

function Header({
  className,
  children,
  isClosable = true,
  onCloseButtonClick,
}: PropsWithChildren<{
  className?: string;
  isClosable?: boolean;
  onCloseButtonClick?: VoidFunction;
}>) {
  return (
    <div className={clsx("modal-window-header", className)}>
      {children}
      {isClosable && (
        <CloseButton onClick={onCloseButtonClick as VoidFunction} className="ms-auto" />
      )}
    </div>
  );
}

function Title({ className, children }: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx("modal-window-title", className)}>{children}</div>;
}

function CloseButton({ className, onClick }: { className?: string; onClick: VoidFunction }) {
  return (
    <Button variant="secondary" fit onClick={onClick} className={className}>
      <Icon className="text-14 lg:text-16" name="close" />
    </Button>
  );
}

function Panel({
  className,
  centered,
  children,
}: PropsWithChildren<{ className?: string; centered?: boolean }>) {
  return (
    <div className={clsx("modal-window-panel", className, centered && "-translate-y-1/2 top-1/2")}>
      {children}
    </div>
  );
}

function ModalRoot({
  open,
  children,
  onOverlayClick,
}: PropsWithChildren<{
  open: boolean;
  onOverlayClick?: VoidFunction;
}>) {
  return (
    open && (
      <Portal>
        <div className="modal-window">
          <Overlay onClick={onOverlayClick} />
          {children}
        </div>
      </Portal>
    )
  );
}

export const Modal = Object.assign(ModalRoot, {
  Header,
  Title,
  Panel,
  PendingOverlay,
  CloseButton,
});
