import { useMediaQuery } from "react-responsive";
import { MOBILE_BREAKPOINT } from "../../const";

export function useMobile(maxWidthPx = MOBILE_BREAKPOINT) {
  return useMediaQuery({
    query: `(max-width: ${maxWidthPx}px)`,
  });
}

export function useMobileLandscape(maxWidthPx = MOBILE_BREAKPOINT) {
  return useMediaQuery({
    query: `(max-width: ${maxWidthPx}px) and (orientation:landscape)`,
  });
}
