import { LanguageSelector, useModal } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { useIsMutating } from "@tanstack/react-query";
import { Modal } from "shared/ui/modal";
import { PasswordResetForm } from "./components";

export function PasswordResetModal({ isStatic = false }: { isStatic?: boolean }) {
  const { isOpen, close } = useModal("password-reset");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={isStatic ? undefined : close} />
      <PasswordResetModalComponent />
    </Modal>
  );
}

function PasswordResetModalComponent() {
  const { $t } = useTranslate();

  const isPending = useIsMutating({ mutationKey: ["Email.setNewPassword"] }) > 0;

  return (
    <Modal.Panel className="w-full lg:top-17 lg:w-[25rem]">
      <p className="text-center font-bold text-16 uppercase">
        {$t({ defaultMessage: "Reset your password" })}
      </p>
      <p className="text-center text-14">{$t({ defaultMessage: "Enter a new password below" })}</p>

      <div className="mt-7">
        <PasswordResetForm />
      </div>

      <div className="mt-auto flex items-center justify-between">
        <LanguageSelector orientation="top" />
      </div>

      <Modal.PendingOverlay isActive={isPending} />
    </Modal.Panel>
  );
}
