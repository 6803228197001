import { clsx } from "clsx";
import { type HTMLAttributes, useId } from "react";

export function Logo({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  const id = useId();

  return (
    <div className={clsx("brand-logo", className)} {...restProps}>
      <svg height="100%" viewBox="0 0 178 40">
        <path
          fill="#fff"
          d="M174.12 26.55h-3.7v8.07h-5.38v-8.07h-4.03a3.37 3.37 0 0 1-3.36-3.36V12.44h5.71v8c0 .57.47 1.07 1.08 1.07h6.25c.6 0 1.07-.47 1.07-1.07v-8h5.72v10.75a3.37 3.37 0 0 1-3.36 3.36Zm-22.19 8.07h-13.1a3.37 3.37 0 0 1-3.37-3.36V15.8a3.37 3.37 0 0 1 3.36-3.36h13.11a3.37 3.37 0 0 1 3.36 3.36v15.46a3.37 3.37 0 0 1-3.36 3.36Zm-2.01-16.5a.64.64 0 0 0-.64-.64h-7.8a.64.64 0 0 0-.64.64v10.85c0 .34.27.64.64.64h7.8c.33 0 .64-.27.64-.64V18.12Zm-19.5 16.5h-16.13V12.44h16.13a3.37 3.37 0 0 1 3.36 3.36v4.7c0 1.35-.77 2.5-1.91 3.03a3.38 3.38 0 0 1 1.91 3.02v4.71a3.37 3.37 0 0 1-3.36 3.36Zm-2.02-16.8c0-.37-.3-.68-.67-.68h-8.07v4.04h8.07c.37 0 .67-.3.67-.68v-2.68Zm0 8.4c0-.37-.3-.67-.67-.67h-8.07v4.03h8.07c.37 0 .67-.3.67-.67v-2.7Zm-23.19 8.4h-5.38V17.14h-7.4v-4.7h20.17v4.7h-7.39v17.48Zm-32.6 0V12.44h17.47v4.7h-12.1v3.7h12.1v4.7h-12.1v4.38h12.1v4.7H72.61Zm-5.38 0H51.09V12.44h16.14a3.37 3.37 0 0 1 3.36 3.36v4.7c0 1.35-.77 2.5-1.92 3.03a3.38 3.38 0 0 1 1.92 3.02v4.71a3.37 3.37 0 0 1-3.36 3.36Zm-2.02-16.8c0-.37-.3-.68-.67-.68h-8.07v4.04h8.07c.37 0 .67-.3.67-.68v-2.68Zm0 8.4c0-.37-.3-.67-.67-.67h-8.07v4.03h8.07c.37 0 .67-.3.67-.67v-2.7Z"
        />
        <path
          fill={`url(#${id})`}
          d="M17.75 21.75h12.9a2.7 2.7 0 0 0 2.7-2.7v-6.51a2.7 2.7 0 0 0-2.7-2.7H20.5v3h9.05c.37 0 .67.3.67.67v4.6c0 .38-.3.68-.67.68h-10.7a.67.67 0 0 1-.67-.67V0h-3.12v19.06a2.7 2.7 0 0 0 2.69 2.69Zm13.17-16.7H20.5v3.12h10.66c2.05 0 3.7 1.65 3.7 3.7v7.86c0 2.05-1.65 3.7-3.7 3.7H17.24a3.69 3.69 0 0 1-3.7-3.7V0h-3.46v19.16c0 4.1 3.3 7.4 7.4 7.4h2.65v5c0 .37-.3.68-.67.68H8.77a.67.67 0 0 1-.67-.68V13.45H4.97V32.5a2.7 2.7 0 0 0 2.7 2.7h12.9a2.7 2.7 0 0 0 2.69-2.7v-5.95h1.51v6.63c0 2.05-1.64 3.7-3.7 3.7H7.17a3.69 3.69 0 0 1-3.7-3.7V13.45H0V32.6C0 36.7 3.3 40 7.4 40h13.44c4.1 0 7.4-3.3 7.4-7.4v-6.05h2.68c4.1 0 7.4-3.29 7.4-7.39v-6.72c0-4.1-3.3-7.4-7.4-7.4Z"
        />
        <defs>
          <linearGradient id={id}>
            <stop stopColor="#D3A02A" />
            <stop offset=".21" stopColor="#F2CC18" />
            <stop offset=".4" stopColor="#C8922B" />
            <stop offset=".67" stopColor="#D5A329" />
            <stop offset=".85" stopColor="#A87329" />
            <stop offset="1" stopColor="#EECA17" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
