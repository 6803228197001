import { useTranslate } from "@lobby/ocb-intl";
import { Tabs } from "../../../../../shared";
import { ChangePasswordForm } from "./change-password-form";
import { UserDataForm } from "./user-data-form";

export function Settings() {
  const { $t } = useTranslate();
  return (
    <div className="mobile-only:relative flex h-full min-h-0 flex-col">
      <Tabs
        items={[
          {
            label: $t({ defaultMessage: "My Data" }),
            children: <UserDataForm />,
          },
          {
            label: $t({ defaultMessage: "Change Password" }),
            children: <ChangePasswordForm />,
          },
        ]}
      />
    </div>
  );
}
