import { Button } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters";
import { clsx } from "clsx";
import { memo, useEffect, useState } from "react";
import type { TBanner } from "../lib/types";

interface IBannersListProps {
  banners: TBanner[];
}

export const BannersListDesktop = memo(function BannersListDesktop({ banners }: IBannersListProps) {
  const [activeBanners, setActiveBanners] = useState(banners);
  const { $t } = useTranslate();

  function flipBanners() {
    setActiveBanners((prev) => {
      const [head, ...tail] = prev;
      return [...tail, head];
    });
  }

  useEffect(() => {
    setActiveBanners(banners);
  }, [banners]);

  return (
    <>
      {activeBanners.length > 3 && (
        <div
          className="absolute top-[-0.375rem] h-[0.125rem] w-full animate-[growing_10s_linear_infinite] bg-accent/70 group-hover:[animation-play-state:paused]"
          onAnimationIteration={flipBanners}
        />
      )}
      {activeBanners.map(({ id, image, title, subtitle, button }, idx) => {
        const splitTitle = title.split("\n");
        const splitSubtitle = subtitle.split("\n");

        return (
          <div
            className={clsx(idx > 2 && "hidden", "relative h-full rounded-rounded p-[1.25em]")}
            key={id}
          >
            <img
              className="absolute top-0 left-0 size-full rounded-inherit object-cover"
              src={image}
              alt={skipIntl("promo-img")}
            />
            <div className="absolute inset-0 rounded-inherit bg-gradient-to-r from-[#0D1523B8] to-[#0D152300]" />
            <div className="relative h-full select-none">
              {(title || subtitle) && (
                <div
                  dir="ltr"
                  className="absolute rtl:text-end top-0 max-h-full max-w-[70%] overflow-hidden whitespace-break-spaces text-white drop-shadow-[0.0625em_0.125em_0_#000000] [line-height:1.15]"
                >
                  {splitTitle.map((substr, idx) => (
                    <div key={idx} className="font-bold text-[2.25em]">
                      {substr}
                    </div>
                  ))}
                  {splitSubtitle.map((substr, idx) => (
                    <div key={idx} className="mt-[0.625em] text-[1.25em]">
                      {substr}
                    </div>
                  ))}
                </div>
              )}
              {button && (
                <div className="absolute bottom-0 ltr:left-0 rtl:right-0">
                  <Button variant="primary" onClick={button.action}>
                    {button.textKey === "playNow"
                      ? $t({ defaultMessage: "play now" })
                      : $t({ defaultMessage: "open" })}
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
});
