function getTimeFormatOptions() {
  return Intl.DateTimeFormat().resolvedOptions();
}

function getLocaleTimeString(options: Intl.DateTimeFormatOptions = {}) {
  const locale = getTimeFormatOptions().locale;
  return new Date().toLocaleString(locale, {
    timeZone: "UTC",
    timeZoneName: "shortOffset",
    hour12: false,
    ...options,
  });
}

export function getLocalTime() {
  const { timeZone } = getTimeFormatOptions();
  return getLocaleTimeString({ timeZone }).replace("GMT", "UTC");
}

export function getGMTTime() {
  return getLocaleTimeString().replace("GMT", "UTC+0");
}
