import { useModal } from "@lobby/core/src/shared";
import { useEffect } from "react";
import { PasswordResetModal } from "widget/password-reset-modal";

export function PasswordResetPage() {
  const { open } = useModal("password-reset");

  useEffect(() => open(), [open]);

  return <PasswordResetModal isStatic />;
}
