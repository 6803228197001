import { useTranslate } from "@lobby/ocb-intl";
import { skipIntl } from "@lobby/utils-formatters/dist";
import { Icon } from "../../../../../shared";
import { NoDataPlaceholder, ProfileSection } from "../../components";
import { useNoWagerGames } from "./use-no-wager-games";

export function Info() {
  const { $t } = useTranslate();

  const { noJackpotGames, noWagerGames, isLoading } = useNoWagerGames();

  return (
    <ProfileSection
      data={noWagerGames || noJackpotGames}
      isLoading={isLoading}
      placeholder={
        <NoDataPlaceholder
          topText={$t({ defaultMessage: "No data" })}
          icon={<Icon name="bonusGift" className="text-[4.5rem]" />}
        />
      }
    >
      {() => <NoWagerGameList />}
    </ProfileSection>
  );
}

function formatGames(games: Record<string, { name: string }[]>) {
  return Object.keys(games).reduce(
    (acc, provider) => {
      acc[provider] = games[provider]
        .reduce((acc, item) => acc + item.name + ", ", "")
        .replace(/, $/, "");
      return acc;
    },
    {} as Record<string, string>,
  );
}

function NoWagerGameList() {
  const { $t } = useTranslate();
  const { noJackpotGames, noWagerGames } = useNoWagerGames();

  const formattedNoWagerGamesList = formatGames(noWagerGames ?? {});

  const UnWagerGameList = noWagerGames && (
    <div className="space-y-2">
      <p className="font-bold text-18">
        {$t({
          defaultMessage: "A list of games in which the bonus cannot be wagered",
        })}
      </p>

      {Object.keys(noWagerGames).map((providerName) => (
        <p key={providerName}>
          <span className="font-bold mr-2">
            {providerName}
            {skipIntl(":")}
          </span>
          {formattedNoWagerGamesList[providerName]}
        </p>
      ))}
    </div>
  );

  const formattedNoJackpotGamesList = formatGames(noJackpotGames ?? {});

  const NoJackpotGameList = noJackpotGames && (
    <div className="space-y-2">
      <p className="font-bold text-18">
        {$t({
          defaultMessage:
            "List of games in which the jackpot does not accumulate and cannot be received",
        })}
      </p>

      {Object.keys(noJackpotGames).map((providerName) => (
        <p key={providerName}>
          <span className="font-bold mr-2">
            {providerName}
            {skipIntl(":")}
          </span>
          {formattedNoJackpotGamesList[providerName]}
        </p>
      ))}
    </div>
  );

  return (
    <div className="mobile-only:relative flex h-full max-h-full min-h-0 flex-col">
      <div className="gutter-stable overflow-y-auto space-y-10 py-1">
        {UnWagerGameList}
        {NoJackpotGameList}
      </div>
    </div>
  );
}
