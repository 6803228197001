import { ApiError } from "./error";

import type { Endpoint, Input, Output } from "./codegen";

export interface ApiClientResponse<E extends Endpoint> {
  id: number;
  jsonrpc: "2.0";
  error?: {
    code: number;
    message: string;
    data: unknown;
  };
  result: Output<E>;
}

export type SendOptions<E extends Endpoint> = {
  params?: Input<E>["body"];
  endpoint: E;
  signal?: AbortSignal;
  headers?: Record<string, string>;
};

export class ApiClient {
  private id = 1;

  async send<E extends Endpoint>({
    endpoint,
    params,
    signal,
    headers,
  }: SendOptions<E>): Promise<ApiClientResponse<E>> {
    const request = new Request("/rpc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: this.id++,
        method: endpoint,
        params: params,
      }),
      signal,
    });

    try {
      if (!import.meta.env.PROD) {
        console.debug(
          `%c == Fetching == %c ${endpoint} `,
          "background: #1976D2; color: #fff",
          "background: #795548; color: #fff",
          params,
        );
      }

      const response = await fetch(request);
      const result = await response.json();
      if (response.ok) {
        return result;
      } else {
        throw new ApiError(response.status, response.statusText);
      }
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      } else {
        throw new ApiError(-1, "Unknown error");
      }
    }
  }
}

export const apiClient = new ApiClient();
