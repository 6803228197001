import { apiClient } from "@lobby/api-client";
import type { SendOptions } from "@lobby/api-client";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../shared";

export const Payment = {
  useDeposit() {
    return useMutation({
      mutationKey: ["Payment.deposit"],
      mutationFn: (params: SendOptions<"Payment.deposit">["params"]) =>
        apiClient.send<"Payment.deposit">({
          endpoint: "Payment.deposit",
          params,
        }),
    });
  },
  useWithdraw() {
    return useMutation({
      mutationKey: ["Payment.withdraw"],
      mutationFn: (params: SendOptions<"Payment.withdraw">["params"]) =>
        apiClient.send<"Payment.withdraw">({
          endpoint: "Payment.withdraw",
          params,
        }),
    });
  },
  useMethodList(paymentType: "deposit" | "withdraw") {
    const { isAuth } = useAuth();

    return useQuery({
      enabled: isAuth,
      queryKey: ["Payment.getMethodsList", paymentType],
      queryFn: () =>
        apiClient.send({
          endpoint: "Payment.getMethodsList",
          params: {
            paymentType,
          },
        }),
      select: (data) => data.result.data,
    });
  },
  useActiveWithdrawRequest() {
    return useQuery({
      staleTime: 30_000,
      queryKey: ["Payment.filter"],
      queryFn: () =>
        apiClient.send({
          endpoint: "Payment.filter",
          params: {
            type: "withdraw",
            status: "active",
            limit: 1,
            offset: 0,
          },
        }),
    });
  },
  useTransactionHistory(paymentType: "deposit" | "withdraw") {
    const { isAuth } = useAuth();

    return useInfiniteQuery({
      enabled: isAuth,
      queryKey: ["Payment.filter", paymentType],
      queryFn: ({ pageParam }) =>
        apiClient.send({
          endpoint: "Payment.filter",
          params: {
            type: paymentType,
            limit: 30,
            offset: pageParam * 30,
          },
        }),
      initialPageParam: 0,
      getNextPageParam: (lastPage, _allPages, lastPageParam) => {
        if (lastPage.result?.data.length === 0) {
          return undefined;
        }
        return lastPageParam + 1;
      },
      getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => {
        if (firstPageParam <= 1) {
          return undefined;
        }
        return firstPageParam - 1;
      },
    });
  },
};
