import { getSentryEnv } from "@lobby/utils-formatters";
import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { App } from "./app";

if (import.meta.env.PROD) {
  Sentry.init({
    release: import.meta.env.VITE_APP_VERSION,
    dsn: "https://60d268a3d8c147ff920b6588cd5b3cff@sentry.c23.games/19",
    tracesSampleRate: 1.0,
    environment: getSentryEnv(),
  });
}

// @ts-expect-error
createRoot(document.getElementById("root")).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
