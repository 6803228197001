import { useTranslate } from "@lobby/ocb-intl";
import { useMemo } from "react";
import { GameHistory } from "../../../../../entities";
import { Icon, type TSportBetsHistory, useInfiniteScroll, useMobile } from "../../../../../shared";
import { getMobileSectionScrollContainer } from "../../../helpers";
import { NextPageLoader, NoDataPlaceholder, ProfileSection } from "../../components";
import { Coupon } from "./coupon";

interface IBetHistoryListProps {
  status: "outcome" | "cancelled" | "cashout" | "created" | "non-created";
}

export function HistoryList({ status }: IBetHistoryListProps) {
  const { $t } = useTranslate();

  const { data, isLoading } = GameHistory.useSportBetHistory(status);

  const normalizedData = useMemo(
    () => data?.pages.flatMap((page) => page.result?.data).filter(Boolean) ?? [],
    [data],
  );

  return (
    <ProfileSection
      isLoading={isLoading}
      data={normalizedData}
      placeholder={
        <NoDataPlaceholder
          topText={
            status === "created"
              ? $t({ defaultMessage: "You have no active bets" })
              : $t({ defaultMessage: "You have no COMPLETED bets" })
          }
          bottomText={status === "created" ? $t({ defaultMessage: "Place a new bid" }) : undefined}
          icon={<Icon name="searchTicket" className="text-[7rem]" />}
        />
      }
    >
      {(validatedData) => <HistoryListView data={validatedData} status={status} />}
    </ProfileSection>
  );
}

function HistoryListView({ data, status }: { data: TSportBetsHistory } & IBetHistoryListProps) {
  const isMobile = useMobile();

  const { fetchNextPage, isFetchingNextPage } = GameHistory.useSportBetHistory(status);

  const scrollContainer = isMobile ? getMobileSectionScrollContainer() : null;

  const scrollRef = useInfiniteScroll(fetchNextPage, scrollContainer);

  return (
    <div
      className="gutter-stable mobile-only:relative h-full max-h-full min-h-0 lg:overflow-y-auto lg:space-y-5 space-y-3 py-1"
      ref={scrollRef}
    >
      {data.map((item, idx) => (
        <Coupon key={idx} {...item} />
      ))}
      {isFetchingNextPage && <NextPageLoader />}
    </div>
  );
}
