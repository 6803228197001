import { Player } from "@lobby/core/src/entities";
import { Icon, useModal } from "@lobby/core/src/shared";
import { PlayerBalance } from "features/player/player-balance";
import { Skeleton } from "shared/ui/skeleton";

export function PlayerInfo() {
  const { open: openProfile } = useModal("profile");

  const { data: player } = Player.usePlayer();
  const { isPending: isBalancePending } = Player.useBalances();

  const playerName = player?.displayableName as string;

  return (
    <button type="button" onClick={openProfile} className="text-right text-14 font-medium min-w-0">
      <div className="flex items-center gap-1 max-w-full">
        <div className="min-w-0">
          <div className="truncate lg:max-w-36" title={playerName}>
            {playerName}
          </div>
          {isBalancePending ? (
            <Skeleton className="h-5 w-full lg:h-5 lg:w-28" />
          ) : (
            <PlayerBalance />
          )}
        </div>
        <Icon name="signIn" className="text-15 shrink-0" />
      </div>
    </button>
  );
}
