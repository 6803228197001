import { clsx } from "clsx";
import type { HTMLAttributes } from "react";
import { Game } from "../../entities";
import { ExternalIcon, Icon, getCategoryIconName, isClientCategory, range } from "../../shared";
import { ExpandedGameList } from "./ExpandedGameList";
import { ScrollableGameList } from "./ScrollableGameList";
import { GameListSkeleton } from "./components";
import { useGroupedGameList } from "./hooks";

function getCategoryIcon(id: string | number): JSX.Element {
  return isClientCategory(id) ? (
    <Icon name={getCategoryIconName(String(id))} />
  ) : (
    <ExternalIcon id={id} />
  );
}

export function GameLists({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  const { isLoading } = Game.useList();

  const gameList = useGroupedGameList();

  return (
    <div className={clsx("flex flex-col gap-3 *:w-full lg:gap-5", className)} {...restProps}>
      {isLoading
        ? range(4)((idx) => <GameListSkeleton key={idx} />)
        : gameList.map(({ type, label, id, games }) => {
            if (!label) return null;

            const hasIcon = type !== "search" && type !== "provider";

            let CategoryIcon = null;
            if (hasIcon) {
              CategoryIcon = getCategoryIcon(id);
            }

            if (gameList.length === 1) {
              return (
                <ExpandedGameList
                  key={id}
                  items={games}
                  label={label}
                  CategoryIcon={CategoryIcon}
                />
              );
            }

            return (
              <ScrollableGameList
                key={id}
                items={games}
                categoryId={id}
                CategoryIcon={CategoryIcon}
                label={label}
              />
            );
          })}
    </div>
  );
}
