import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { useIntlT } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { useEffect } from "react";
import { type TLocale, emitter, localesMap } from "../../lib";
import { Icon } from "../icons";
import { flagIcons } from "./flag-icons";

interface ILanguageSelectorProps {
  className?: string;
  orientation?: "top" | "bottom";
}

export function LanguageSelector({ className, orientation = "bottom" }: ILanguageSelectorProps) {
  const { intl } = useIntlT();

  const locale = intl.locale as TLocale;

  function handleLanguageChange(locale: TLocale) {
    emitter.emit("SET_LOCALE", locale);
    localStorage.setItem("locale", locale);
  }

  useEffect(() => {
    intl.locale = localStorage.getItem("locale") ?? "en";
  }, [intl]);

  return (
    <Listbox value={locale} onChange={handleLanguageChange}>
      <div className={clsx("language-selector", className)}>
        <ListboxButton className="language-selector-button">
          <div className="language-selector-button_flag-icon">{flagIcons[locale]}</div>
          <span className="language-selector-button_label">{localesMap[locale].name}</span>
          <Icon name="arrowLeft" className="language-selector-button_arrow-icon" />
        </ListboxButton>
        <ListboxOptions
          anchor={{
            to: orientation === "top" ? "top start" : "bottom start",
            offset: "-0.625rem",
            gap: "1rem",
          }}
          transition
          className={`language-selector-dropdown ${orientation === "top" ? "origin-top" : "origin-bottom"}`}
        >
          <div className="language-selector-dropdown_content">
            {Object.values(localesMap).map((lang) => (
              <ListboxOption key={lang.code} value={lang.code}>
                <div className="language-selector-dropdown-item_flag-icon">
                  {flagIcons[lang.code as TLocale]}
                </div>
                {lang.name}
              </ListboxOption>
            ))}
          </div>
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
